export function ThumbsDown() {
  return (
    <svg
      width="22"
      height="21"
      viewBox="0 0 22 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.18583 13.3438H4.45074C3.55243 13.3437 2.74836 12.7365 2.65326 11.8432C2.61392 11.4738 2.59375 11.0986 2.59375 10.7187C2.59375 8.22708 3.46165 5.93812 4.91162 4.13768C5.25078 3.71655 5.77508 3.5 6.3158 3.5L9.82978 3.5C10.253 3.5 10.6734 3.56823 11.0749 3.70206L13.8001 4.61044C14.2016 4.74427 14.622 4.8125 15.0452 4.8125L16.1777 4.8125M7.18583 13.3438C7.72683 13.3438 8.05284 13.9772 7.82045 14.4658C7.41462 15.3189 7.1875 16.2736 7.1875 17.2812C7.1875 18.3686 8.06894 19.25 9.15625 19.25C9.51869 19.25 9.8125 18.9562 9.8125 18.5938V18.0395C9.8125 17.5386 9.90808 17.0423 10.0941 16.5772C10.36 15.9125 10.9083 15.4126 11.5402 15.0761C12.5148 14.557 13.3674 13.8391 14.0439 12.9765C14.4793 12.4214 15.1159 12.0313 15.8215 12.0313H16.1572M7.18583 13.3438H9.1125M16.1777 4.8125C16.187 4.85625 16.2017 4.89967 16.2225 4.94182C16.7403 5.9912 17.0313 7.17256 17.0312 8.42188C17.0312 9.72278 16.7158 10.95 16.1572 12.0313M16.1777 4.8125C16.1103 4.49342 16.3378 4.15625 16.6807 4.15625H17.475C18.2526 4.15625 18.9738 4.60952 19.2008 5.35329C19.4969 6.32395 19.6563 7.35431 19.6562 8.42188C19.6562 9.78028 19.3983 11.0784 18.9287 12.27C18.6618 12.9473 17.9786 13.3438 17.2506 13.3438H16.3294C15.9165 13.3438 15.6774 12.8573 15.8917 12.5044C15.9855 12.3501 16.0741 12.1923 16.1572 12.0313"
        stroke="currentColor"
        strokeWidth="1.3125"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
