import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  scrollContainer: {
    maxHeight: "90vh",
    overflowY: "auto",
    padding: theme.spacing(2),

    "&::-webkit-scrollbar": {
      width: 6,
    },
    "&::-webkit-scrollbar-track": {
      background: "transparent",
    },
    "&::-webkit-scrollbar-thumb": {
      background: "rgba(0, 0, 0, 0.2)",
      borderRadius: "4px",
    },
    "&::-webkit-scrollbar-thumb:hover": {
      background: "rgba(0, 0, 0, 0.3)",
    },
  },
  stepContainer: {
    display: "flex",
    alignItems: "flex-start",
    marginBottom: theme.spacing(4),
    position: "relative",
  },
  stepCircle: {
    width: 40,
    height: 40,
    borderRadius: "50%",
    border: "2px solid #17457A",
    backgroundColor: "#fff",
    color: "#17457A",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginRight: theme.spacing(2),
    fontSize: 16,
    fontWeight: 600,
    position: "relative",
    zIndex: 1,
  },
  stepNumberCircle: {
    width: 40,
    height: 40,
    borderRadius: "50%",
    backgroundColor: "#17457A",
    color: "#fff",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginRight: theme.spacing(2),
    fontSize: 16,
    fontWeight: 600,
    position: "relative",
    zIndex: 1,
  },
  stepConnector: {
    position: "absolute",
    top: 0,
    left: 20,
    width: 2,
    borderLeft: "2px dotted #ECECEC",
    height: "100%",
    zIndex: 0,
  },
  stepHeaderContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: theme.spacing(2), // 16px
  },
  stepContent: {
    flex: 1,
  },
  stepActionsContainer: {
    display: "flex",
    gap: theme.spacing(1),
  },
  titleStep: {
    color: "#4D4D4D",
    fontSize: 16,
    fontWeight: 600,
    marginBottom: theme.spacing(1),
  },
  subTitleStep: {
    color: "#747474",
    fontSize: 14,
    fontWeight: 400,
    marginBottom: theme.spacing(2),
  },
  label: {
    color: "#747474",
    fontSize: 12,
    fontWeight: 600,
    lineHeight: 1.2,
    marginBottom: theme.spacing(1),
  },
  imageContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    // minHeight: "40vh",
    marginBottom: theme.spacing(2),
    border: "1px solid #ECECEC",
    borderRadius: 8,
    padding: theme.spacing(3),
    width: "100%",
    textAlign: "center",
  },
  icon: {
    fontSize: 50,
    color: "#4F98BC",
    marginBottom: theme.spacing(2),
  },
  button: {
    backgroundColor: "#4F98BC",
    color: "white",
    padding: theme.spacing(1, 4),
    fontSize: 16,
    fontWeight: 600,
    textTransform: "capitalize",
    "&:hover": {
      backgroundColor: "#357A8B",
    },
  },
  buttonWhite: {
    textTransform: "none",
    border: "1px solid #4F98BC",
    backgroundColor: "white",
    color: "#4F98BC",
    boxShadow: "none",
    "&:hover": {
      backgroundColor: "#ECECEC",
      color: "#4F98BC",
      border: "1px solid #4F98BC",
      boxShadow: "none",
    },
  },
  buttonSecondary: {
    textTransform: "none",
    border: "1px solid #4F98BC",
    backgroundColor: "#4F98BC",
    color: "#FFF",
    boxShadow: "none",
    "&:disabled": {
      backgroundColor: "#ECECEC",
      color: "#A1A1A1",
      border: "1px solid #ECECEC",
    },
  },
  imageText: {
    color: "#4D4D4D",
    fontSize: 14,
    fontWeight: 600,
    marginBottom: theme.spacing(1),
  },
  imageDescription: {
    color: "#747474",
    fontSize: 14,
    fontWeight: 400,
    marginBottom: theme.spacing(2),
  },
  definitionStandardContainer: {
    border: "1px solid #ECECEC",
    minHeight: "40vh",
    display: "flex",
    flexDirection: "column",
    height: "100%",
  },
  clearButton: {
    backgroundColor: "white",
    border: "1px solid #747474",
    color: "#747474",
    marginRight: "16px",
    textTransform: "Capitalize",
    "&:hover": {
      backgroundColor: "#f0f0f0",
    },
  },
  feedbackButton: {
    width: 20,
    height: 35,
    border: "1px solid #4F98BC",
    borderRadius: 4,
  },

  accordion: {
    marginBottom: theme.spacing(2),
    border: "1px solid #ECECEC",
    borderRadius: "4px",
    boxShadow: "none",
    "&::before": {
      display: "none",
    },
  },
  accordionSummary: {
    backgroundColor: "#F9F9F9",
  },
  iconAccordion: {
    paddingRight: theme.spacing(3),
  },
  accordionItemContainer: {
    display: "flex",
    flexDirection: "column",
  },
  accordionItemContent: {
    display: "flex",
    alignItems: "flex-center", //center
    width: "100%",
  },
  accordionContainerDetails: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  accordionTextField: {
    flexGrow: 1,
    width: "100%",
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },

  accordionButtonContainer: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: theme.spacing(1),
    gap: theme.spacing(1),
  },
  accordionTitle: {
    fontSize: 16,
    fontWeight: 600,
    color: "#4D4D4D",
    paddingLeft: 10,
  },
  accordionDescription: {
    fontSize: 14,
    fontWeight: 400,
    color: "#747474",
    flexGrow: 1,
    whiteSpace: "pre-wrap",
    wordBreak: "break-word",
  },
  accordionDescriptionView: {
    fontSize: 14,
    fontWeight: 400,
    color: "#747474",
    flexGrow: 1,
    whiteSpace: "pre-wrap",
    wordBreak: "break-word",
    lineHeight: "40px",
  },

  accordionContainerButtonActions: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: theme.spacing(1),
    gap: theme.spacing(1),
  },
  accordionDivider: {
    marginTop: 4,
    marginBottom: 4,
  },
  menuIcon: {
    width: 24,
    height: 24,
    flexShrink: 0,
    borderRadius: 4,
    background: "var(--Grey-200, #ECECEC)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  menuText: {
    paddingLeft: 8,
    color: "var(--Grey-400, #747474)",
    fontSize: 14,
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
  },
  saveButton: {
    marginLeft: "auto",
    height: "36px",
  },
  stepHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    marginBottom: "10px",
  },
  inputLabel: {
    height: 10,
    marginBottom: 10,
    fontSize: 14,
    color: "#747474",
    fontWeight: "bold",
  },
  inputInternalLabel: {
    fontSize: 14,
    color: "#747474",
    fontWeight: "bold",
  },
  inputAutoField: {
    "& > .MuiTextField-root": {
      padding: 0,
      margin: 0,
      paddingTop: 5,
      paddingBottom: 5,
      paddingLeft: 5,
      background: "#ffffff",
    },
    "&  .MuiInput-underline:before": {
      border: "0 !important",
    },
    "&  .MuiInput-underline:after": {
      border: "0 !important",
    },
    "& .MuiAutocomplete-popupIndicator": {
      position: "absolute",
      top: 0,
      right: 0,
    },
    "& .MuiAutocomplete-clearIndicator": {
      position: "absolute",
      top: 3,
      right: 20,
    },
    "& .MuiChip-root": {
      height: "25px !important",
    },
    "& .MuiChip-label": {
      fontSize: "11px !important",
    },
    "& .MuiFormControl-root": {
      padding: "3px !important",
      paddingRight: "5px !important",
    },
    "& .MuiInput-input": {
      fontSize: 14,
      paddingLeft: 5,
    },
  },
  inputField: {
    borderRadius: "4px",
    border: "solid 1px #d6dde1 !important",
    fontSize: "14px",
    paddingLeft: 10,
    paddingRight: 10,
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    "&:hover": {
      border: "solid 1px #359be0",
    },
    "&:focus": {
      backgroundColor: "transparent",
    },
    "& > input": {
      padding: "12px 0 12px",
    },
  },
  errorIcon: {
    color: "#f07a8c",
  },
  addCustomAreaContainer: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  addCustomAreaButtonContainer: {
    display: "flex",
    alignItems: "center",
    paddingTop: 10,
    width: "100%",
  },
  addCustomAreaIconContainer: {
    backgroundColor: "#EDF5F8",
    borderRadius: "4px",
    padding: "4px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginRight: "8px",
  },
  addCustomAreaText: {
    color: "#4F98BC",
    fontSize: "14px",
    fontWeight: 400,
  },
  wrapperDialog: {
    textAlign: "center",
    paddingBottom: 0,
  },
  wrapperDialogClose: {
    position: "absolute",
    right: 5,
    top: 10,
  },
  iconDialog: {
    color: "#747474",
    width: 44,
    height: 44,
    marginBottom: 5,
  },
  iconsDialogClose: {
    color: "#979797",
    width: 20,
    height: 20,
  },
  titleDialog: {
    color: "#4D4D4D",
    fontSize: 16,
    fontWeight: "bold",
    marginBottom: 0,
    paddingLeft: 10,
  },
  contentDialog: {
    fontSize: 14,
    paddingTop: 10,
  },
  wrapperDialogAction: {
    textAlign: "center",
    justifyContent: "center !important",
    marginBottom: 20,
  },
  dialogActions: {
    padding: theme.spacing(3),
    justifyContent: "flex-end",
  },
  inputFields: {
    "& .MuiOutlinedInput-root": {
      borderRadius: "4px",

      "&:hover": {
        borderColor: "#E0E0E0",
      },
      "&.Mui-focused": {
        borderColor: "#E0E0E0",
      },
      "& fieldset": {
        borderColor: "#DFDFDF",
      },
      "&:hover fieldset": {
        borderColor: "#DFDFDF",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#DFDFDF",
      },
    },
    "& .MuiOutlinedInput-input": {
      padding: "10px 14px",
    },
    "& .MuiInputBase-input": {
      padding: 10,
      fontSize: 15,
    },
  },
  inputFieldFilters: {
    borderRadius: 4,
    border: "solid 1px #d6dde1",
    fontSize: 14,
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    "& .MuiInputBase-root": {
      height: 36,
    },
    "& .MuiInputBase-input": {
      padding: 10,
      fontSize: 14,
      fontStyle: "normal",
      height: 30,
      cursor: "pointer",
    },
    "&:hover": {
      border: "solid 1px #d6dde1",
    },
    "&:focus": {
      backgroundColor: "transparent",
    },
    "& .MuiInputBase-inputMarginDense": {
      margin: 0,
    },
  },
  inputHeight: {
    height: 35,
  },
  titleContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  bulletPoint: {
    width: 12,
    height: 12,
    borderRadius: "50%",
    backgroundColor: "white",
    border: "2px solid #D9D9D9",
    marginRight: theme.spacing(2),
    flexShrink: 0,
    marginTop: theme.spacing(1),
  },
  standarFooterContainer: {
    display: "flex",
    borderTop: "1px solid #ECECEC",
  },
  feedBackButtonsContainer: {
    display: "flex",
    alignItems: "center",
    padding: 5,
  },
  analisisButtonsContainer: {
    display: "flex",
    gap: 2,
    marginLeft: "auto",
    padding: 5,
  },
  standardAccordionContainer: {
    border: "1px solid #ECECEC",
    padding: "16px",
    minHeight: "40vh",
    display: "flex",
    flexDirection: "column",
    height: "100%",
  },
  focusedInput: {
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#747474",
    },
  },
  jobIconBox: {
    display: "flex",
    alignItems: "center",
    marginLeft: "auto",
    paddingRight: 10,
    paddingTop: 5,
  },
  jobIcon: {
    color: "white",
    fill: "#EFC945",
    marginRight: 8,
  },
  jobText: {
    fontSize: 12,
    fontWeight: 400,
    color: "#747474",
  },
  jobLinkText: {
    fontSize: 14,
    fontWeight: 400,
    color: "#4F98BC",
    borderBottom: "1px solid #4F98BC",
    cursor: "pointer",
  },
  standardListContainer: {
    marginLeft: 16,
    marginTop: 8,
  },
  standardListTitleContainer: {
    display: "flex",
    alignItems: "center",
    background: "#F8F8F8",
    padding: "8px 16px",
  },
  standardListTitle: {
    fontSize: 14,
    fontWeight: 700,
    marginLeft: 8,
  },
  standardListDescription: {
    fontSize: 14,
    fontWeight: 400,
    color: "#747474",
    marginLeft: 8,
  },
  loadingContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
  },
  standardLabel: {
    color: "#747474",
    fontSize: 14,
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: 2,
  },
  progressBarContainer: {
    width: "100%",
  },
  progressBarBackground: {
    width: "100%",
    height: "10px",
    backgroundColor: "#EDF5F8",
    borderRadius: "10px",
    overflow: "hidden",
    position: "relative",
  },
  progressErrorBackground: {
    width: "100%",
    height: "10px",
    backgroundColor: "#FFDFDF",
    borderRadius: "10px",
    overflow: "hidden",
    position: "relative",
  },
  progressBarFill: {
    height: "100%",
    background:
      "linear-gradient(to right, #17447F, #1C639E, #259BD6, #38A5DB, #6AC1EA, #74C7ED)",
    borderRadius: "10px",
    transition: "width 1s ease-in-out",
  },
  progressBarError: {
    height: "100%",
    background: "#9A1010",
    borderRadius: "10px",
    transition: "width 1s ease-in-out",
  },

  infoContainer: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: theme.spacing(1),
  },

  totalFilesLabel: {
    fontSize: 14,
    fontWeight: 400,
  },
  timeLabel: {
    fontSize: 14,
    fontWeight: 600,
  },
  errorLabel: {
    fontSize: 14,
    color: "#9A1010",
  },
  activityListContainer: {
    flex: 1,
    paddingTop: theme.spacing(7),
    paddingBottom: theme.spacing(10),
  },
  activityCardContent: {
    display: "flex",
    alignItems: "center",
    gap: "8px",
  },
  activityCard: {
    display: "flex",
    padding: "16px 12px",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: 10,
    alignSelf: "stretch",
    backgroundColor: "#F8F8F8",
    borderRadius: "8px",
  },
  activityCardList: {
    display: "flex",
    flexDirection: "column",
    gap: 10,
    marginTop: "16px",
  },
  activityDescription: {
    color: "#747474",
    fontSize: 14,
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "20px",
  },
  activityDate: {
    color: "#747474",
    fontSize: 14,
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "20px",
    marginLeft: "32px",
  },
  activityButton: {
    display: "flex",
    alignItems: "center",
    gap: "8px",
    color: "#4F98BC",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
    background: "#EDF5F8",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
    padding: "8px 12px",
    "&:hover": {
      backgroundColor: "#f0f0f0",
    },
  },
}));

export default useStyles;
