import http from "./httpService";
import { apiUrl } from "lib/config";
import { ht } from "date-fns/locale";

const apiEndpoint = apiUrl + "/luna-lens";
const apiEndpointSVWorkticket = apiUrl + "/service-verification";
export function getLunaLensCategories(user_id) {
  return http.get(`${apiEndpoint}/${user_id}/categories`);
}

export function getLunaLensCategoryById(category_id) {
  return http.get(`${apiEndpoint}/category/${category_id}`);
}

export function createLunaLensCategory(data) {
  return http.post(`${apiEndpoint}/create-category`, data);
}

export function getFeedbackReasons() {
  return http.get(`${apiEndpoint}/feedback-reasons`);
}

export function saveFeedback(data) {
  return http.post(`${apiEndpoint}/save-lens-feedback`, data);
}

export function getAnalysis(data) {
  const queryParams = new URLSearchParams(data).toString();
  return http.get(`${apiEndpoint}/get-workticket-analysis?${queryParams}`);
}

export function processAnalysis(data) {
  return http.post(`${apiEndpoint}/processed`, data);
}

export function startAnalysis(data) {
  return http.post(`${apiEndpoint}/start-analysis`, data);
}

export function addAnalysis(data) {
  return http.post(`${apiEndpoint}/add-analysis`, data);
}

export function getAnalysisById(data) {
  const queryParams = new URLSearchParams(data).toString();
  return http.get(`${apiEndpoint}/get-analysis/?${queryParams}`);
}
export function getAnalysisTest(data) {
  const queryParams = new URLSearchParams(data).toString();
  return http.get(`${apiEndpoint}/get-analysis/?${queryParams}`);
}
export function getCheckPendingVerificationAnalysis(data) {
  const queryParams = new URLSearchParams(data).toString();
  return http.get(
    `${apiEndpointSVWorkticket}/check-pending-verification/?${queryParams}`
  );
}
export function getAnalysisThread(data) {
  const queryParams = new URLSearchParams(data).toString();
  return http.get(`${apiEndpoint}/get-analysis-thread/?${queryParams}`);
}

export function getSavedThread(data) {
  const queryParams = new URLSearchParams(data).toString();
  return http.get(`${apiEndpoint}/get-saved-thread/?${queryParams}`);
}

export function getAnalysisList(data) {
  const queryParams = new URLSearchParams(data).toString();
  return http.get(`${apiEndpoint}/get-analysis-list/?${queryParams}`);
}

export function getVideoFrame(data) {
  const queryParams = new URLSearchParams(data).toString();
  return http.get(`${apiEndpoint}/get-file-frame/?${queryParams}`);
}

export function getFrameCount(data) {
  const queryParams = new URLSearchParams(data).toString();
  return http.get(`${apiEndpoint}/get-frame-count/?${queryParams}`);
}

export function getAnalysisReport(data) {
  const queryParams = new URLSearchParams(data).toString();
  return http.get(`${apiEndpoint}/get-analysis-report/?${queryParams}`);
}
export function getVerificationReport(data) {
  const queryParams = new URLSearchParams(data).toString();
  return http.get(
    `${apiEndpointSVWorkticket}/verification-report/?${queryParams}`
  );
}

export function updateAnalysis(data) {
  return http.post(`${apiEndpoint}/update-analysis`, data);
}

export function updateAnalysisStatus(id, data) {
  return http.post(`${apiEndpoint}/${id}/update-analysis-status`, data);
}

export function editReport(data) {
  return http.post(`${apiEndpoint}/edit-report`, data);
}

export function saveAnalysis(data) {
  return http.post(`${apiEndpoint}/save-analysis`, data);
}

export function deleteAnalysis(id) {
  return http.delete(`${apiEndpoint}/${id}/delete-analysis`);
}

export function editAnalysisTitle(id, data) {
  return http.post(`${apiEndpoint}/${id}/edit-title`, data);
}

export function getAnalysisLensPdf(id) {
  return http.get(`${apiEndpoint}/${id}/pdf`, {
    responseType: "blob",
  });
}
export const getSVWorkticketRatings = (workticketId) => {
  return http.get(
    `${apiEndpointSVWorkticket}/ratings?workticket_id=${workticketId}`
  );
};
export const getSVWorkticketFeedbackreasons = (ratingData) => {
  const { selectedRating, activeRatingId } = ratingData;
  return http.get(
    `${apiEndpointSVWorkticket}/${activeRatingId}/rating-reasons?rating=${selectedRating}`
  );
};

export const modifyRatingSV = (data) => {
  const { workticketId, feedbackObject } = data;
  return http.post(
    `${apiEndpointSVWorkticket}/${workticketId}/modify-rating`,
    feedbackObject
  );
};
export const deleteRatingSV = (ratingId) => {
  return http.delete(`${apiEndpointSVWorkticket}/${ratingId}/delete-rating`);
};
export const svConfirmAction = (data) => {
  const { workticketId } = data;
  return http.post(`${apiEndpointSVWorkticket}/${workticketId}/confirm`);
};
export const deleteWorkticketCommentLuna = (data) => {
  const { workticket_id, comment_id, reasons, feedback_comment } = data;
  return http.delete(
    `${apiEndpointSVWorkticket}/delete-luna-comment?workticket_id=${workticket_id}&comment_id=${comment_id}&reasons[]=${reasons}&feedback_comment=${feedback_comment}`
  );
};
export const verifyAgainSV = (data) => {
  const { workticket_id } = data;
  return http.post(`${apiEndpointSVWorkticket}/${workticket_id}/verify-again`);
};
export const bulkActionSV = (data) => {
  const { workticket_ids, action } = data;
  return http.post(
    `${apiEndpointSVWorkticket}/bulk-action?workticket_ids[]=${workticket_ids}&action=${action}`
  );
};
