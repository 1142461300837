import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  inputField: {
    flexGrow: 1,
    width: 300,
    maxWidth: 300,
    minWidth: 300,
    height: 42,
    backgroundColor: "white",
    borderRadius: 4,
    border: "solid 1px #d6dde1",
    "& .Mui-focused": {
      border: "solid 1px #359be0",
    },
    "& > input": {
      padding: 8,
    },
    "& > fieldset": {
      border: "none",
    },
    [theme.breakpoints.down("md")]: {
      width: 160,
      maxWidth: 160,
      minWidth: 160,
    },
    [theme.breakpoints.down("sm")]: {
      marginBottom: 10,
      clear: "both",
      marginTop: 10,
      zIndex: 100,
      width: "100%",
      maxWidth: "100%",
    },
  },
  searchIcon: {
    color: "#ececec",
    cursor: "pointer",
  },
  clearSearch: {
    display: "flex",
    alignItems: "center",
    padding: "0 10px",
    cursor: "pointer",
  },
  clearSearchButton: {
    padding: 0,
  },
  inputFieldSearchAi: {
    flexGrow: 1,
    width: "100%",
    maxWidth: "620px",
    height: "42px",
    backgroundColor: "white",
    borderRadius: 4,
    border: "solid 1px #d6dde1",
    paddingLeft: "2px",
    "& div:first-child": {
      marginRight: 0,
    },
    "& .Mui-focused": {
      border: "solid 1px #359be0",
    },
    "& > input": {
      padding: "8px",
    },
    "& > fieldset": {
      border: "none",
    },
    [theme.breakpoints.down("md")]: {
      maxWidth: "500px",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      maxWidth: "100%",
      marginBottom: "10px",
      clear: "both",
      marginTop: "10px",
      zIndex: 100,
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      paddingLeft: "1px",
      height: "42px",
    },
  },
  inputFieldWorkticketSearchAi: {
    flexGrow: 1,
    width: "100%",
    maxWidth: "700px",
    height: "42px",
    backgroundColor: "white",
    borderRadius: 4,
    border: "solid 1px #d6dde1",
    paddingLeft: "2px",
    "& div:first-child": {
      marginRight: 0,
    },
    "& .Mui-focused": {
      border: "solid 1px #359be0",
    },
    "& > input": {
      padding: "8px",
    },
    "& > fieldset": {
      border: "none",
    },
    [theme.breakpoints.down(1501)]: {
      maxWidth: "500px",
    },
    [theme.breakpoints.down(1301)]: {
      maxWidth: "300px",
    },
    [theme.breakpoints.down(1101)]: {
      width: "100%",
      maxWidth: "100%",
      marginBottom: "10px",
      clear: "both",
      marginTop: "10px",
      zIndex: 100,
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      paddingLeft: "1px",
      height: "42px",
    },
  },
  inputFieldStandardSearchAi: {
    flexGrow: 1,
    width: "100%",
    maxWidth: "700px",
    height: "42px",
    backgroundColor: "white",
    borderRadius: 4,
    border: "solid 1px #d6dde1",
    paddingLeft: "2px",
    "& div:first-child": {
      marginRight: 0,
    },
    "& .Mui-focused": {
      border: "solid 1px #359be0",
    },
    "& > input": {
      padding: "8px",
    },
    "& > fieldset": {
      border: "none",
    },
    [theme.breakpoints.down(1501)]: {
      maxWidth: "500px",
    },
    [theme.breakpoints.down(1301)]: {
      maxWidth: "300px",
    },
    [theme.breakpoints.down(1101)]: {
      width: "100%",
      maxWidth: "100%",
      marginBottom: "10px",
      clear: "both",
      marginTop: "10px",
      zIndex: 100,
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      paddingLeft: "1px",
      height: "42px",
    },
  },
  workticketSearchAi: {
    flexGrow: 1,
    width: "100%",
    height: "42px",
    backgroundColor: "white",
    borderRadius: 4,
    paddingLeft: "2px",
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#d6dde1",
    },
    "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#d6dde1",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#6EC4EB",
      borderWidth: "1px",
    },
  },
  partnerSearchAi: {
    flexGrow: 1,
    width: "100%",
    height: "42px",
    backgroundColor: "white",
    borderRadius: 4,
    paddingLeft: "2px",
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#d6dde1",
    },
    "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#d6dde1",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#6EC4EB",
      borderWidth: "1px",
    },
  },
  standardSearchAi: {
    flexGrow: 1,
    width: "100%",
    height: "42px",
    backgroundColor: "white",
    borderRadius: 4,
    paddingLeft: "2px",
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#d6dde1",
    },
    "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#d6dde1",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#6EC4EB",
      borderWidth: "1px",
    },
  },
  searchAiLoading: {
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#6EC4EB",
    },
    "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#6EC4EB",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#6EC4EB",
      borderWidth: "1px",
    },
  },
  locationSearchParentContainer: {
    position: "relative",
    width: "100%",
  },
  searchParentContainer: {
    position: "relative",
    width: "700px",
    [theme.breakpoints.down(1501)]: {
      maxWidth: "500px",
    },
    [theme.breakpoints.down(1301)]: {
      maxWidth: "300px",
    },
    [theme.breakpoints.down(1101)]: {
      width: "100%",
      maxWidth: "100%",
      marginBottom: "10px",
      clear: "both",
      marginTop: "10px",
      zIndex: 100,
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      paddingLeft: "1px",
      height: "42px",
    },
  },
  locationSearchAi: {
    flexGrow: 1,
    width: "100%",
    height: "42px",
    backgroundColor: "white",
    borderRadius: 4,
    paddingLeft: "2px",
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#d6dde1",
    },
    "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#d6dde1",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#6EC4EB",
      borderWidth: "1px",
    },
  },
}));

export default useStyles;
