import React, { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import Annotation from "react-image-annotation";
import Box from "@material-ui/core/Box";
import Input from "@material-ui/core/Input";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";
import SendIcon from "@material-ui/icons/Send";
import CommentsIcon from "assets/icons/commentsIconBlack";
import MessageDialog from "./dialog/messageDialog";
import { dateFormatField } from "components/util/timeFormat";
import {
  getWorkticketComments,
  addWorkticketComment,
} from "services/workticketService";
import { getCommentByFileId } from "services/fileService";
import { useWorkticketView } from "contexts/workticketViewContext";
import { logException } from "components/util/logUtil";
import useStyles from "./styles";

const transformCocoToAnnotationFormat = (cocoAnnotation) => ({
  id: cocoAnnotation.id,
  geometry: {
    x: cocoAnnotation.bbox[0],
    y: cocoAnnotation.bbox[1],
    width: cocoAnnotation.bbox[2],
    height: cocoAnnotation.bbox[3],
    type: "RECTANGLE",
  },
  data: {
    commentDate: cocoAnnotation.createdAt,
    userAvatar: cocoAnnotation.userAvatar,
    userName: cocoAnnotation.userName,
    body: cocoAnnotation.comment,
  },
});

const transformToCocoFormat = (annotation, imageId) => ({
  id: uuidv4(),
  image_id: imageId,
  bbox: [
    annotation.geometry.x,
    annotation.geometry.y,
    annotation.geometry.width,
    annotation.geometry.height,
  ],
  category_id: annotation.data ? annotation.data.categoryId : 1,
  area: annotation.geometry.width * annotation.geometry.height,
  iscrowd: 0,
  // comment: annotation.data ? annotation.data.text.trim() : "",
});

const parseAndExtractCocoAnnotation = (comment) => {
  try {
    if (!comment.video_metadata) {
      console.error(
        "video_metadata is empty or does not exist:",
        comment.video_metadata
      );
      return null;
    }

    const parsedMetadata =
      typeof comment.video_metadata === "string"
        ? JSON.parse(
            comment.video_metadata.replace(/\\\"/g, '"').replace(/^"|"$/g, "")
          )
        : comment.video_metadata;

    const cocoAnnotation = parsedMetadata?.metadata?.coco_annotation;

    if (!cocoAnnotation) {
      console.warn("coco_annotation not found in metadata:", parsedMetadata);
      return null;
    }

    return {
      ...cocoAnnotation,
      comment: comment.body,
      createdAt: comment.created_at,
      userName: comment.user_name,
      userAvatar: comment.profile_url,
    };
  } catch (error) {
    console.error("Error in parseAndExtractCocoAnnotation:", error);
    return null;
  }
};

/**
 * ImageAnnotator is a component that displays an image with annotations.
 *
 * Annotations are comments that have been added to the image.
 *
 * The component receives the following props:
 * - workTicketId: The id of the workticket that the image belongs to.
 * - fileId: The id of the file that the image belongs to.
 * - fileUrl: The URL of the image.
 * - commentList: An array of comments that have been added to the image.
 * - imageHeight: The height of the image. Defaults to 500.
 * - enableAnnotations: A boolean indicating whether annotations should be enabled. Defaults to false.
 *
 * The component renders an Annotation component that displays the image with
 * the annotations. It also renders a MessageDialog that displays a success
 * message when a new comment is added to the image.
 */
const ImageAnnotator = (props) => {
  const {
    workTicketId,
    fileId,
    fileUrl,
    commentList = [],
    enableAnnotations = false,
  } = props;
  const classes = useStyles();
  const context = useWorkticketView();
  const dispatchContext = context[1];
  const [annotations, setAnnotations] = useState([]);
  const [annotation, setAnnotation] = useState({ geometry: null, data: {} });
  const [newCommentText, setNewCommentText] = useState("");
  const [hoveredAnnotationId, setHoveredAnnotationId] = useState(null);
  const [isLoadingData, setIsLoadingData] = useState(true);
  const [openMessage, setOpenMessage] = useState(false);
  const [openMessageContent, setOpenMessageContent] = useState("");

  useEffect(() => {
    const loadAnnotations = () => {
      if (commentList.length > 0) {
        const extractedAnnotations = commentList
          .filter((comment) => !!comment.video_metadata)
          .map((comment) => parseAndExtractCocoAnnotation(comment))
          .filter((annotation) => annotation !== null);

        const transformedAnnotations = extractedAnnotations.map(
          (cocoAnnotation) => transformCocoToAnnotationFormat(cocoAnnotation)
        );

        setAnnotations(transformedAnnotations);
      }
    };
    loadAnnotations();
    // eslint-disable-next-line
  }, [commentList]);

  useEffect(() => {
    setAnnotations([]);
    setAnnotation({ geometry: null, data: {} });
    setNewCommentText("");
    setHoveredAnnotationId(null);
  }, [fileId, fileUrl]);

  const fetchData = async () => {
    try {
      const response = await getCommentByFileId(fileId);
      const comments = response.data.data;

      dispatchContext({
        type: "SET_WORKTICKET_FILE_COMMENTS",
        workticketFileComments: comments,
      });

      const responseComment = await getWorkticketComments(workTicketId);
      dispatchContext({
        type: "SET_WORKTICKET_COMMENTS",
        workticketComments: responseComment.data.data,
      });

      setOpenMessageContent("Comment has been added successfully.");
      setIsLoadingData(false);
    } catch (e) {
      logException(e, "Cannot add comment");
    }
  };

  const handleAnnotationSubmit = async (newAnnotation) => {
    if (!newAnnotation.geometry || newCommentText.trim() === "") return;

    const updatedAnnotation = {
      ...newAnnotation,
      data: { text: newCommentText },
    };

    const updatedAnnotations = [...annotations, updatedAnnotation];
    setAnnotations(updatedAnnotations);
    setAnnotation({ geometry: null, data: {} });
    setNewCommentText("");

    const cocoAnnotation = transformToCocoFormat(updatedAnnotation, fileId);

    try {
      setIsLoadingData(true);
      setOpenMessage(true);
      setOpenMessageContent("Adding comment");

      await addWorkticketComment(workTicketId, {
        file_id: fileId,
        body: newCommentText,
        video_metadata: JSON.stringify({
          metadata: { coco_annotation: cocoAnnotation },
        }),
        tab: "internal",
      });

      await fetchData();
      setOpenMessageContent("Comment has been added successfully.");
      setIsLoadingData(false);
    } catch (e) {
      setOpenMessageContent("Failed to add comment. Please try again.");
      setIsLoadingData(false);
      logException(e, "Cannot add comment");
    }
  };

  const handleNewCommentChange = (event) => {
    setNewCommentText(event.target.value);
  };

  const closeMessage = () => {
    setOpenMessage(false);
  };

  const renderEditor = ({ annotation }) => {
    if (!annotation.geometry) return null;

    return (
      <Box
        className={classes.annotationEditorContainer}
        style={{
          left: `${annotation.geometry.x}%`,
          top: `${annotation.geometry.y}%`,
          zIndex: 1002,
        }}
      >
        <Box className={classes.annotationInputCommentIcon}>
          <CommentsIcon fill="#fff" width={25} height={33} />
        </Box>
        <Box className={classes.annotationContainerAddButton}>
          <Input
            type="text"
            placeholder="Add a comment"
            multiline
            disableUnderline
            value={newCommentText}
            onChange={handleNewCommentChange}
            className={classes.annotationCommentInputText}
          />
          <IconButton
            size="small"
            style={{
              color: newCommentText.trim() !== "" ? "#4F98BC" : "#D9D9D9",
              alignSelf: "flex-end",
            }}
            onClick={() => handleAnnotationSubmit(annotation)}
          >
            <SendIcon />
          </IconButton>
        </Box>
      </Box>
    );
  };

  const renderHighlight = ({ annotation }) => {
    if (!annotation.geometry) return null;

    const isHovered = hoveredAnnotationId === annotation.id;
    const userName = annotation.data.userName || "";

    return (
      <Box
        key={annotation.id}
        className={classes.annotationHighlightBox}
        style={{
          left: `${annotation.geometry.x}%`,
          top: `${annotation.geometry.y}%`,
          zIndex: isHovered ? 1001 : 1000,
        }}
        onMouseEnter={() => setHoveredAnnotationId(annotation.id)}
        onMouseLeave={() => setHoveredAnnotationId(null)}
      >
        {isHovered && (
          <Box className={classes.annotationCommentDetailsBox}>
            <Box className={classes.annotationCommentDetailsHeader}>
              <CommentsIcon fill="#4F98BC" width={25} height={33} />
              <Typography className={classes.annotationCommentDetailsText}>
                Comment
              </Typography>
            </Box>
            <Box className={classes.annotationCommentUserDetails}>
              <Box className={classes.avatarContainer2}>
                <Avatar
                  src={annotation.data.userAvatar || null}
                  alt={userName}
                  className={
                    !annotation.data.userAvatar
                      ? classes.avatarComment
                      : classes.avatarDefault
                  }
                >
                  {!annotation.data.userAvatar &&
                    userName
                      .split(" ")
                      .map((word) => word.charAt(0))
                      .join("")}
                </Avatar>
              </Box>
              <Box style={{ flexGrow: 1, marginLeft: 5 }}>
                <Box className={classes.nameDateContainerStyles2}>
                  <Typography className={classes.userName}>
                    {annotation.data.userName}
                  </Typography>
                  <Box className={classes.annotationCommentDateContainer}>
                    <Typography className={classes.annotationCommentDate}>
                      {dateFormatField(annotation.data.commentDate)}
                    </Typography>
                  </Box>
                </Box>
                <Box className={classes.annotationCommentDescriptionContainer}>
                  <Typography className={classes.annotationCommentDescription}>
                    {annotation.data.body}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        )}
        <CommentsIcon
          fill={isHovered ? "#4F98BC" : "#747474"}
          width={25}
          height={33}
        />
      </Box>
    );
  };

  if (!enableAnnotations) {
    return (
      <Box className={classes.previewImageContainer}>
        <Box
          className={classes.imageGallery}
          style={{
            backgroundImage: `url('${decodeURIComponent(fileUrl)}')`,
          }}
        ></Box>
      </Box>
    );
  }

  return (
    <Box className={classes.previewAnnoationImageContainer}>
      <Box key={fileId} className={classes.imageAnnotation}>
        <Annotation
          src={fileUrl}
          alt="annotation"
          annotations={annotations}
          value={annotation}
          onChange={(newAnnotation) => setAnnotation(newAnnotation)}
          onSubmit={handleAnnotationSubmit}
          renderContent={() => null}
          renderEditor={renderEditor}
          renderHighlight={renderHighlight}
          disableOverlay={true}
          style={{
            objectFit: "contain",
            width: "100%",
            height: "100%",
            display: "block",
          }}
        />
      </Box>

      <MessageDialog
        title={"Success!"}
        open={openMessage}
        handleClose={closeMessage}
        message={openMessageContent}
        isLoadingData={isLoadingData}
      />
    </Box>
  );
};

export default ImageAnnotator;
