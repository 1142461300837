import React, { useState, useRef, useEffect, useContext } from "react";
import GlobalUiContext from "contexts/globalUiContext";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import MessageValidationDialog from "./dialog/messageValidationDialog";
import {
  attachFileIcon,
  afterMediaGray,
  beforeMediaGray,
  fileIcon,
} from "./icons";
import {
  uploadFileChunk,
  uploadFileCheckStatus,
} from "services/workticketService";
import { useUploadFileDispatch } from "contexts/uploadFileContext";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { permissionSurface, hasPermission } from "lib/permissions";
import useStyles from "./styles";

const CHUNK_SIZE = 5 * 1024 * 1024;
const BATCH_SIZE = 5;
const MAX_FILES = 100;
const MAX_FILES_SIZE = 8 * 1024 * 1024 * 1024;

const getMenuOptions = () => {
  const options = [
    {
      id: 1,
      label: "Upload Before Media",
      value: "BEFORE",
      icon: beforeMediaGray(),
    },
    {
      id: 2,
      label: "Upload After Media",
      value: "AFTER",
      icon: afterMediaGray(),
    },
    {
      id: 3,
      label: "Upload File",
      value: "FILE",
      icon: fileIcon(),
    },
  ];
  return options;
};

const initialAllowedTypes = [
  "image/jpeg",
  "image/png",
  "video/mp4",
  "video/quicktime",
  "application/pdf",
];

const classifyFiles = (files) => {
  const result = { all: [], pdf: [], image: [], video: [], links: [] };

  files.forEach((file, index) => {
    const fileInfo = {
      id: index,
      display_name: file.name,
      status: "Queued",
      progress: 0,
      message: "",
    };

    classifyFileType(file, fileInfo, result);
    result.all.push(fileInfo);
  });

  return result;
};

const classifyFileType = (file, fileInfo, result) => {
  if (file.type.startsWith("image")) {
    fileInfo.mime_type = 2;
    result.image.push(fileInfo);
  } else if (file.type.startsWith("video")) {
    fileInfo.mime_type = 3;
    result.video.push(fileInfo);
  } else if (file.type === "application/pdf") {
    fileInfo.mime_type = 1;
    result.pdf.push(fileInfo);
  }
};

const initUploadFiles = (classifiedFiles, dispatch) => {
  dispatch({
    type: "SET_UPLOAD_FILES",
    uploadFiles: classifiedFiles,
  });

  dispatch({ type: "SET_LOADING", isUploadFile: true });
};

const updateProgress = (fileName, progressEvent, dispatch) => {
  dispatch({
    type: "UPDATE_UPLOAD_PROGRESS",
    fileName,
    progress: progressEvent,
  });
};

const updateFileId = (fileName, fileId, dispatch, metadata) => {
  dispatch({
    type: "UPDATE_UPLOAD_FILE_ID",
    fileName,
    fileId,
    metadata,
  });
};

const updateStatus = (fileName, newStatus, chunkIndex, dispatch) => {
  dispatch({
    type: "UPDATE_UPLOAD_STATUS",
    fileName,
    status: newStatus,
    message: `Chunk ${chunkIndex} failed after 3 retries`,
  });
};

const createFormData = (
  file,
  workTicketId,
  chunkIndex,
  totalChunks,
  retryCount,
  fileType
) => {
  const start = chunkIndex * CHUNK_SIZE;
  const end = Math.min(start + CHUNK_SIZE, file.size);
  const chunk = file.slice(start, end);

  const formData = new FormData();
  formData.append("file", chunk);
  formData.append("workTicketId", workTicketId);
  formData.append("fileName", file.name);
  formData.append("chunkIndex", chunkIndex);
  formData.append("totalChunks", totalChunks);
  formData.append("retryCount", retryCount);
  formData.append("fileType", fileType);
  return formData;
};

const simulateProgressForSingleChunk = (fileName, uploadFileDispatch) => {
  const minProgressIncrement = 10;
  let currentProgress = 0;

  const updateProgressAndScheduleNext = () => {
    currentProgress += minProgressIncrement;
    updateProgress(fileName, currentProgress, uploadFileDispatch);

    if (currentProgress < 100) {
      setTimeout(updateProgressAndScheduleNext, 200);
    }
  };

  updateProgressAndScheduleNext();
};

const UploadFile = ({ workTicketId, workticket }) => {
  const classes = useStyles();
  const fileInputRef = useRef(null);
  const uploadFileDispatch = useUploadFileDispatch();
  const [openValidation, setOpenValidation] = useState(false);
  const [titleValidation, setTitleValidation] = useState("");
  const [messageValidation, setMessageValidation] = useState("");
  const [isUploading, setIsUploading] = useState(false);
  const [filesCheckStatus, setFilesCheckStatus] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [fileType, setFileType] = useState(null);
  const [allowedTypes, setAllowedTypes] = useState(initialAllowedTypes);
  const isMounted = useRef(true);
  const { globalUi } = useContext(GlobalUiContext);
  const { permissions } = globalUi;

  const handleSelect = async (event) => {
    const action = event.currentTarget.getAttribute("data");
    const actionHandlers = {
      BEFORE: () => {
        setFileType(1);
        setAllowedTypes(["image/jpeg", "image/png"]);
      },
      AFTER: () => {
        setFileType(2);
        setAllowedTypes(["image/jpeg", "image/png"]);
      },
      FILE: () => {
        setFileType(null);
        setAllowedTypes(initialAllowedTypes);
      },
    };

    const selectedAction = actionHandlers[action];
    if (selectedAction) {
      selectedAction();
      fileInputRef.current.click();
      const resultCheckStatus = await uploadFileCheckStatus(workTicketId);
      setFilesCheckStatus(resultCheckStatus.data.data);
    }
    setAnchorEl(null);
  };

  const handleClose = (option) => {
    setAnchorEl(null);
    if (option) {
      handleSelect(option);
    }
  };

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  const handleFileChange = (event) => {
    if (event.target.files.length > MAX_FILES) {
      setTitleValidation("Maximum files exceeded");
      setMessageValidation(
        `You can only upload a maximum of ${MAX_FILES} files.`
      );
      setOpenValidation(true);
      fileInputRef.current.value = "";
      return;
    }

    const maxFileSizeGB = MAX_FILES_SIZE / 1024 / 1024 / 1024;

    for (let i = 0; i < event.target.files.length; i++) {
      if (event.target.files[i].size > MAX_FILES_SIZE) {
        setTitleValidation("Maximum file size exceeded");
        setMessageValidation(
          `You can only upload files with a maximum size of ${maxFileSizeGB} GB.`
        );
        setOpenValidation(true);
        fileInputRef.current.value = "";
        return;
      }
    }
    const files = Array.from(event.target.files).filter((file) =>
      allowedTypes.includes(file.type)
    );
    sendFiles(files);
  };

  const sendFiles = async (files) => {
    setIsUploading(true);
    try {
      const classifiedFiles = classifyFiles(files);
      initUploadFiles(classifiedFiles, uploadFileDispatch);

      for (let i = 0; i < files.length; i += BATCH_SIZE) {
        const batch = files.slice(i, i + BATCH_SIZE);
        batch.forEach((file) => {
          updateStatus(file.name, "In Progress", 0, uploadFileDispatch);
        });
        await Promise.allSettled(
          batch.map((file) => {
            let startChunk = 0;
            const fileCheckStatus = filesCheckStatus.find(
              (fileCheck) => fileCheck.file_name === file.name
            );
            if (fileCheckStatus) {
              startChunk = Number(fileCheckStatus.uploaded_chunks);
            }
            return uploadFileInChunks(file, workTicketId, startChunk);
          })
        );
      }

      if (isMounted.current) {
        setIsUploading(false);
      }
    } catch (error) {
      if (isMounted.current) {
        setIsUploading(false);
      }
    }
  };

  const uploadFileInChunks = async (file, workTicketId, initChunk = 0) => {
    const totalChunks = Math.ceil(file.size / CHUNK_SIZE);
    let currentChunk = initChunk;

    const uploadChunk = async (chunkIndex, retryCount = 0) => {
      const formData = createFormData(
        file,
        workTicketId,
        chunkIndex,
        totalChunks,
        retryCount,
        fileType
      );

      try {
        const response = await uploadFileChunk(workTicketId, formData);
        const uploadData = response.data.data;

        if (response.status !== 200) {
          throw new Error(
            `Chunk ${chunkIndex} failed to upload. Status: ${response.status}`
          );
        }

        if (totalChunks === 1) {
          simulateProgressForSingleChunk(file.name, uploadFileDispatch);
        } else {
          const progress = ((chunkIndex + 1) / totalChunks) * 100;
          updateProgress(file.name, progress, uploadFileDispatch);
        }

        if (uploadData.completed) {
          updateFileId(
            file.name,
            uploadData.file_id,
            uploadFileDispatch,
            uploadData.metadata
          );
        }
      } catch (error) {
        console.error(
          `Error uploading chunk ${chunkIndex} of ${file.name}:`,
          error
        );

        if (retryCount < 3) {
          await uploadChunk(chunkIndex, retryCount + 1);
        } else {
          updateStatus(file.name, "Failed", chunkIndex, uploadFileDispatch);
          throw error;
        }
      }
    };

    while (currentChunk < totalChunks) {
      await uploadChunk(currentChunk);
      currentChunk++;
    }
  };

  const handleFileUpload = async (event) => {
    if (
      parseInt(workticket?.service_type) === 2 ||
      hasPermission(permissionSurface.KPI, permissions)
    ) {
      fileInputRef.current.click();
      const resultCheckStatus = await uploadFileCheckStatus(workTicketId);
      setFilesCheckStatus(resultCheckStatus.data.data);
    } else {
      setAnchorEl(event.currentTarget);
    }
  };

  return (
    <Box>
      <input
        type="file"
        multiple
        accept="image/*, video/*, .pdf"
        style={{ display: "none" }}
        ref={fileInputRef}
        onChange={handleFileChange}
      />

      <Button
        className={classes.buttonConfirm}
        variant="outlined"
        startIcon={attachFileIcon()}
        onClick={(event) => handleFileUpload(event)}
        disabled={isUploading}
      >
        <Typography variant="body1">Upload Files</Typography>
      </Button>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {getMenuOptions().map((option, index) => (
          <MenuItem key={index} onClick={handleSelect} data={option.value}>
            <Box className={classes.menuIcon}>{option.icon}</Box>
            <Typography variant="caption" className={classes.menuText}>
              {option.label}
            </Typography>
          </MenuItem>
        ))}
      </Menu>
      <MessageValidationDialog
        open={openValidation}
        title={titleValidation}
        message={messageValidation}
        handleClose={() => setOpenValidation(false)}
        isLoadingData={false}
      />
    </Box>
  );
};

export default UploadFile;
