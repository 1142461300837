import { create } from "zustand";

const useLunaLensStore = create((set) => ({
  tabThreads: [],
  startingAnalysis: false,
  globalThreadId: null,
  analysisComment: false,
  analysisStarted: false,
  startAnalysisFlag: false,
  mediaLimitation: false,
  analysisInformation: {},
  analysisThread: {},
  analysisThreadSV: {},
  svThread: [],
  analysis: [],
  analysisHistory: [],
  analysisDetail: {},
  categoryList: [],
  workticketAnalysisList: [],
  media: [],
  selectedMedia: {
    quality_issues: [],
    general_observations: [],
    health_and_safety_risks: [],
    services_recommendations: [],
    asset_tagging: [],
    sla_compliance: [],
    service_verification_before: [],
    service_verification_after: [],
  },
  selectedImages: {
    quality_issues: [],
    general_observations: [],
    health_and_safety_risks: [],
    services_recommendations: [],
    asset_tagging: [],
    sla_compliance: [],
    service_verification_before: [],
    service_verification_after: [],
  },
  selectedCategories: [],
  globalSelectedMediaCount: {
    quality_issues: 0,
    general_observations: 0,
    health_and_safety_risks: 0,
    services_recommendations: 0,
    asset_tagging: 0,
    sla_compliance: 0,
    service_verification: 0,
  },

  setDoneMediaFiles: (medias) =>
    set((state) => {
      const newMedia = state.media.map((item) => {
        if (medias.some((media) => media.id === item.id)) {
          return {
            ...item,
            done: false,
          };
        }
        return item;
      });
      return { media: newMedia };
    }),

  setMediaProcessed: (mediaId) =>
    set((state) => {
      const newMedia = state.media.map((item) => {
        if (mediaId === item.id) {
          return {
            ...item,
            frames: "1",
            track: false,
          };
        }
        return item;
      });
      return { media: newMedia };
    }),

  setTitleAnalysisThread: (title) =>
    set((state) => {
      const newThread = {
        ...state.analysisThread,
        name: title,
      };
      return { analysisThread: newThread };
    }),

  setStartingAnalysis: (flag) =>
    set({
      startingAnalysis: flag,
    }),

  addTabThread: (thread) =>
    set((state) => {
      const newTabThreads = [...state.tabThreads, thread];
      return { tabThreads: newTabThreads };
    }),

  removeTabThread: (workticketId) =>
    set((state) => {
      const newTabThreads = state.tabThreads.filter(
        (thread) => thread.workticket_id !== workticketId
      );
      return { tabThreads: newTabThreads };
    }),

  setGlobalThreadId: (id) =>
    set({
      globalThreadId: id,
    }),

  clearGlobalThreadId: () =>
    set({
      globalThreadId: null,
    }),

  setAnalysisComment: (flag) =>
    set({
      analysisComment: flag,
    }),

  setAnalysisStarted: (flag) =>
    set({
      analysisStarted: flag,
    }),

  setStartAnalysisFlag: (flag) =>
    set({
      startAnalysisFlag: flag,
    }),

  setMediaLimitation: (limitation) =>
    set({
      mediaLimitation: limitation,
    }),

  setAnalysisHistory: (newAnalysisHistory) =>
    set({
      analysisHistory: newAnalysisHistory,
    }),

  setAnalysisInformation: (newAnalysisInformation) =>
    set({
      analysisInformation: newAnalysisInformation,
    }),

  resetAnalysisInformation: () =>
    set(() => {
      const newAnalysisThreadList = {};
      return { analysisInformation: newAnalysisThreadList };
    }),

  setAnalysisThread: (newAnalysisThread) =>
    set({
      analysisThread: newAnalysisThread,
    }),

  setAnalysisThreadSV: (newAnalysisThread) =>
    set({
      analysisThreadSV: newAnalysisThread,
    }),
  setAnalysisResponse: (
    id,
    response,
    response_text,
    json_response,
    run_id,
    error
  ) =>
    set((state) => {
      const newAnalysis =
        state.analysisThread?.luna_lens_workticket_analysis.map((thread) => {
          if (thread.id === id) {
            return {
              ...thread,
              loading: "0",
              json_response: json_response,
              luna_lens_analysis_categories: response,
              response_text: response_text,
              run_id: run_id,
              error: error,
            };
          }
          return thread;
        });
      const newThread = {
        ...state.analysisThread,
        luna_lens_workticket_analysis: newAnalysis,
      };
      return { analysisThread: newThread };
    }),

  setAnalysisFailed: (id) =>
    set((state) => {
      const newAnalysis =
        state.analysisThread?.luna_lens_workticket_analysis.map((thread) => {
          if (thread.id === id) {
            return {
              ...thread,
              status: 2,
            };
          }
          return thread;
        });
      const newThread = {
        ...state.analysisThread,
        status: 2,
        luna_lens_workticket_analysis: newAnalysis,
      };
      return { analysisThread: newThread };
    }),

  setSavedLensAnalysis: (id, savedFlag) =>
    set((state) => {
      const newAnalysisThread =
        state.analysisThread.luna_lens_workticket_analysis.map((thread) => {
          if (thread.id === id) {
            return {
              ...thread,
              saved: savedFlag,
            };
          }
          return thread;
        });
      const newThread = {
        ...state.analysisThread,
        luna_lens_workticket_analysis: newAnalysisThread,
      };
      return { analysisThread: newThread };
    }),

  updateAnalysisTrade: (responseTrade) =>
    set((state) => {
      const newAnalysisThread =
        state.analysisThread.luna_lens_workticket_analysis.map((thread) => {
          if (thread.id === responseTrade.id) {
            return {
              ...responseTrade,
            };
          }
          return thread;
        });
      const newThread = {
        ...state.analysisThread,
        luna_lens_workticket_analysis: newAnalysisThread,
      };
      return { analysisThread: newThread };
    }),

  setAnalysisFeedback: (id, category, feedback) =>
    set((state) => {
      const newAnalysisThread =
        state.analysisThread.luna_lens_workticket_analysis.map((thread) => {
          if (thread.id === id) {
            const newResponse = thread.luna_lens_analysis_categories.map(
              (res) => {
                if (res.luna_lens_category.value === category) {
                  return {
                    ...res,
                    feedback: feedback,
                  };
                }
                return res;
              }
            );
            return {
              ...thread,
              luna_lens_analysis_categories: newResponse,
            };
          }
          return thread;
        });
      const newThread = {
        ...state.analysisThread,
        luna_lens_workticket_analysis: newAnalysisThread,
      };
      return { analysisThread: newThread };
    }),

  setWorkticketAnalysisFeedback: (id, feedback) =>
    set((state) => {
      const newAnalysisThread =
        state.analysisThread.luna_lens_workticket_analysis.map((thread) => {
          if (thread.id === id) {
            return {
              ...thread,
              feedback: feedback,
            };
          }
          return thread;
        });
      const newThread = {
        ...state.analysisThread,
        luna_lens_workticket_analysis: newAnalysisThread,
      };
      return { analysisThread: newThread };
    }),

  updateAnalysisCategory: (id, newCategory) =>
    set((state) => {
      const newAnalysisThread =
        state.analysisThread.luna_lens_workticket_analysis.map((thread) => {
          if (thread.id === id) {
            return {
              ...thread,
              luna_lens_analysis_categories: newCategory,
            };
          }
          return thread;
        });
      const newThread = {
        ...state.analysisThread,
        luna_lens_workticket_analysis: newAnalysisThread,
      };
      return { analysisThread: newThread };
    }),

  addAnalysisTrade: (id, category, title, value) =>
    set((state) => {
      const newAnalysisThread =
        state.analysisThread.luna_lens_workticket_analysis.map((thread) => {
          if (thread.id === id) {
            const newResponse = thread.luna_lens_analysis_categories.map(
              (res) => {
                if (res.luna_lens_category.value === category) {
                  return {
                    ...res,
                    luna_lens_analysis_categories_trade: [
                      ...res.luna_lens_analysis_categories_trade,
                      {
                        title: title,
                        description: value,
                      },
                    ],
                  };
                }
                return res;
              }
            );
            return {
              ...thread,
              luna_lens_analysis_categories: newResponse,
            };
          }
          return thread;
        });
      const newThread = {
        ...state.analysisThread,
        luna_lens_workticket_analysis: newAnalysisThread,
      };
      return { analysisThread: newThread };
    }),

  deleteAnalysisTrade: (id, category, tradeId) =>
    set((state) => {
      const newAnalysisThread =
        state.analysisThread.luna_lens_workticket_analysis.map((thread) => {
          if (thread.id === id) {
            const newResponse = thread.luna_lens_analysis_categories.map(
              (res) => {
                if (res.luna_lens_category.value === category) {
                  const trade = res.luna_lens_analysis_categories_trade.map(
                    (t, index) => {
                      if (index === tradeId) {
                        return {
                          ...t,
                          deleted: true,
                        };
                      }
                      return t;
                    }
                  );
                  return {
                    ...res,
                    luna_lens_analysis_categories_trade: trade,
                  };
                }
                return res;
              }
            );
            return {
              ...thread,
              luna_lens_analysis_categories: newResponse,
            };
          }
          return thread;
        });
      const newThread = {
        ...state.analysisThread,
        luna_lens_workticket_analysis: newAnalysisThread,
      };
      return { analysisThread: newThread };
    }),

  removeEmptyAnalysis: () =>
    set((state) => {
      const newAnalysisThread =
        state.analysisThread.luna_lens_workticket_analysis.map((thread) => {
          const newResponse = thread.luna_lens_analysis_categories.map(
            (res) => {
              return {
                ...res,
                luna_lens_analysis_categories_trade:
                  res?.luna_lens_analysis_categories_trade?.filter(
                    (a) => a.description.length > 0
                  ),
              };
            }
          );
          return {
            ...thread,
            luna_lens_analysis_categories: newResponse,
          };
        });
      const newThread = {
        ...state.analysisThread,
        luna_lens_workticket_analysis: newAnalysisThread,
      };
      return { analysisThread: newThread };
    }),

  resetAnalysisDetail: () =>
    set(() => {
      const newAnalysisThreadList = [];
      const newAnalysisInformation = {};
      return {
        analysisThread: newAnalysisThreadList,
        analysisInformation: newAnalysisInformation,
      };
    }),

  addAnalysisThread: (newAnalysisThread) =>
    set((state) => {
      const analysisThreadCopy = state.analysisThread;
      analysisThreadCopy.luna_lens_workticket_analysis = [
        ...analysisThreadCopy.luna_lens_workticket_analysis,
        newAnalysisThread,
      ];
      return { analysisThread: analysisThreadCopy };
    }),

  updateDescriptionEditThread: (id, category, index, description) =>
    set((state) => {
      const newAnalysisThread =
        state.analysisThread.luna_lens_workticket_analysis.map((thread) => {
          if (thread.id === id) {
            const newResponse = thread.luna_lens_analysis_categories.map(
              (res) => {
                if (res.luna_lens_category.value === category) {
                  const analysis = [...res.luna_lens_analysis_categories_trade];
                  analysis[index].description = description;
                  return {
                    ...res,
                    luna_lens_analysis_categories_trade: analysis,
                  };
                }
                return res;
              }
            );
            return {
              ...thread,
              luna_lens_analysis_categories: newResponse,
            };
          }
          return thread;
        });
      const newThread = {
        ...state.analysisThread,
        luna_lens_workticket_analysis: newAnalysisThread,
      };
      return { analysisThread: newThread };
    }),

  setGlobalSelectedMediaCount: (category, count) =>
    set((state) => {
      const newGlobalSelectedMediaCount = state.globalSelectedMediaCount;
      newGlobalSelectedMediaCount[category] = count;
      return { globalSelectedMediaCount: newGlobalSelectedMediaCount };
    }),

  setAllGlobalSelectedMediaCount: (count) =>
    set((state) => {
      const newGlobalSelectedMediaCount = state.globalSelectedMediaCount;
      newGlobalSelectedMediaCount.quality_issues = count;
      newGlobalSelectedMediaCount.general_observations = count;
      newGlobalSelectedMediaCount.health_and_safety_risks = count;
      newGlobalSelectedMediaCount.services_recommendations = count;
      newGlobalSelectedMediaCount.asset_tagging = count;
      newGlobalSelectedMediaCount.sla_compliance = count;
      newGlobalSelectedMediaCount.service_verification = count;
      return { globalSelectedMediaCount: newGlobalSelectedMediaCount };
    }),

  setSelectedCategories: (index) =>
    set((state) => {
      const newSelectedCategories = state.selectedCategories.includes(index)
        ? state.selectedCategories.filter((i) => i !== index)
        : [...state.selectedCategories, index];
      return { selectedCategories: newSelectedCategories };
    }),

  resetSelectedCategories: () =>
    set({
      selectedCategories: [],
    }),

  resetSelectedImages: () =>
    set({
      selectedImages: {
        quality_issues: [],
        general_observations: [],
        health_and_safety_risks: [],
        services_recommendations: [],
        asset_tagging: [],
        sla_compliance: [],
        service_verification_before: [],
        service_verification_after: [],
      },
    }),

  setNewSelectedImages: (newSelectedImages) =>
    set({
      selectedImages: newSelectedImages,
    }),

  setSelectedImages: () =>
    set((state) => {
      const selectedImagesUrls = state.selectedMedia;
      const selectedImagesUrlsKeys = {};
      selectedImagesUrlsKeys.quality_issues =
        selectedImagesUrls.quality_issues.map((index) => state.media[index]);
      selectedImagesUrlsKeys.general_observations =
        selectedImagesUrls.general_observations.map(
          (index) => state.media[index]
        );
      selectedImagesUrlsKeys.health_and_safety_risks =
        selectedImagesUrls.health_and_safety_risks.map(
          (index) => state.media[index]
        );
      selectedImagesUrlsKeys.services_recommendations =
        selectedImagesUrls.services_recommendations.map(
          (index) => state.media[index]
        );
      selectedImagesUrlsKeys.asset_tagging =
        selectedImagesUrls.asset_tagging.map((index) => state.media[index]);
      selectedImagesUrlsKeys.sla_compliance =
        selectedImagesUrls.sla_compliance.map((index) => state.media[index]);
      selectedImagesUrlsKeys.service_verification_before =
        selectedImagesUrls.service_verification_before.map(
          (index) => state.media[index]
        );
      selectedImagesUrlsKeys.service_verification_after =
        selectedImagesUrls.service_verification_after.map(
          (index) => state.media[index]
        );
      return { selectedImages: selectedImagesUrlsKeys };
    }),

  setSelectedMedia: (index, category) =>
    set((state) => {
      const newSelectedMediaCategory = state.selectedMedia[category].includes(
        index
      )
        ? state.selectedMedia[category].filter((i) => i !== index)
        : [...state.selectedMedia[category], index];
      const newSelectedMedia = state.selectedMedia;
      newSelectedMedia[category] = newSelectedMediaCategory;
      return { selectedMedia: newSelectedMedia };
    }),

  setAllSelectedMedia: (indexList, category) =>
    set((state) => {
      const newSelectedMedia = state.selectedMedia;
      newSelectedMedia[category] = indexList;
      return { selectedMedia: newSelectedMedia };
    }),

  resetSelectedMedia: () =>
    set({
      selectedMedia: {
        quality_issues: [],
        general_observations: [],
        health_and_safety_risks: [],
        services_recommendations: [],
        asset_tagging: [],
        sla_compliance: [],
        service_verification_before: [],
        service_verification_after: [],
      },
    }),

  resetSelectedMediaByCategory: (category) =>
    set((state) => {
      const newSelectedMedia = state.selectedMedia;
      newSelectedMedia[category] = [];
      return { selectedMedia: newSelectedMedia };
    }),

  addMedia: (newMedia) =>
    set((state) => {
      const newMediaList = [...state.media, ...newMedia];
      return { media: newMediaList };
    }),

  setMedia: (newMedia) =>
    set({
      media: newMedia,
    }),

  setAnalysisDetail: (newAnalysisDetail) =>
    set({
      analysisDetail: newAnalysisDetail,
    }),

  setWorkticketAnalysisList: (newWorkticketList) =>
    set({
      workticketAnalysisList: [...newWorkticketList],
    }),

  addWorkticketAnalysisList: (newAnalysis) =>
    set((state) => {
      const newWorkticketAnalysisList = [
        newAnalysis,
        ...state.workticketAnalysisList,
      ];
      return { workticketAnalysisList: newWorkticketAnalysisList };
    }),

  updateWorkticketAnalysisList: (newAnalysis) =>
    set((state) => {
      const newWorkticketAnalysisList = state.workticketAnalysisList.map(
        (analysis) => {
          if (analysis.id === newAnalysis.id) {
            return newAnalysis;
          }
          return analysis;
        }
      );
      return { workticketAnalysisList: newWorkticketAnalysisList };
    }),

  deleteWorkticketAnalysisList: (analysisId) =>
    set((state) => {
      const newWorkticketAnalysisList = state.workticketAnalysisList.filter(
        (analysis) => analysis.id !== analysisId
      );
      return { workticketAnalysisList: newWorkticketAnalysisList };
    }),

  updateDescription: (indexCategory, index, value) =>
    set((state) => {
      const newInputs = [...state.analysis];
      newInputs[indexCategory].analysis[index].description = [value];
      return { analysis: newInputs };
    }),

  updateDescriptionEdit: (indexCategory, index, value) =>
    set((state) => {
      const newInputs = [...state.analysis];
      newInputs[indexCategory].luna_lens_analysis_categories_trade[
        index
      ].description = value;
      return { analysis: newInputs };
    }),

  addFeedback: (indexCategory, feedback) =>
    set((state) => {
      const Analysis = state.analysis[indexCategory];
      Analysis.feedback = feedback;
      const newInputs = state.analysis.map((analysis, index) =>
        index === indexCategory ? Analysis : analysis
      );
      return { analysis: newInputs };
    }),

  addTrade: (indexCategory, title, value) =>
    set((state) => {
      const inputs = [...state.analysis];
      inputs[indexCategory].analysis.push({
        title: title,
        description: [value],
      });
      return { analysis: inputs };
    }),

  addTradeEdit: (indexCategory, title, value) =>
    set((state) => {
      const inputs = [...state.analysis];
      inputs[indexCategory].luna_lens_analysis_categories_trade.push({
        title: title,
        description: value,
        edit: true,
      });
      return { analysis: inputs };
    }),

  removeAnalysis: (index) =>
    set((state) => {
      const newInputs = state.analysis.filter((_, i) => i !== index);
      return { analysis: newInputs };
    }),

  setAnalysisList: (newInputsList) =>
    set({
      analysis: [...newInputsList],
    }),

  setCategoryList: (newCategoryList) =>
    set({
      categoryList: [...newCategoryList],
    }),
  setSVThread: (newSVThread) =>
    set((state) => {
      const sortedSVThread = newSVThread?.sort((a, b) => {
        if (a.saved === "0" && b.saved === "1") return -1;
        if (a.saved === "1" && b.saved === "0") return 1;
        return 0;
      });
      // sortedSVThread?.pop();
      return { svThread: sortedSVThread };
    }),

  setCategorySelected: (categoryValue, selectedCount) =>
    set((state) => {
      const newCategoryList = state.categoryList.map((category) => {
        if (category.value === categoryValue) {
          category.selected = selectedCount > 0;
          category.files = selectedCount;
        }
        return category;
      });
      return { categoryList: newCategoryList };
    }),

  resetCategories: () =>
    set((state) => {
      const newCategoryList = state.categoryList.map((category) => {
        category.selected = false;
        category.files = 0;
        return category;
      });
      return { categoryList: newCategoryList };
    }),

  resetCategoriesByCategory: (category) =>
    set((state) => {
      const newCategoryList = state.categoryList.map((category) => {
        if (category.value === category) {
          category.selected = false;
          category.files = 0;
        }
        return category;
      });
      return { categoryList: newCategoryList };
    }),

  resetAllCategories: () =>
    set((state) => {
      const newCategoryList = state.categoryList.map((category) => {
        category.selected = false;
        category.files = 0;
        return category;
      });
      return { categoryList: newCategoryList };
    }),

  updateAnalysis: (indexCategory, newCategory) =>
    set((state) => {
      const newInputs = [...state.analysis];
      newInputs[indexCategory] = newCategory;
      return { analysis: newInputs };
    }),

  addCategory: (newCategory) =>
    set((state) => {
      const newCategoryList = [...state.categoryList, newCategory];
      return { categoryList: newCategoryList };
    }),
}));

export default useLunaLensStore;
