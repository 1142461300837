import React, { useState, useEffect, useContext } from "react";
import GlobalUiContext from "contexts/globalUiContext";
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  IconButton,
  LinearProgress,
} from "@material-ui/core";
import {
  imageIcon,
  documentIcon,
  videoIcon,
  checkCircle,
  radioButtonUnchecked,
  infoIcon,
  linkIcon,
  deleteIcon,
  mismatchIcon,
} from "./icons";
import PauseCircleOutlineIcon from "@material-ui/icons/PauseCircleOutline";
import LoadingStateHorizontal from "../LoadingStateHorizontal/LoadingStateHorizontal";
import MessageDialogResumeFiles from "./dialog/messageDialogResumeFiles";
import ErrorIcon from "@material-ui/icons/Error";
import { logException } from "components/util/logUtil";
import {
  workticketFiles,
  changeBeforeAfterTag,
} from "services/workticketService";
import { useWorkticketView } from "contexts/workticketViewContext";
import {
  useUploadFileState,
  useUploadFileDispatch,
} from "contexts/uploadFileContext";
import classNames from "classnames";
import useStyles from "./styles";
import useLunaLensStore from "store/lunaLensAnalysis";
import FileMediaTimeType from "components/common/File/fileMediaTimeType";
import { deleteFile } from "services/fileService";
import { permissionSurface, hasPermission } from "lib/permissions";

const COMPLETED = "Completed";
const FAILED = "Failed";
const IN_PROGRESS = "In Progress";
const QUEUED = "Queued";

const iconMap = {
  1: documentIcon(),
  2: imageIcon(),
  3: videoIcon(),
  4: linkIcon(),
};

const changeUploadFileStatus = (dispatch, isUploadCompleted) => {
  dispatch({
    type: "SET_UPLOAD_COMPLETED",
    isUploadCompleted: isUploadCompleted,
  });
};

const changeUploadFileStatusInspection = (dispatch, isUploadFile) => {
  dispatch({
    type: "SET_LOADING",
    isUploadFile: isUploadFile,
  });
};

const setWorkTicketFiles = (files, dispatch) => {
  dispatch({
    type: "SET_WORKTICKET_FILES",
    workticketFiles: files,
  });
};

const UploadProgress = ({ workTicketId, activeTab, workticket }) => {
  const classes = useStyles();

  const [finishUpload, setFinishUpload] = useState(false);
  const [openMessage, setOpenMessage] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [, dispatchContext] = useWorkticketView();
  const uploadFileDispatch = useUploadFileDispatch();
  const { uploadFiles } = useUploadFileState();

  const [messageCompletedFiles, setMessageCompletedFiles] = useState("");
  const [messageFailedFiles, setMessageFailedFiles] = useState("");
  const [messageFailedFilesList, setMessageFailedFilesList] = useState("");
  const { globalUi } = useContext(GlobalUiContext);
  const { permissions } = globalUi;

  const setMedia = useLunaLensStore((state) => state.setMedia);

  useEffect(() => {
    const inProgressFiles = uploadFiles.all?.filter(
      (item) => item.status === IN_PROGRESS || item.status === QUEUED
    );
    setFinishUpload(inProgressFiles?.length === 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadFiles.all, activeTab]);

  useEffect(() => {
    if (finishUpload) {
      handleFinish();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [finishUpload]);

  const handleFinish = async () => {
    try {
      setIsLoading(true);
      setOpenMessage(true);

      const response = await workticketFiles(workTicketId);
      const allLensMedia = response.data.data.all
        .map((item) => {
          return {
            id: item.id,
            url: item.file_url,
            mime_type: item.mime_type,
            frames: item.frames,
            track: item.mime_type === "3" && item.frames === "0" ? true : false,
            done: item.mime_type === "3" && item.frames === "0" ? true : false,
          };
        })
        .filter((item) => {
          const mimeTypes = ["2", "3", "5"];
          return mimeTypes.some((type) => item.mime_type.includes(type));
        });
      setMedia(allLensMedia);
      setWorkTicketFiles(response.data.data, dispatchContext);

      const allFiles = uploadFiles.all || [];
      const totalCount = allFiles.length;
      const completedCount = allFiles.filter(
        (file) => file.status === COMPLETED
      ).length;
      const failedCount = allFiles.filter(
        (file) => file.status === FAILED
      ).length;

      setMessageCompletedFiles(
        completedCount > 0 ? ` ${completedCount}/${totalCount}` : ""
      );
      setMessageFailedFiles(
        failedCount > 0 ? ` ${failedCount}/${totalCount}` : ""
      );
      setMessageFailedFilesList(
        failedCount > 0
          ? allFiles
              .filter((file) => file.status === FAILED)
              .map((file) => file.display_name)
              .join("|")
          : ""
      );
    } catch (error) {
      logException(error, "Error finishing upload");
    } finally {
      setIsLoading(false);
    }
  };

  const closeMessageDialog = () => {
    parseInt(workticket?.service_type) !== 2 &&
    !hasPermission(permissionSurface.KPI, permissions)
      ? changeUploadFileStatus(uploadFileDispatch, true)
      : changeUploadFileStatusInspection(uploadFileDispatch, false);
    setOpenMessage(false);
  };

  const updateFileId = (fileName, fileId, dispatch, metadata) => {
    dispatch({
      type: "UPDATE_UPLOAD_FILE_ID",
      fileName,
      fileId,
      metadata,
    });
  };

  const handleChangeType = async (fileSelected, status) => {
    const response = await changeBeforeAfterTag(fileSelected.metadata.id, {
      before_after_tag: status,
    });
    if (response.status === 200) {
      updateFileId(
        fileSelected.display_name,
        fileSelected.id,
        uploadFileDispatch,
        response.data.data
      );
    }
  };

  const handleDeleteFile = async (fileSelected) => {
    const response = await deleteFile(fileSelected.id);
    if (response.status === 204) {
      const newUploadFiles = {
        ...uploadFiles,
        all: uploadFiles.all.filter((file) => file.id !== fileSelected.id),
        [activeTab]: uploadFiles[activeTab].filter(
          (file) => file.id !== fileSelected.id
        ),
      };
      uploadFileDispatch({
        type: "SET_UPLOAD_FILES",
        uploadFiles: newUploadFiles,
      });
    }
  };

  if (!uploadFiles || !Array.isArray(uploadFiles.all))
    return <LoadingStateHorizontal isVisible />;
  return (
    <Box width="100%" style={{ marginTop: 15, marginBottom: 40 }}>
      <TableContainer>
        <Table size="small">
          <TableBody>
            {uploadFiles[activeTab].map((item, index) => (
              <>
                <TableRow
                  key={item.id}
                  className={classNames(
                    {
                      [classes.stripedRow]: index % 2 === 0,
                    },
                    classes.borderRow
                  )}
                >
                  <TableCell className={classes.imageCell}>
                    <Box className={classes.progressIconBox}>
                      {iconMap[item.mime_type]}
                    </Box>
                  </TableCell>
                  <TableCell className={classes.fullWidthCell}>
                    <Box display="flex" flexDirection="column">
                      {item.status === COMPLETED && item.metadata ? (
                        <Box className={classes.mediaTimeTimeContainer}>
                          <FileMediaTimeType
                            fileSelected={item}
                            onChange={handleChangeType}
                          />
                        </Box>
                      ) : null}
                      <Typography
                        className={classes.progressBarFileName}
                        align="left"
                      >
                        {item.display_name}
                      </Typography>
                      {item.status !== COMPLETED && (
                        <Box>
                          <LinearProgress
                            className={classNames(classes.progressBar, {
                              [classes.progressBarFailed]:
                                item.status === FAILED,
                            })}
                            variant="determinate"
                            color="secondary"
                            value={
                              typeof item.progress === "number" &&
                              item.progress >= 0 &&
                              item.progress <= 100
                                ? item.progress
                                : 0
                            }
                          />
                          {item.status === FAILED && (
                            <Box display="flex" alignItems="center" mt={1}>
                              <Box className={classes.centeredIconButton}>
                                {infoIcon()}
                              </Box>
                              <Box ml={1}>
                                <Typography
                                  className={classes.progressBarMessage}
                                >
                                  Upload failed: We're sorry, but your file
                                  could not be uploaded.
                                </Typography>
                              </Box>
                            </Box>
                          )}
                        </Box>
                      )}
                    </Box>
                  </TableCell>
                  <TableCell className={classes.iconCell} pr={0}>
                    <Box display="flex" alignItems="center">
                      <Box>
                        {item.status === QUEUED ? (
                          <IconButton>
                            <PauseCircleOutlineIcon
                              fontSize="small"
                              className={classes.iconQueued}
                            />
                          </IconButton>
                        ) : item.status === COMPLETED ? (
                          <Box className={classes.actionButtonsContainer}>
                            <IconButton>{checkCircle()}</IconButton>
                            <IconButton onClick={() => handleDeleteFile(item)}>
                              {deleteIcon()}
                            </IconButton>
                          </Box>
                        ) : item.status === FAILED ? (
                          <IconButton>
                            <ErrorIcon className={classes.iconError} />
                          </IconButton>
                        ) : (
                          <IconButton>{radioButtonUnchecked()}</IconButton>
                        )}
                      </Box>
                    </Box>
                  </TableCell>
                </TableRow>
                {item.metadata?.has_geo_mismatch ? (
                  <TableRow>
                    <TableCell colSpan={3} className={classes.mismatchCell}>
                      <Box
                        className={classes.mismatchContainer}
                        display="flex"
                        alignItems="center"
                      >
                        <Box className={classes.centeredIconButton}>
                          {mismatchIcon()}
                        </Box>
                        <Box ml={1}>
                          <Typography className={classes.progressBarMessage}>
                            Potential Location Mismatch Detected
                          </Typography>
                        </Box>
                      </Box>
                    </TableCell>
                  </TableRow>
                ) : null}
              </>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <MessageDialogResumeFiles
        open={openMessage}
        title={"File Upload completed"}
        successFiles={messageCompletedFiles}
        failedFiles={messageFailedFiles}
        listFailed={messageFailedFilesList}
        handleClose={closeMessageDialog}
        isLoadingData={isLoading}
      />
    </Box>
  );
};

export default UploadProgress;
