export function TwoThumbsUpSV({ strokeColor = "#D9D9D9" }) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1003_7309)">
        <path
          d="M11.1294 9.27357C11.1294 10.1891 10.9174 11.0593 10.5305 11.8339C10.3132 12.2766 10.0429 12.6891 9.72494 13.0613C9.51825 13.3027 9.20027 13.4235 8.87168 13.4235H6.74647C6.48679 13.4235 6.2324 13.3883 5.98861 13.3078L4.34038 12.7897C4.09659 12.7092 3.8422 12.6689 3.58781 12.6689H3.17443C2.95184 12.6689 2.73985 12.5532 2.66035 12.352C2.48016 11.9245 2.38477 11.4617 2.38477 10.9738C2.38477 10.0935 2.70275 9.28363 3.23273 8.64479C3.31222 8.54419 3.42882 8.48886 3.55071 8.44359C3.7998 8.35304 4.02239 8.18202 4.19199 7.97578C4.60007 7.48282 5.11945 7.07035 5.70772 6.76854C6.0946 6.57739 6.42319 6.29067 6.58748 5.90838C6.69878 5.64178 6.75707 5.35506 6.75707 5.06834V4.7464C6.75707 4.54017 6.93197 4.36914 7.15456 4.36914C7.81173 4.36914 8.347 4.87719 8.347 5.50093C8.347 5.69208 8.33111 5.88323 8.29931 6.06431C8.24631 6.43655 8.12971 6.79369 7.96012 7.12065C7.82233 7.40234 8.01842 7.76451 8.347 7.76451H10.0058C10.5464 7.76451 11.034 8.11663 11.0923 8.6297C11.1135 8.84097 11.1294 9.05727 11.1294 9.27357Z"
          fill={strokeColor}
        />
        <path
          d="M16.0005 6.90426C16.0005 8.31271 15.497 9.60547 14.6437 10.6316C14.4529 10.863 14.0555 11.0542 13.7481 11.0542H11.8614C12.1369 10.4304 12.1899 9.40929 12.1899 8.4938C12.1899 8.48877 12.1899 8.48374 12.1899 8.47871C12.1899 7.64873 11.4427 6.98475 10.5682 6.98475H9.40756C8.85638 6.98475 9.07897 6.4264 9.12667 6.10446C9.15847 5.89823 9.26977 5.54611 9.27507 5.45054C9.28037 5.31976 9.47116 5.16382 9.52945 5.11352C10.1336 4.59038 11.1353 4.30366 11.4533 3.53907C11.5646 3.27247 11.6229 2.98575 11.6229 2.69903V2.3771C11.6229 2.18092 11.7819 2.00487 11.9885 1.99984C12.6616 1.98475 13.2128 2.49782 13.2128 3.13163C13.2128 3.76543 13.0644 4.29863 12.8047 4.80165C12.6616 5.07831 12.8895 5.39521 13.2128 5.39521H14.8769C15.4175 5.39521 15.8945 5.76744 15.9581 6.27549C15.9846 6.48173 15.9952 6.693 15.9952 6.90426H16.0005Z"
          fill={strokeColor}
        />
        <path
          d="M0.439881 8.7608C0.153693 9.44491 0 10.1944 0 10.9741C0 11.5878 0.0953958 12.1813 0.275588 12.7397C0.413382 13.1672 0.847963 13.4288 1.31964 13.4288H1.80192C2.04041 13.4288 2.1835 13.1773 2.07751 12.9761C1.76482 12.3725 1.58993 11.6934 1.58993 10.9741C1.58993 10.1139 1.84432 9.30909 2.2789 8.62499C2.40609 8.42378 2.263 8.14209 2.01391 8.14209H1.45744C1.01756 8.14209 0.604174 8.36845 0.439881 8.7608Z"
          fill={strokeColor}
        />
      </g>
      <defs>
        <clipPath id="clip0_1003_7309">
          <rect
            width="16"
            height="11.4286"
            fill="white"
            transform="translate(0 2)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
