import { createContext, useContext, useReducer } from "react";
const Context = createContext(null);

export const Consumer = Context.Consumer;

const SVDialogues = createContext();
const initialState = {
  isModifyRatingOpen: false,
  rating: null,
  isDeleteUserDialogOpen: false,
  ratingToDelete: null,
};
export const reducer = (state, action) => {
  switch (action.type) {
    case "TOGGLE_MODIFY_RATING":
      return {
        ...state,
        isModifyRatingOpen: action.open,
        rating: action.rating,
      };
    case "TOGGLE_DELETE_USER_DIALOG":
      return {
        ...state,
        isDeleteUserDialogOpen: action.open,
        ratingToDelete: action.ratingToDelete,
      };
    default:
      return state;
  }
};
const SVDialoguesProvider = ({ children }) => (
  <SVDialogues.Provider value={useReducer(reducer, initialState)}>
    {children}
  </SVDialogues.Provider>
);
const useDialogSV = () => {
  const context = useContext(SVDialogues);
  if (context === undefined) {
    throw new Error("useDialogSV must be used within a SVDialoguesProvider");
  }
  return context;
};

export { SVDialoguesProvider, useDialogSV };
