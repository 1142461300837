import React, { useState, useEffect } from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import LinearProgress from "@material-ui/core/LinearProgress";
import FormHelperText from "@material-ui/core/FormHelperText";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import ThumbUpAltIcon from "assets/icons/handThumbUp";
import ThumbDownAltIcon from "assets/icons/handThumbDown";
import { LunaLogo } from "assets/icons/lunaLogo";
import LoadingIndicator from "components/common/LoadingIndicator/loadingIndicator";
import FormSelectAuto from "components/ui/FormContent/formSelectAuto";
import MessageDialog from "components/ui/dialog/messageDialog";
import { logException } from "components/util/logUtil";
import { getReasons, saveFeedback } from "services/verificationStandardService";
import useStyles from "./styles";

const validateFields = (reason, comment) => {
  const errors = {};
  const validationRules = {
    reason: !reason.length,
    comment: !comment,
  };
  Object.keys(validationRules).forEach((key) => {
    if (validationRules[key]) {
      errors[key] = validationRules[key];
    }
  });
  return errors;
};

const FeedbackDialog = (props) => {
  const classes = useStyles();
  const { runId, userId, userEmail, standardDetailId } = props;
  const [isFeedbackModalOpen, setIsFeedbackModalOpen] = useState(false);
  const [selectedFeedbackType, setSelectedFeedbackType] = useState(null);
  const [isFeedbackLoading, setIsFeedbackLoading] = useState(true);
  const [isSuccessDialogOpen, setIsSuccessDialogOpen] = useState(false);
  const [isLoadingDialogVisible, setIsLoadingDialogVisible] = useState(false);
  const [feedbackReasonOptions, setFeedbackReasonOptions] = useState([]);
  const [selectedFeedbackReasons, setSelectedFeedbackReasons] = useState([]);
  const [feedbackComment, setFeedbackComment] = useState("");
  const [isReasonError, setIsReasonError] = useState(false);
  const [isCommentError, setIsCommentError] = useState(false);

  useEffect(() => {
    const fetchFeedbackOptions = async () => {
      try {
        setIsFeedbackLoading(true);
        const { data } = await getReasons();
        setFeedbackReasonOptions(data.feedbackReasons);
      } catch (error) {
        logException(error, "Failed to fetch feedback options");
      } finally {
        setIsFeedbackLoading(false);
      }
    };

    fetchFeedbackOptions();
  }, []);

  const handleCloseModal = () => {
    setIsFeedbackModalOpen(false);
    setSelectedFeedbackType(null);
    setSelectedFeedbackReasons([]);
    setFeedbackComment("");
    setIsReasonError(false);
    setIsCommentError(false);
  };

  const handleFeedbackChange = (event, newValue) => {
    setSelectedFeedbackReasons(newValue.map((r) => r.value) || []);
    setIsReasonError(false);
  };

  const handleFeedbackClick = (type) => {
    setSelectedFeedbackType(type);
    setIsFeedbackModalOpen(true);
  };

  const handleFeedbackTypeChange = (event, newValue) => {
    setSelectedFeedbackType(newValue);
    setSelectedFeedbackReasons([]);
  };

  const handleThumbUpClick = async () => {
    const feedbackData = createFeedbackData(1);
    setIsLoadingDialogVisible(true);

    try {
      await saveFeedback(feedbackData);
      setIsLoadingDialogVisible(false);
      handleCloseModal();
      setIsSuccessDialogOpen(true);
    } catch (error) {
      logException(error, "Failed to submit feedback");
    }
  };

  const handleConfirm = async () => {
    const errors = validateFields(selectedFeedbackReasons, feedbackComment);
    if (errors.reason || errors.comment) {
      setIsReasonError(!!errors.reason);
      setIsCommentError(!!errors.comment);
      return;
    }

    setIsLoadingDialogVisible(true);
    const feedbackData = createFeedbackData(0, true);

    try {
      await saveFeedback(feedbackData);
      setIsLoadingDialogVisible(false);
      handleCloseModal();
      setIsSuccessDialogOpen(true);
    } catch (error) {
      logException(error, "Failed to submit feedback");
      setIsLoadingDialogVisible(false);
    }
  };

  const createFeedbackData = (score, requiredComment = false) => {
    const selectedCategory = selectedFeedbackType?.label || "Unknown Category";
    const formattedReasons = selectedFeedbackReasons.map((id) => {
      const reason = feedbackReasonOptions
        .flatMap((option) => option.reasons)
        .find((r) => r.id === id);

      if (reason) {
        return `${reason.id}, ${selectedCategory}, ${reason.name}`;
      }

      return `${id}, ${selectedCategory}, Unknown Reason`;
    });

    return {
      feedback_date: new Date().toISOString(),
      feedback_score: score,
      feedback_comment: requiredComment
        ? feedbackComment.trim()
        : feedbackComment,
      feedback_reasons: formattedReasons,
      run_id: runId,
      user_id: userId,
      user_email: userEmail,
      standard_detail_id: standardDetailId,
    };
  };

  const closeSuccessDialog = () => {
    setIsSuccessDialogOpen(false);
  };

  return (
    <>
      <Box className={classes.containerFeedbackButtonSearch}>
        <IconButton
          className={classes.feedbackButton}
          onClick={handleThumbUpClick}
          style={{ marginRight: 8, marginLeft: 10 }}
        >
          <ThumbUpAltIcon />
        </IconButton>
        <IconButton
          className={classes.feedbackButton}
          onClick={() => handleFeedbackClick(0)}
        >
          <ThumbDownAltIcon />
        </IconButton>
      </Box>

      <Box className={classes.container}>
        <Dialog
          open={isLoadingDialogVisible}
          aria-labelledby="loading-dialog-title"
          aria-describedby="loading-dialog-description"
          maxWidth="xs"
          fullWidth
        >
          <DialogContent>
            <LoadingIndicator />
          </DialogContent>
        </Dialog>

        <Dialog
          open={isFeedbackModalOpen && !isLoadingDialogVisible}
          onClose={handleCloseModal}
          aria-labelledby="feedback-modal-title"
          maxWidth="xs"
          fullWidth
        >
          <DialogTitle className={classes.wrapperDialog}>
            <Box className={classes.wrapperTitle}>
              <Box display="flex" alignItems="center">
                <LunaLogo />
                <Typography
                  variant="caption"
                  gutterBottom
                  className={classes.titleDialog}
                >
                  Luna Feedback
                </Typography>
              </Box>
              <IconButton
                aria-label="close"
                onClick={handleCloseModal}
                className={classes.wrapperDialogClose}
              >
                <CloseIcon />
              </IconButton>
            </Box>
          </DialogTitle>

          <DialogContent>
            {isFeedbackLoading ? (
              <LinearProgress color="secondary" />
            ) : (
              <Box className={classes.selectContainer} mt={2}>
                <FormSelectAuto
                  name="feedbackType"
                  label="Select Feedback Category"
                  options={feedbackReasonOptions.map((option) => ({
                    value: option.id,
                    label: option.label,
                  }))}
                  value={selectedFeedbackType}
                  handleChange={handleFeedbackTypeChange}
                />
                {selectedFeedbackType?.value && (
                  <>
                    <Box mt={2}>
                      <FormSelectAuto
                        multiple
                        name="feedbackReasons"
                        label="Select Reasons"
                        placeholder="Select Reasons"
                        noTruncate={true}
                        lineHeight="80%"
                        options={
                          feedbackReasonOptions
                            .filter(
                              (option) =>
                                option.id === selectedFeedbackType.value
                            ) //filter categories by selected type
                            .flatMap((option) => {
                              const reasons = option.reasons
                                .filter(
                                  (reason) =>
                                    String(reason.reason_category_id) ===
                                    String(selectedFeedbackType.value)
                                )
                                .map((reason) => ({
                                  value: reason.id,
                                  label: reason.name,
                                }));
                              return reasons;
                            }) || []
                        }
                        value={selectedFeedbackReasons
                          .map((reasonId) => {
                            // mapping reasons reasons to the appropriate format
                            const reason = feedbackReasonOptions
                              .find(
                                (option) =>
                                  option.id === selectedFeedbackType.value
                              )
                              ?.reasons.find(
                                (reason) => reason.id === reasonId
                              );
                            return reason
                              ? { value: reason.id, label: reason.name }
                              : null;
                          })
                          .filter(Boolean)}
                        handleChange={handleFeedbackChange}
                        error={
                          isReasonError
                            ? [
                                {
                                  key: "tellUsMore",
                                  message:
                                    "At least one feedback reason is required",
                                },
                              ]
                            : []
                        }
                      />
                    </Box>
                  </>
                )}
              </Box>
            )}
            <Box className={classes.selectContainer}>
              <FormControl fullWidth>
                <TextField
                  multiline
                  id="comment"
                  size="small"
                  variant="standard"
                  name="comment"
                  placeholder="Write here ..."
                  minRows={5}
                  onChange={(event) => {
                    setFeedbackComment(event.target.value);
                    setIsCommentError(false);
                  }}
                  value={feedbackComment}
                  error={isCommentError}
                  InputProps={{ disableUnderline: true }}
                  className={classes.textArea}
                />
                {isCommentError && (
                  <FormHelperText className={classes.wrapperError} error>
                    Comment is required
                  </FormHelperText>
                )}
              </FormControl>
            </Box>
          </DialogContent>
          <DialogActions className={classes.actionItemButton}>
            <Button
              type="button"
              onClick={handleCloseModal}
              className={classes.buttonCancel}
            >
              Cancel
            </Button>
            <Button
              type="button"
              onClick={handleConfirm}
              color="primary"
              variant="contained"
              className={classes.buttonConfirm}
              disabled={
                !selectedFeedbackReasons.length || !feedbackComment.trim()
              }
            >
              Submit Feedback
            </Button>
          </DialogActions>
        </Dialog>
      </Box>

      <MessageDialog
        open={isSuccessDialogOpen}
        message="Your feedback has been submitted!"
        handleClose={closeSuccessDialog}
      />
    </>
  );
};

export default FeedbackDialog;
