import React, { useEffect, useState } from "react";
import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { SelectedImageCheck } from "../images/checkIcon";
import { filmIcon, videoIcon } from "components/common/File/icons";
import { getVideoFrame } from "services/lunaLensService";
import PhotoIcon from "@material-ui/icons/Photo";
import classnames from "classnames";
import LoadingMediaIndicator from "components/common/LoadingMediaIndicator/LoadingMediaIndicator";
import { ChatBubbleIcon } from "../icons/ChatBubbleIcon";

const useStyles = makeStyles((theme) => ({
  image: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },
  imageItem: {
    width: "100%",
    paddingTop: "100%",
    position: "relative",
    borderRadius: 6,
    overflow: "hidden",
    transition: "300ms",
  },
  imageItemSelected: {
    filter: "brightness(0.8)",
  },
  selectedIcon: {
    position: "absolute",
    top: theme.spacing(1),
    right: theme.spacing(1),
  },
  selectedImage: {
    cursor: "pointer",
    "&:hover": {
      filter: "brightness(0.8)",
    },
  },
  iconContainer: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    // zIndex: 9,
  },
  outerBox: {
    position: "relative",
    display: "inline-block",
  },
  thumbnailIconContainerSV: {
    color: "#fff",
    borderRadius: 4,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    top: "8%",
    right: "8%",
    transform: "translate(0, 0)",
  },
  thumbnailIconContainer: {
    color: "#fff",
    borderRadius: 4,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
  thumbnailIconSize: {
    width: 25,
    height: 25,
  },
  backgroundBlue: {
    background: "#4F98BC !important",
    boxShadow: "-3px 3px rgba(23, 69, 122, 1)",
  },
  backgroundWhite: {
    background: "#fff !important",
  },
}));

const ImageGalleryGridItem = ({
  url,
  index,
  isSelected,
  track,
  done,
  onClick,
  onOpenImage,
  viewOnly,
  id,
  mime_type,
  reportType,
}) => {
  const classes = useStyles();
  const [frame, setFrame] = useState();

  useEffect(() => {
    const fetchVideoFrame = async () => {
      if (mime_type === "3" || mime_type === "5") {
        const fileFrame = await getVideoFrame({ file_id: id });
        setFrame(fileFrame?.data.file_frame?.file_url);
      }
    };
    fetchVideoFrame();
  }, [mime_type, id, track]);
  return (
    <Box
      style={{ backgroundColor: "#EDF5F8" }}
      className={[
        classes.imageItem,
        viewOnly || track ? "" : classes.selectedImage,
      ].join(" ")}
      onClick={() => {
        if (track) return;
        if (viewOnly) {
          onOpenImage({ index });
        } else {
          onClick(index);
        }
      }}
    >
      {mime_type === "3" || mime_type === "5" ? (
        frame ? (
          <>
            <img
              src={frame}
              alt={`Grid item #${index}`}
              className={`${classes.image} ${
                isSelected ? classes.imageItemSelected : ""
              }`}
            />
            <Box className={classes.iconContainer}>
              <Box
                className={classnames(
                  classes.thumbnailIconContainer,
                  classes.thumbnailIconSize,
                  classes.backgroundBlue
                )}
              >
                {filmIcon()}
              </Box>
            </Box>
          </>
        ) : (
          <Box className={classes.iconContainer}>
            {track ? (
              <LoadingMediaIndicator
                isVisible={mime_type === "3"}
                pending={done}
                id={id}
              />
            ) : (
              <Box
                className={classnames(
                  classes.thumbnailIconContainer,
                  classes.thumbnailIconSize,
                  classes.backgroundBlue
                )}
              >
                {filmIcon()}
              </Box>
            )}
          </Box>
        )
      ) : (
        <>
          <img
            src={url}
            alt={`Grid item #${index}`}
            className={`${classes.image} ${
              isSelected ? classes.imageItemSelected : ""
            }`}
          />
          {reportType === "1" && (
            <Box
              className={classnames(
                classes.thumbnailIconContainerSV,
                classes.thumbnailIconSize,
                classes.backgroundWhite
              )}
            >
              {<ChatBubbleIcon />}
            </Box>
          )}
        </>
      )}
      {isSelected && !viewOnly && (
        <Box className={classes.selectedIcon}>
          <SelectedImageCheck />
        </Box>
      )}
    </Box>
  );
};

export default ImageGalleryGridItem;
