import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import useStyles from "./styles";
import { useUploadFileDispatch } from "contexts/uploadFileContext";
import { workticketFiles } from "services/workticketService";
import { useWorkticketView } from "contexts/workticketViewContext";

const ConfirmUpload = ({ workTicketId }) => {
  const classes = useStyles();
  const uploadFileDispatch = useUploadFileDispatch();
  const [, dispatchContext] = useWorkticketView();

  const handleConfirmClick = async () => {
    const response = await workticketFiles(workTicketId);
    dispatchContext({
      type: "SET_WORKTICKET_FILES",
      workticketFiles: response.data.data,
    });
    uploadFileDispatch({
      type: "SET_UPLOAD_COMPLETED",
      isUploadCompleted: false,
    });
    uploadFileDispatch({
      type: "SET_LOADING",
      isUploadFile: false,
    });
  };

  return (
    <Box className={classes.confirmUploadContainer}>
      <Button
        className={classes.buttonConfirm}
        variant="outlined"
        onClick={handleConfirmClick}
      >
        <Typography variant="body1">Confirm Upload</Typography>
      </Button>
    </Box>
  );
};

export default ConfirmUpload;
