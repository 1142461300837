import React, { useState } from "react";
import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Carousel from "react-material-ui-carousel";

import ImageGrid from "./imageGalleryGrid";
import ImageGallerNavFooter from "./imageGalleryNavFooter";
import useLunaLensStore from "store/lunaLensAnalysis";

const useStyles = makeStyles((theme) => ({
  carouselContainer: {
    display: "flex",
    flexDirection: "column",
    height: "90%",
    position: "relative",
    width: "100%",
  },
  carouselWrapper: {
    flex: 1,
    overflow: "hidden",
  },
  navFooter: {
    flexShrink: 0,
  },
}));

const ImageGallery = ({
  imageUrls,
  numberImages,
  viewOnly,
  category,
  verification,
  title,
  handleOpenImage,
  reportType,
}) => {
  const classes = useStyles();
  const [currentPage, setCurrentPage] = useState(0);
  const isMobile = window.innerWidth <= 600;
  const imagesPerPage = isMobile ? numberImages - 7 : numberImages;
  const totalPages = Math.ceil(imageUrls?.length / imagesPerPage);
  const totalImages = imageUrls?.length;
  const showNavFooter = totalPages > 1;
  const [nextEnabled, setNextEnabled] = useState(false);

  const mediaCategory =
    category === "service_verification"
      ? `${category}_${verification}`
      : category;

  const selectedMedia = useLunaLensStore((state) => state.selectedMedia);
  const globalSelectedMediaCount = useLunaLensStore(
    (state) => state.globalSelectedMediaCount
  );
  const setGlobalSelectedMediaCount = useLunaLensStore(
    (state) => state.setGlobalSelectedMediaCount
  );
  const setSelectedMedia = useLunaLensStore((state) => state.setSelectedMedia);
  const setCategorySelected = useLunaLensStore(
    (state) => state.setCategorySelected
  );
  const setMediaLimitation = useLunaLensStore(
    (state) => state.setMediaLimitation
  );

  const getPageImages = (page) => {
    const startIndex = page * imagesPerPage;
    return imageUrls.slice(startIndex, startIndex + imagesPerPage);
  };

  const handleNext = () => {
    setNextEnabled(true);
    setCurrentPage((prevPage) => (prevPage + 1) % totalPages);
    setTimeout(() => {
      setNextEnabled(false);
    }, 500);
  };

  const handlePrev = () => {
    setNextEnabled(true);
    setCurrentPage((prevPage) => (prevPage - 1 + totalPages) % totalPages);
    setTimeout(() => {
      setNextEnabled(false);
    }, 500);
  };

  const handleImageClick = (index) => {
    if (category !== "service_verification") {
      const selected = selectedMedia[category].includes(index);
      if (selected) {
        setGlobalSelectedMediaCount(
          category,
          globalSelectedMediaCount[category] - 1
        );
      } else {
        if (globalSelectedMediaCount[category] === 25) {
          setMediaLimitation(true);
          setTimeout(() => {
            setMediaLimitation(false);
          }, 3000);
          return;
        }
        setGlobalSelectedMediaCount(
          category,
          globalSelectedMediaCount[category] + 1
        );
      }
      setSelectedMedia(index, category);
      setCategorySelected(category, selectedMedia[category].length);
    } else {
      const selected =
        selectedMedia[`${category}_${verification}`].includes(index);
      if (selected) {
        setGlobalSelectedMediaCount(
          category,
          globalSelectedMediaCount[category] - 1
        );
      } else {
        if (globalSelectedMediaCount[category] === 50) {
          setMediaLimitation(true);
          setTimeout(() => {
            setMediaLimitation(false);
          }, 3000);
          return;
        }
        setGlobalSelectedMediaCount(
          category,
          globalSelectedMediaCount[category] + 1
        );
      }
      setSelectedMedia(index, `${category}_${verification}`);
      setCategorySelected(
        category,
        selectedMedia[`${category}_before`].length +
          selectedMedia[`${category}_after`].length
      );
    }
  };

  return (
    <Box
      className={classes.carouselContainer}
      style={
        imageUrls?.length > 28 ? { minHeight: "405px" } : { minHeight: "0px" }
      }
    >
      <Box className={classes.carouselWrapper}>
        <Carousel
          animation="slide"
          autoPlay={false}
          navButtonsAlwaysInvisible
          index={currentPage}
          onChange={(index) => setCurrentPage(index)}
          indicators={false}
          cycleNavigation={false}
        >
          {Array.from({ length: totalPages }).map((_, pageIndex) => (
            <ImageGrid
              key={pageIndex}
              pageIndex={pageIndex}
              images={getPageImages(pageIndex)}
              imagesPerPage={imagesPerPage}
              selectedImages={selectedMedia[mediaCategory]}
              handleImageClick={handleImageClick}
              viewOnly={viewOnly}
              title={title}
              handleOpenImage={handleOpenImage}
              reportType={reportType}
            />
          ))}
        </Carousel>
      </Box>
      {showNavFooter && (
        <Box className={classes.navFooter}>
          <ImageGallerNavFooter
            currentPage={currentPage}
            totalPages={totalPages}
            imagesPerPage={imagesPerPage}
            totalImages={totalImages}
            handlePrev={handlePrev}
            handleNext={handleNext}
            disabled={nextEnabled}
          />
        </Box>
      )}
    </Box>
  );
};

export default ImageGallery;
