import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  wrapperDialog: {
    textAlign: "center",
  },
  wrapperDialogClose: {
    position: "absolute",
    right: 5,
    top: 5,
  },
  dialogMainIcon: {
    marginTop: theme.spacing(4),
  },
  learnMoreTextUnderline: {
    textDecoration: "underline",
    color: "#4f98bc",
    cursor: "pointer",
  },
  iconDialog: {
    color: "#747474",
    width: 24,
    height: 24,
    marginBottom: 5,
  },
  iconSuccessDialog: {
    color: "#328914",
    width: 44,
    height: 44,
    marginBottom: 5,
  },
  iconsDialogClose: {
    color: "#979797",
    width: 20,
    height: 20,
  },
  titleDialogDelete: {
    fontSize: 16,
    fontWeight: "bold",
    marginTop: 10,
    marginBottom: 5,
  },
  titleDialog: {
    fontSize: 16,
    fontWeight: "bold",
    marginTop: 3,
    marginBottom: 5,
  },
  sectionDialog: {
    marginTop: 40,
    textAlign: "left",
    fontSize: 16,
    fontWeight: "bold",
    marginBottom: 12,
  },
  sectionDialogFeedback: {
    marginTop: 24,
    textAlign: "left",
    fontSize: 16,
    fontWeight: "bold",
    marginBottom: 12,
  },
  contentDialog: {
    fontSize: 14,
  },
  infoContainer: {
    marginTop: 12,
    display: "flex",
    flexDirection: "column",
    gap: 8,
  },
  contentDialogRating: {
    fontSize: 14,
    marginTop: 4,
    maxWidth: 418,
    marginLeft: 90,
    marginRight: 108,
    [theme.breakpoints.down("sm")]: {
      marginLeft: 16,
      marginRight: 16,
    },
  },
  contentDialogRatingWrapper: {
    display: "flex",
    justifyContent: "center",
  },
  contentDialogRatingWrapperDeleteRating: {
    marginBottom: 24,
    marginTop: 32,
    textAlign: "left",
    maxWidth: 664,
  },
  contentDialogRatingWrapperDeleteComment: {
    marginBottom: 24,
    marginTop: 32,
    textAlign: "left",
    width: "464px",
    maxWidth: 464,
  },
  ratingItemsScrollContainer: {
    height: 300,
    overflowY: "auto",
    overflowX: "hidden",
  },
  selectContainer: {
    marginTop: 20,
    width: 418,
  },
  ratingButtonsContainer: {
    marginTop: 40,
    display: "flex",
    justifyContent: "center",
    gap: 24,
  },
  didNotMeetStandardButton: {
    color: "#747474",
    fontWeight: 600,
    backgroundColor: "#ffffff",
    padding: "6px 14px",
    border: "1px solid #D9D9D9",
    borderRadius: 3,
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#747474",
      color: "#ffffff",
      transition: "0.3s",
    },
  },
  didNotMeetStandardButtonActive: {
    backgroundColor: "#747474",
    fontWeight: 600,
    color: "#ffffff",
    padding: "6px 14px",
    border: "1px solid #D9D9D9",
    borderRadius: 3,
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#747474",
      color: "#ffffff",
      transition: "0.3s",
    },
  },
  metStandardButton: {
    color: "#9CBB65",
    fontWeight: 600,
    backgroundColor: "#ffffff",
    padding: "6px 14px",
    border: "1px solid #9CBB65",
    borderRadius: 3,
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#9CBB65",
      color: "#ffffff",
      transition: "0.3s",
    },
  },
  metStandardButtonActive: {
    backgroundColor: "#9CBB65",
    fontWeight: 600,
    color: "#ffffff",
    padding: "6px 14px",
    border: "1px solid #9CBB65",
    borderRadius: 3,
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#9CBB65",
      color: "#ffffff",
      transition: "0.3s",
    },
  },
  exceededStandardButton: {
    color: "#328914",
    fontWeight: 600,
    backgroundColor: "#ffffff",
    padding: "6px 14px",
    border: "1px solid #328914",
    borderRadius: 3,
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#328914",
      color: "#ffffff",
      transition: "0.3s",
    },
  },
  exceededStandardButtonActive: {
    backgroundColor: "#328914",
    fontWeight: 600,
    color: "#ffffff",
    padding: "6px 14px",
    border: "1px solid #328914",
    borderRadius: 3,
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#328914",
      color: "#ffffff",
      transition: "0.3s",
    },
  },
  wrapperDialogAction: {
    textAlign: "center",
    justifyContent: "center !important",
    marginBottom: 20,
  },
  wrapperDialogDelete: {
    justifyContent: "center !important",
    marginBottom: 20,
  },
  wrapperDialogActionRating: {
    justifyContent: "end",
    marginTop: 8,
    marginBottom: 20,
    paddingRight: 16,
  },
  button: {
    textTransform: "Capitalize",
    paddingLeft: 50,
    paddingRight: 50,
    marginLeft: 10,
    fontWeight: "bold",
    backgroundColor: "#4f98bc",
    "&:hover": {
      backgroundColor: "#4f98bc",
    },
  },
  buttonRateServiceDelete: {
    textTransform: "Capitalize",
    marginLeft: 8,
    // fontWeight: "bold",
    width: 155,
    fontWeight: 600,
    backgroundColor: "#4f98bc",
    "&:hover": {
      backgroundColor: "#4f98bc",
    },
  },
  buttonRateService: {
    textTransform: "Capitalize",
    marginLeft: 8,
    width: 167,
    fontWeight: 600,
    backgroundColor: "#4f98bc",
    "&:hover": {
      backgroundColor: "#4f98bc",
    },
  },
  buttonOutlined: {
    textTransform: "Capitalize",
    paddingLeft: 50,
    paddingRight: 50,
    color: "#4f98bc",
    marginRight: 40,
  },
  buttonOutlinedBadgeDialog: {
    textTransform: "Capitalize",
    width: "80%",
    paddingLeft: 50,
    paddingRight: 50,
    color: "#4f98bc",
  },
  buttonOutlinedRating: {
    textTransform: "Capitalize",
    width: 115,
    paddingLeft: 50,
    paddingRight: 50,
    color: "#4f98bc",
    marginRight: 6,
  },
  formContainer: {
    paddingLeft: 50,
    paddingRight: 50,
    paddingTop: 20,
    paddingBottom: 20,
    textAlign: "left",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 20,
      paddingRight: 20,
    },
  },
  checkbox: {
    color: "#ececec",
  },
  labelCheckbox: {
    "& > span.MuiTypography-root": {
      color: "#4d4d4d",
      fontSize: 12,
    },
  },
  alertContainer: {
    border: "none",
    marginBottom: theme.spacing(1),
  },
  titleWarningDialog: {
    fontSize: 16,
  },
  dialogBodyContainer: {
    marginTop: 8,
    "& p": {
      fontSize: 14,
      marginBottom: 4,
    },
  },
  dialogListContainer: {
    marginTop: 8,
    marginBottom: 8,
    "& p": {
      fontWeight: "bold",
    },
  },
  headerContainer: {
    display: "flex",
    flexDirection: "row",
    gap: 8,
    justifyContent: "center",
    alignItems: "center",
  },
  questionList: {
    marginLeft: 45,
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
    },
  },
  listMinHeight: {
    paddingTop: 20,
    paddingBottom: 20,
    paddingRight: 0,
    paddingLeft: 0,
  },
  alignRating: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  ratingBox: {
    background: "#FFFFFF",
    border: "1px solid #4F98BC",
    borderRadius: 3,
    width: 35,
  },
  ratingFont: {
    fontWeight: "bold",
    fontSize: 18,
    lineHeight: "35px",
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    color: "#3996C0",
    cursor: "pointer",
    justifyContent: "center",
  },
  ratingFontSelected: {
    backgroundColor: "#4F98BC",
    color: "#ffffff",
  },
  ratingFontSelectedActive: {
    animation: "$selectedNumberAnswer 1s",
  },
  ratingTextContainer: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: 10,
  },
  ratingTextBelow: {
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: 14,
    lineHeight: "19px",
    color: "#4F98BC",
  },
  "@keyframes selectedAnswer": {
    "0%": {
      opacity: 1,
    },
    "20%": {
      opacity: 0.5,
    },
    "40%": {
      opacity: 1,
    },
    "60%": {
      opacity: 0.5,
    },
    "80%": {
      opacity: 1,
    },
    "100%": {
      opacity: 0.5,
    },
  },
  "@keyframes selectedNumberAnswer": {
    "0%": {
      opacity: 1,
    },
    "20%": {
      opacity: 0.5,
    },
    "40%": {
      opacity: 1,
    },
    "60%": {
      opacity: 0.5,
    },
    "80%": {
      opacity: 1,
    },
    "100%": {
      opacity: 0.5,
    },
  },
}));

export default useStyles;
