import React from "react";
const Context = React.createContext(null);

export const Provider = Context.Provider;
export const Consumer = Context.Consumer;

const WorkticketActionContext = React.createContext();

const initialState = {
  openSchedule: false,
  openScheduleSingle: false,
  openDueDate: false,
  openDueDateSingle: false,
  openDone: false,
  openArchive: false,
  openAssign: false,
  openAssignPartner: false,
  openAssignMultiple: false,
  openDuplicate: false,
  openDelete: false,
  openFollow: false,
  openUnFollow: false,
  openRate: false,
  openChangeAP: false,
  openChangeAR: false,
  openDecline: false,
  openComment: false,
  openError: false,
  errorMessage: "",
  openSVConfirm: false,
  openVerifyAgain: false,
  openVerifyAgainBulk: false,
  openConfirmBulk: false,
};

export const reducer = (state, action) => {
  switch (action.type) {
    case "TOGGLE_SCHEDULE":
      return {
        ...state,
        openSchedule: action.open,
      };
    case "TOGGLE_SCHEDULE_SINGLE":
      return {
        ...state,
        openScheduleSingle: action.open,
      };
    case "TOGGLE_DUEDATE":
      return {
        ...state,
        openDueDate: action.open,
      };
    case "TOGGLE_DUEDATE_SINGLE":
      return {
        ...state,
        openDueDateSingle: action.open,
      };
    case "TOGGLE_RATE_SINGLE":
      return {
        ...state,
        openRate: action.open,
      };
    case "TOGGLE_DONE":
      return {
        ...state,
        openDone: action.open,
      };
    case "TOGGLE_ARCHIVE":
      return {
        ...state,
        openArchive: action.open,
      };
    case "TOGGLE_ASSIGN":
      return {
        ...state,
        openAssign: action.open,
      };
    case "TOGGLE_ASSIGN_PARTNER":
      return {
        ...state,
        openAssignPartner: action.open,
      };
    case "TOGGLE_CHANGE_AP":
      return {
        ...state,
        openChangeAP: action.open,
      };
    case "TOGGLE_CHANGE_AR":
      return {
        ...state,
        openChangeAR: action.open,
      };
    case "TOGGLE_DECLINE":
      return {
        ...state,
        openDecline: action.open,
      };
    case "TOGGLE_COMMENT":
      return {
        ...state,
        openComment: action.open,
      };
    case "TOGGLE_ASSIGN_MULTIPLE":
      return {
        ...state,
        openAssignMultiple: action.open,
      };
    case "TOGGLE_DUPLICATE":
      return {
        ...state,
        openDuplicate: action.open,
      };
    case "TOGGLE_DELETE":
      return {
        ...state,
        openDelete: action.open,
      };
    case "TOGGLE_FOLLOW":
      return {
        ...state,
        openFollow: action.open,
      };
    case "TOGGLE_UNFOLLOW":
      return {
        ...state,
        openUnFollow: action.open,
      };
    case "TOGGLE_ERROR":
      return {
        ...state,
        openError: action.open,
      };
    case "SET_ERROR_MESSAGE":
      return {
        ...state,
        errorMessage: action.message,
      };
    case "TOGGLE_SV_CONFIRM":
      return {
        ...state,
        openSVConfirm: action.open,
      };
    case "TOGGLE_VERIFY_AGAIN":
      return {
        ...state,
        openVerifyAgain: action.open,
      };
    case "TOGGLE_VERIFY_AGAIN_BULK":
      return {
        ...state,
        openVerifyAgainBulk: action.open,
      };
    case "TOGGLE_CONFIRM_BULK":
      return {
        ...state,
        openConfirmBulk: action.open,
      };
    default:
      return initialState;
  }
};

const WorkticketActionProvider = ({ children }) => (
  <WorkticketActionContext.Provider
    value={React.useReducer(reducer, initialState)}
  >
    {children}
  </WorkticketActionContext.Provider>
);

function useWorkticketAction() {
  const context = React.useContext(WorkticketActionContext);
  if (context === undefined) {
    throw new Error(
      "useWorkticketAction must be used within a WorkticketActionProvider"
    );
  }
  return context;
}

export { WorkticketActionProvider, useWorkticketAction };
