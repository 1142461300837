// Permissions uid
export const permissionProject = {
  DETAIL: 32,
  LIST: 35,
  CREATE: 36,
  EDIT: 37,
  DELETE: 38,
  MODULE: 96,
};

export const permissionWorkticket = {
  DETAIL: 1,
  LIST: 4,
  GEO_ASSIGNED: 5,
  GEO_EXCLUSIVE: 6,
  GEO_INCLUSIVE: 7,
  CREATE: 8,
  CLONE: 9,
  STATUS_UPDATE: 10,
  MARK_DONE: 11,
  EDIT: 12,
  DELETE: 13,
  UPLOAD_FILES: 14,
  SIGNATURE: 15,
  TASKS: 16,
  CLOCK: 17,
  MANUAL_TIMEKEEPING: 18,
  MANAGE_TIMEKEEPING: 19,
  DELETE_TIMEKEEPING: 20,
  VIEW_TIMEKEEPING: 21,
  EXPORT_TIMEKEEPING: 22,
  GENERATORS: 23,
  UNASSIGNED: 87,
  ASSIGN_SCHEDULE: 92,
  MODULE: 98,
  TAB_NOT_ASSIGNED: 106,
  TAB_NOT_SCHEDULED: 107,
  TAB_CANCELLED: 108,
  TAB_ARCHIVED: 109,
  SET_COMPLETED_DATE: 112,
  TAB_CORRECTIVE_ACTION: 113,
  VIEW_AP: 119,
  BACK_DATE: 120,
  WORKORDERS_NOVA_ADMIN: 139,
  EXPORT_LIST: 140,
  CONVERT_TO_PROJECT: 145,
  WORKTICKET_WOID: 153,
  VIEW_AR: 163,
  WALGREENS_FILTERS: 200,
  WALGREENS_DATA: 201,
  WALGREENS_STATUS_CHANGE: 202,
  WALGREENS_NTE: 203,
  WORKTICKETS_PREVIEW: 205,
  VIEW_ONLY_AP: 212,
  KPI: 166,
  FINANCIAL_AR: 221,
  FINANCIAL_AP: 222,
  RATE: 226,
  EDIT_RATING: 227,
  MANUAL_RATE_TIMEKEEPING: 233,
  UPDATE_CANCELLATION_STATUS: 237,
  COMMENTS_INTERNAL: 238,
  COMMENTS_PARTNER: 239,
  COMMENTS_CUSTOMER: 240,
  REQUESTS: 242,
  REQUESTS_INVOICE: 243,
  REQUESTS_QUOTE: 244,
  REQUESTS_SOURCE: 245,
  REQUESTS_VIEW_LINK: 246,
  LUNA_LENS_VIEW: 253,
  LUNA_LENS_MANAGE: 254,
  SERVICE_VERIFICATION_MANAGE: 310,
};

export const permissionQuote = {
  DETAIL: 24,
  LIST: 27,
  CREATE: 28,
  EDIT: 29,
  APPROVAL: 30,
  DELETE: 31,
  DUE_DATE: 83,
  MODULE: 97,
  VIEW_PROJECT: 32,
  RECURRENCE: 117,
};

export const permissionAsset = {
  DETAIL: 40,
  LIST: 41,
  CREATE: 42,
  EDIT: 43,
  DELETE: 44,
  UPLOAD_FILES: 45,
  MODULE: 95,
  MANAGE_CATEGORIES: 111,
};

export const permissionRequest = {
  DETAIL: 123,
  LIST: 122,
  MODULE: 124,
  CHANGE_STATUS: 125,
  UPLOAD_FILE: 126,
  APPROVE_QT_WT: 127,
  CHANGE_RESOLVE: 128,
};

export const permissionFinancial = {
  AP_EXPORT: 46,
  AP_LIST: 47,
  AP_LIST_OPS: 48,
  AP_LIST_SUB: 49,
  AP_DETAIL: 50,
  AP_DETAIL_OPS: 51,
  AP_DETAIL_SUB: 52,
  AR_EXPORT: 53,
  AR_LIST: 54,
  AR_LIST_OPS: 55,
  AR_DETAIL: 56,
  AR_DETAIL_OPS: 57,
  AP_PARTIAL: 58,
  AR_PARTIAL: 59,
  AP_AR_HISTORY: 60,
  AP_AR_DELETE: 61,
  AP_LIST_SUPPLIES: 213,
  MODULE: 93,
  AP_WORKTICKET_DISCOUNTS: 255,
};

export const permissionDashboard = {
  MAP: 141,
  NOTES: 143,
  KPI: 146,
  PEOPLE: 148,
  LABOR_REPORT: 199,
  KPI_PARTNER: 223,
  KPI_FINANCIAL_PORTFOLIO: 224,
  KPI_FINANCIAL_CAMPUS: 225,
  LEADERBOARD: 232,
  WT_DB_NUMBERS: 234,
  WT_DB_DOLLARS: 235,
  WT_DB_LIQUIDITY: 236,
  LOCATION_MANAGE: 400,
  KPI_INSPECTIONS: 307,
};

export const permissionAdmin = {
  USER_LIST: 62,
  USER_CREATE: 63,
  USER_DETAIL: 64,
  USER_EDIT: 65,
  USER_DELETE: 66,
  CUSTOMER_LIST: 67,
  CUSTOMER_CREATE: 68,
  CUSTOMER_DETAIL: 69,
  CUSTOMER_EDIT: 70,
  CUSTOMER_DELETE: 71,
  JOB_LIST: 72,
  JOB_DETAIL: 73,
  JOB_EDIT: 74,
  ROLE_LIST: 75,
  ROLE_CREATE: 76,
  ROLE_DETAIL: 77,
  ROLE_EDIT: 78,
  ROLE_DELETE: 79,
  COMMENTS_MANAGE: 91,
  MODULE: 94,
};

export const permissionLegacy = {
  QUALITY: 88,
  TEAM: 89,
  TOOLS: 90,
  WALGREENS: 102,
  AP_SUPPLIES: 103,
  HOURLY_BILLING: 104,
  TUYO_BILLING: 105,
  TRAINING: 114,
  REPORTS: 116,
};

export const permissionSurface = {
  REQUEST: 121,
  QUOTE: 129,
  WORKTICKET: 130,
  QUALITY: 131,
  MAP: 142,
  CREATE_WORKTICKET: 138,
  NOTES: 144,
  KPI: 147,
  KPI_QUALITY: 216,
  PEOPLE: 149,
  WORKTICKET_WOID: 161,
  ASSETS: 162,
  SURVEYS_TAB_LIST_SURFACE: 195,
  RATE: 228,
  EDIT_RATING: 229,
};

export const permissionBudget = {
  MODULE: 132,
  LIST_CAMPUS: 133,
  LIST_PORTFOLIO: 134,
  UPLOAD_BUDGET: 135,
};

export const permissionWorknova = {
  EMERGENCY_TICKET: 136,
  EXTERNAL_WOID: 137,
  UPLOAD_WO: 139,
  EXPORT_WT: 140,
};

export const permissionEmergencyTicket = {
  CREATE: 136,
};

export const permissionOpenPositions = {
  LIST_AND_VIEW: 150,
  UPDATE: 151,
  DELETE: 152,
};

export const permissionTalent = {
  ANNOUNCEMENTS: 154,
  KPI: 155,
  KPI_LABOR: 214,
  EVALUATE: 178,
  DELETE_EVALUATION: 180,
  NOTICE: 181,
  EVALUATION_LIST_SUBMITTED: 185,
  EVALUATION_LIST_TIERED: 186,
  EVALUATION_LIST_TEAM: 187,
  DELETE_NOTICE: 188,
  DELETE_RECOGNITION: 189,
  VIEW_MY_EVALUATIONS: 190,
  VIEW_DEPARTMENT: 191,
  INDIVIDUAL_VEW: 198,
  ORG_CHART: 196,
  ORG_CHART_OP: 204,
};

export const permissionForms = {
  FORMS_MODULE: 156,
  LIST_VIEW_FOR_FORMS_CREATED: 157,
  LIST_VIEW_FOR_FORMS_TIERED: 158,
  LIST_VIEW_FOR_FORMS_TEAM: 159,
  DELETE_FORMS: 160,
};

export const permissionQuality = {
  MANAGE: 164,
  VIEW: 165,
  LIST_SUBMITTED_CREATED: 182,
  LIST_SUBMITTED_EXCLUSIVE: 183,
  LIST_SUBMITTED_INCLUSIVE: 184,
  SURVEYS_TAB_LIST: 194,
  KPI: 215,
  KPI_CSAT: 230,
  KPI_RATING: 231,
};

export const permissionCustomer = {
  CUSTOMERS_MODULE: 168,
  VIEW: 169,
  CREATE_EDIT: 170,
  JOB_MANAGE: 171,
  NOTE_MANAGE: 172,
  VIEW_JOB: 173,
  EDIT_JOB: 174,
  VIEW_CONTACT: 175,
  CREATE_EDIT_CONTACT: 176,
  DELETE_CONTACT: 177,
  VIEW_BUDGET: 217,
  ADMIN_BUDGET: 218,
  APPROVE_BUDGET: 219,
  SYNCH_BUDGET: 220,
};

export const permissionLearn = {
  VIEW: 192,
  ADMIN: 193,
};

export const permissionPartner = {
  VIEW: 247,
  PARTNER_INACTIVATE: 249,
  PARTNER_CREATE: 248,
  PARTNER_DELETE: 250,
  PARTNER_MANAGE: 251,
  PARTNER_OWNER: 252,
};

export const permissionVerificationStandard = {
  VIEW: 312,
  STANDARD_MANAGE: 311,
};
export const hasPermission = (permission, permissionList) => {
  const find = permissionList.find(
    (perm) => Number(perm.uid) === Number(permission)
  );
  return find ? true : false;
};
