import { Button } from "@material-ui/core";
import { AttachFileIcon } from "./icons/AttachFileIcon";
import React from "react";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

export function SuggestedPrompts({ classes }) {
  return (
    <>
      <Box className={classes.suggestedPromptMainContainer}>
        <Typography className={classes.suggestedPromptTitle}>
          Suggested Prompts
        </Typography>
        <Button
          startIcon={<AttachFileIcon />}
          align="left"
          className={classes.buttonSuggestedPromptLunaLens}
          // onClick={() => {
          //   // setCategorySelected(!categorySelected);
          //   // handleCategoryContext(categoryValue);
          // }}
        >
          Upload additional media in order to re-run the analysis.
        </Button>
      </Box>
    </>
  );
}
