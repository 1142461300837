import React, { useState, useContext } from "react";
import PropTypes from "prop-types";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";
import ArrowDropDownIcon from "@material-ui/icons/KeyboardArrowDown";
import { permissionPartner, hasPermission } from "lib/permissions";
import { fileMediaTimeType } from "constants.js";
import useStyles from "./styles";

const CustomArrowDropDownIcon = ({ onClick }) => (
  <ArrowDropDownIcon
    style={{ fontSize: 18, marginRight: 8, cursor: "pointer" }}
    onClick={onClick}
  />
);

const statusColors = {
  1: {
    background: "#FFFFFF",
    textColor: "#4F98BC",
  },
  2: {
    background: "#FFFFFF",
    textColor: "#4F98BC",
  },
};

const FileMediaTimeType = ({ fileSelected, onChange, readOnly = false }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const statusId = parseInt(fileSelected.metadata.before_after_tag, 10);

  const currentStatusColor = statusColors[statusId] || {};

  const handleChange = (event) => {
    const newStatusId = event.target.value;
    console.log(fileSelected);
    onChange(fileSelected, newStatusId);
    setOpen(false);
  };

  const handleIconClick = (event) => {
    event.stopPropagation();
    setOpen((prevOpen) => !prevOpen);
  };

  return (
    <Select
      fullWidth
      disableUnderline
      value={statusId}
      open={open}
      disabled={readOnly}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      onClick={(e) => e.stopPropagation()}
      onChange={handleChange}
      IconComponent={() => (
        <CustomArrowDropDownIcon
          onClick={handleIconClick}
          style={{
            color: currentStatusColor.textColor,
          }}
        />
      )}
      style={{
        backgroundColor: currentStatusColor.background,
        color: currentStatusColor.textColor,
        border: "1px solid #D9D9D9",
      }}
      className={classes.selectStatus}
      renderValue={(selected) => {
        const selectedStatus = fileMediaTimeType.find(
          (status) => status.value === parseInt(selected, 10)
        );
        return selectedStatus ? (
          <Typography variant="caption">{selectedStatus.label}</Typography>
        ) : null;
      }}
    >
      {fileMediaTimeType.map((option) => (
        <MenuItem
          key={option.value}
          value={option.value}
          style={{ fontSize: 14 }}
        >
          {option.label}
        </MenuItem>
      ))}
    </Select>
  );
};

FileMediaTimeType.propTypes = {
  onChange: PropTypes.func.isRequired,
  partnerSelected: PropTypes.object.isRequired,
};

export default FileMediaTimeType;
