import React from "react";

const FolderIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="108"
    height="116"
    viewBox="0 0 108 116"
    fill="none"
  >
    <g clipPath="url(#clip0_1150_13417)">
      <path
        d="M11.2161 107.909C11.2161 107.785 11.3189 107.683 11.4422 107.683H14.7607C14.884 107.683 14.9867 107.785 14.9867 107.909V112.779C14.9867 112.902 14.884 113.005 14.7607 113.005H11.4422C11.3189 113.005 11.2161 112.902 11.2161 112.779V107.909Z"
        fill="#D6ECF0"
      />
      <path
        d="M17.3857 107.909C17.3857 107.785 17.4885 107.683 17.6118 107.683H20.9303C21.0536 107.683 21.1563 107.785 21.1563 107.909V112.779C21.1563 112.902 21.0536 113.005 20.9303 113.005H17.6118C17.4885 113.005 17.3857 112.902 17.3857 112.779V107.909Z"
        fill="#D6ECF0"
      />
      <path
        d="M23.5502 107.909C23.5502 107.785 23.653 107.683 23.7763 107.683H27.0948C27.2181 107.683 27.3208 107.785 27.3208 107.909V112.779C27.3208 112.902 27.2181 113.005 27.0948 113.005H23.7763C23.653 113.005 23.5502 112.902 23.5502 112.779V107.909Z"
        fill="#D6ECF0"
      />
      <path
        d="M29.7198 107.909C29.7198 107.785 29.8226 107.683 29.9459 107.683H33.2644C33.3877 107.683 33.4905 107.785 33.4905 107.909V112.779C33.4905 112.902 33.3877 113.005 33.2644 113.005H29.9459C29.8226 113.005 29.7198 112.902 29.7198 112.779V107.909Z"
        fill="#D6ECF0"
      />
      <path
        d="M35.8843 107.909C35.8843 107.785 35.9871 107.683 36.1104 107.683H39.4289C39.5522 107.683 39.6549 107.785 39.6549 107.909V112.779C39.6549 112.902 39.5522 113.005 39.4289 113.005H36.1104C35.9871 113.005 35.8843 112.902 35.8843 112.779V107.909Z"
        fill="#D6ECF0"
      />
      <path
        d="M42.0539 107.909C42.0539 107.785 42.1566 107.683 42.2799 107.683H45.5985C45.7218 107.683 45.8245 107.785 45.8245 107.909V112.779C45.8245 112.902 45.7218 113.005 45.5985 113.005H42.2799C42.1566 113.005 42.0539 112.902 42.0539 112.779V107.909Z"
        fill="#D6ECF0"
      />
      <path
        d="M48.2184 107.909C48.2184 107.785 48.3211 107.683 48.4444 107.683H51.763C51.8862 107.683 51.989 107.785 51.989 107.909V112.779C51.989 112.902 51.8862 113.005 51.763 113.005H48.4444C48.3211 113.005 48.2184 112.902 48.2184 112.779V107.909Z"
        fill="#D6ECF0"
      />
      <path
        d="M54.3879 107.909C54.3879 107.785 54.4907 107.683 54.614 107.683H57.9325C58.0558 107.683 58.1585 107.785 58.1585 107.909V112.779C58.1585 112.902 58.0558 113.005 57.9325 113.005H54.614C54.4907 113.005 54.3879 112.902 54.3879 112.779V107.909Z"
        fill="#D6ECF0"
      />
      <path
        d="M60.5525 107.909C60.5525 107.785 60.6552 107.683 60.7785 107.683H64.0971C64.2204 107.683 64.3231 107.785 64.3231 107.909V112.779C64.3231 112.902 64.2204 113.005 64.0971 113.005H60.7785C60.6552 113.005 60.5525 112.902 60.5525 112.779V107.909Z"
        fill="#D6ECF0"
      />
      <path
        d="M6.79829 110.097C6.79829 107.39 8.99181 105.197 11.699 105.197H96.1164C98.8236 105.197 101.017 107.39 101.017 110.097V110.591C101.017 113.298 98.8236 115.491 96.1164 115.491H11.6939C8.98668 115.491 6.79315 113.298 6.79315 110.591V110.097H6.79829Z"
        stroke="#4F98BC"
        strokeWidth="1.02741"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M85.1025 62.5746H36.0076V0.513794H76.575L85.1025 7.70054V62.5746Z"
        fill="white"
        stroke="#4F98BC"
        strokeWidth="1.02741"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M76.575 0.513916L76.7908 9.48836L85.1025 7.70067"
        stroke="#4F98BC"
        strokeWidth="1.02741"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M42.958 16.9833H78.1674"
        stroke="#4F98BC"
        strokeWidth="1.02741"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M42.958 23.6614H78.1674"
        stroke="#4F98BC"
        strokeWidth="1.02741"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M42.958 29.7799H78.1674"
        stroke="#4F98BC"
        strokeWidth="1.02741"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M107.176 40.0177L101.731 95.7959L88.606 37.6444L105.538 37.3721C106.606 37.7266 107.295 38.8311 107.176 40.0126V40.0177Z"
        fill="#4F98BC"
      />
      <path
        d="M101.659 94.5269L96.9742 35.9849C96.8407 34.3051 95.4383 33.0157 93.7584 33.0157H35.1343V30.5191C35.1343 29.5019 34.3072 28.6749 33.2901 28.6749H10.5791C9.56199 28.6749 8.73492 29.5019 8.73492 30.5191V33.0157H3.68005C1.79475 33.0157 0.310137 34.6236 0.464249 36.4986L5.14925 95.0406C5.28281 96.7153 6.68523 98.0098 8.36505 98.0098H98.4434C100.329 98.0098 101.808 96.4019 101.659 94.5269Z"
        fill="#D6ECF0"
      />
    </g>
    <defs>
      <clipPath id="clip0_1150_13417">
        <rect
          width="106.733"
          height="116"
          fill="white"
          transform="translate(0.453979)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default FolderIcon;
