import React, { useMemo } from "react";
import { Link } from "react-router-dom";
import Skeleton from "@material-ui/lab/Skeleton";
import PageHeader from "components/common/PageHeader/pageHeader";
import { useVerificationStandardView } from "contexts/verificationStandardViewContext";

const MODE_TITLES = {
  new: "New Standard",
  view: "View Standard",
  edit: "Edit Standard",
  duplicate: "Duplicate Standard",
};

const StandardBreadcrumb = ({ mode = "new" }) => {
  const [stateContext] = useVerificationStandardView();
  const { standardDefinition, isLoading } = stateContext ?? {};

  const pageTitle = useMemo(() => {
    return standardDefinition?.id ? standardDefinition.name : MODE_TITLES[mode];
    //eslint-disable-next-line
  }, [standardDefinition?.id, standardDefinition?.name]);

  if (isLoading && standardDefinition?.id !== null) {
    return (
      <Skeleton
        animation="wave"
        variant="rect"
        height={80}
        style={{ marginBottom: 20 }}
      />
    );
  }

  return (
    <PageHeader pageTitle={pageTitle}>
      <Link to="/verification-standards">verification-standards</Link>
    </PageHeader>
  );
};

export default StandardBreadcrumb;
