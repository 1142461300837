import React from "react";
import Box from "@material-ui/core/Box";
import TimelineIcon from "@material-ui/icons/Timeline";
import useStyles from "./styles";

const StandardActivityList = ({ activities }) => {
  const classes = useStyles();

  // Función para formatear la fecha
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });
  };

  return (
    <Box>
      <button className={classes.activityButton}>
        <TimelineIcon /> Activity ({activities.length})
      </button>

      <Box className={classes.activityCardList}>
        {activities.map((activity) => (
          <Box key={activity.id} className={classes.activityCard}>
            <Box className={classes.activityCardContent}>
              <TimelineIcon />
              <Box className={classes.activityDescription}>
                {activity.historic_activity.activity_message}
              </Box>
            </Box>
            <Box className={classes.activityDate}>
              {formatDate(activity.historic_activity.created_at)}
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default StandardActivityList;
