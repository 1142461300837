import React from "react";
import clsx from "clsx";
import Toolbar from "@material-ui/core/Toolbar";
import useStyles from "./styles";
import { useTableState } from "../../../contexts/tableContext";
import MaintenanceAlert from "components/common/maintenanceAlert";

const EnhancedTableToolbar = ({ children, paddingBottom }) => {
  const classes = useStyles();
  const { selected } = useTableState();

  const toolbarStyle = {};

  return (
    <>
      <MaintenanceAlert />
      <Toolbar
        className={clsx(classes.toolBar, {
          [classes.highlight]: selected.length > 0,
        })}
        style={toolbarStyle}
      >
        {children}
      </Toolbar>
    </>
  );
};

export default EnhancedTableToolbar;
