import Box from "@material-ui/core/Box";
import { LunaSVIcon } from "./icon/LunaSVIcon";
import Typography from "@material-ui/core/Typography";
import ThreeDots from "../partnerPage/icons/ThreeDots";
import { ThumbsUpSV } from "./icon/ThumbsUpSV";
import React, { useState } from "react";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import EditIcon from "@material-ui/icons/Edit";
import { ThumbsDownSV } from "./icon/thumbsDownSV";
import { TwoThumbsUpSV } from "./icon/twoThumbsUpSV";
import DeleteIcon from "@material-ui/icons/Delete";
import { useDialogSV } from "./WorkticketSVRatingDialogContext";
import { dateFormatField } from "../../components/util/timeFormat";

const ratingData = {
  0: {
    ratingText: "Service did not meet the expected standard",
    colorThumbs: "#747474",
    colorThumbsInactive: "#D9D9D9",
  },
  1: {
    ratingText: "Service met the expected standard",
    colorThumbsInactive: "#D9D9D9",
    colorThumbs: "#9CBB65",
  },
  2: {
    ratingText: "Service exceeded the expected standard",
    colorThumbsInactive: "#D9D9D9",
    colorThumbs: "#328914",
  },
};
export function WorkticketSVRatingItem({
  classes,
  ratingItem,
  index,
  modifyPerms,
  deletePerms,
}) {
  const showMenuModify = modifyPerms && ratingItem.active === "1";
  const showDelete = deletePerms && ratingItem.active !== "1";
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const handleClick = (event) => {
    setMenuAnchorEl(event.currentTarget);
  };
  const [dialogSVAction, dispatchDialogSVAction] = useDialogSV();
  const [hoveredIndex, setHoveredIndex] = useState(null);
  let menuItems = [
    {
      icon: <EditIcon fontSize="inherit" />,
      text: "Modify Rating",
      onClick: () => {
        handleClose();
        dispatchDialogSVAction({
          type: "TOGGLE_MODIFY_RATING",
          open: true,
        });
      },
      iconClassName: classes.menuIconEdit,
      iconHoveredClassName: classes.menuIconEditHovered,
      hide: index !== 0,
    },
    {
      icon: <DeleteIcon fontSize="inherit" />,
      text: "Delete",
      onClick: () => {
        handleClose();
        dispatchDialogSVAction({
          type: "TOGGLE_DELETE_USER_DIALOG",
          open: true,
          ratingToDelete: ratingItem.id,
        });
      },
      iconClassName: classes.menuIconDelete,
      iconHoveredClassName: classes.menuIconDeleteHover,
      hide: index === 0,
    },
  ];
  const handleClose = () => {
    setMenuAnchorEl(null);
  };
  let ratingText = ratingData[ratingItem?.rating_value]?.ratingText;
  let colorThumbs = ratingData[ratingItem?.rating_value]?.colorThumbs;
  let colorThumbsInactive =
    ratingData[ratingItem?.rating_value]?.colorThumbsInactive;
  return (
    <>
      <Box
        className={
          index === 0
            ? classes.workticketCommentContainerActive
            : classes.workticketCommentContainer
        }
      >
        <Box className={classes.svLunaDateHeaderContainer}>
          <Box className={classes.svLunaIconNameContainer}>
            {ratingItem?.user?.profile?.picture?.file_url && (
              <img
                style={{ width: "24px", height: "24px", borderRadius: "50%" }}
                alt={"profile icon"}
                src={ratingItem?.user?.profile?.picture?.file_url}
              />
            )}
            <Typography className={classes.lunaName}>
              {ratingItem?.user?.first_name} {ratingItem?.user?.last_name}
            </Typography>
          </Box>
          <Box className={classes.svLunaDateContainer}>
            <Typography className={classes.lunaDate}>
              {dateFormatField(ratingItem.created_at)}
            </Typography>
            {(showMenuModify || showDelete) && (
              <Box
                onClick={handleClick}
                className={classes.threeDotsContainerComments}
              >
                <ThreeDots />
              </Box>
            )}
          </Box>
        </Box>
        <Box className={classes.thumbsUpDescriptionContainer}>
          {ratingItem.rating_value === "0" && (
            <ThumbsDownSV
              strokeColor={index === 0 ? colorThumbs : colorThumbsInactive}
            />
          )}
          {ratingItem.rating_value === "1" && (
            <ThumbsUpSV
              thumbsUpColor={index === 0 ? colorThumbs : colorThumbsInactive}
            />
          )}
          {ratingItem.rating_value === "2" && (
            <TwoThumbsUpSV
              strokeColor={index === 0 ? colorThumbs : colorThumbsInactive}
            />
          )}
          <Typography className={classes.descriptionSV}>
            {ratingText}
          </Typography>
        </Box>
        {ratingItem?.workticket_rating_reasons?.reason && (
          <Typography className={classes.svCommentTextModified}>
            <span style={{ fontWeight: "bold", marginRight: "4px" }}>
              Modification Reason:
            </span>
            {ratingItem.workticket_rating_reasons?.reason}
          </Typography>
        )}
        <Typography className={classes.svCommentText}>
          {ratingItem.feedback}
        </Typography>
      </Box>
      <Menu
        id="simple-menu"
        anchorEl={menuAnchorEl}
        keepMounted
        open={Boolean(menuAnchorEl)}
        onClose={handleClose}
        PaperProps={{
          style: {
            borderRadius: 8,

            boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)",
          },
        }}
      >
        {menuItems.map((item, index) => (
          <>
            {!item.hide && (
              <MenuItem
                key={index}
                onClick={item.onClick}
                onMouseEnter={() => setHoveredIndex(index)}
                onMouseLeave={() => setHoveredIndex(null)}
                className={classes.menuItem}
              >
                <Box
                  className={
                    hoveredIndex === index
                      ? item.iconHoveredClassName
                      : item.iconClassName
                  }
                >
                  {item.icon}
                </Box>
                <Typography variant="caption" className={classes.menuText}>
                  {item.text}
                </Typography>
              </MenuItem>
            )}
          </>
        ))}
      </Menu>
    </>
  );
}
