import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { VerifiedBadge } from "./icon/VerifiedBadge";
import useStyles from "./dialog/styles";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import React from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";
import { useVerifyRedirect } from "../../contexts/verifiedRedirectContext";

export function WTBadgeVerifiedDialog({
  title,
  message,
  onClose,
  open,
  certitiedDate,
}) {
  const classes = useStyles();
  const [verifyAction, dispatchVerifyAction] = useVerifyRedirect();
  const { workticketId, workticketNumber, analysisId } = verifyAction;
  return (
    <>
      <Dialog
        open={open}
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description"
        maxWidth={"xs"}
        fullWidth={true}
        PaperProps={{
          style: {
            backgroundColor: "white",
            boxShadow: "none",
          },
        }}
      >
        <DialogContent className={classes.wrapperDialog}>
          <IconButton
            aria-label="close"
            onClick={onClose}
            className={classes.wrapperDialogClose}
          >
            <CloseIcon className={classes.iconDialogClose} />
          </IconButton>
          <Box className={classes.dialogMainIcon}>
            <VerifiedBadge />
          </Box>
          <Typography variant="h6" gutterBottom className={classes.titleDialog}>
            {title}
          </Typography>
          <Box className={classes.infoContainer}>
            <Typography
              variant="body1"
              gutterBottom
              className={classes.contentDialog}
            >
              {message}{" "}
              <Link
                to={`/lens-analysis/${workticketId}/${workticketNumber}/${analysisId}/edit/none`}
                className={classes.learnMoreTextUnderline}
              >
                Learn more
              </Link>
            </Typography>
            <Typography
              variant="body1"
              gutterBottom
              className={classes.contentDialog}
            >
              {"Certified Date:"} {certitiedDate}
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions className={classes.wrapperDialogAction}>
          <Button
            onClick={onClose}
            className={classes.buttonOutlinedBadgeDialog}
            variant="outlined"
            size="large"
            color="primary"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
