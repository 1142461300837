import React from "react";
import LoadingStateHorizontal from "../LoadingStateHorizontal/LoadingStateHorizontal";

import useStyles from "./styles";

const LoadingIndicator = (props) => {
  const { backgroundColor } = props;
  const classes = useStyles();

  return (
    <div
      className={classes.loading}
      style={backgroundColor ? { background: backgroundColor } : {}}
    >
      <LoadingStateHorizontal isVisible />
    </div>
  );
};

export default LoadingIndicator;
