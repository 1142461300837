import http from "./httpService";
import { apiUrl } from "lib/config";
import { getFilterStringVerificationStandard } from "components/util/filterUtil";
import { getFilterString } from "components/util/filterUtil";
import { verificationTabOptions } from "constants.js";

const apiEndpoint = `${apiUrl}/verfication-standards`;
const apiEndpointSingular = `${apiUrl}/verification-standard`;

export function getServiceCategory(data) {
  const queryParams = new URLSearchParams(data).toString();
  return http.get(`${apiEndpointSingular}/service-categories?${queryParams}`);
}

export function getAreaBuilding() {
  return http.get(`${apiEndpointSingular}/area-building`);
}

export function getJobs(data) {
  const queryParams = new URLSearchParams(data).toString();
  return http.get(`${apiEndpointSingular}/jobs?${queryParams}`);
}

export function getStandardById(id) {
  return http.get(`${apiEndpointSingular}/${id}/standard`);
}

export function getStandards(data) {
  const queryParams = new URLSearchParams(data).toString();
  return http.get(`${apiEndpointSingular}/standards?${queryParams}`);
}

export function getStandardJobs(jobIds) {
  const queryParams = jobIds
    .map((id) => `jobs[]=${encodeURIComponent(id)}`)
    .join("&");
  return http.get(`${apiEndpointSingular}/standard-jobs?${queryParams}`);
}

export function checkStandardStatus(data) {
  const queryParams = new URLSearchParams(data).toString();
  return http.get(
    `${apiEndpointSingular}/check-standard-status/?${queryParams}`
  );
}

export function checkStandardStatusByStandardIdAndExecutionId(data) {
  const queryParams = new URLSearchParams(data).toString();
  return http.get(
    `${apiEndpointSingular}/check-analysis-status?${queryParams}`
  );
}

export function getReasons() {
  return http.get(`${apiEndpointSingular}/reasons`);
}

export function createAreaBuilding(data) {
  return http.post(`${apiEndpointSingular}/create-area-building`, data);
}

export function dispatchStandardExecution(data) {
  return http.post(`${apiEndpointSingular}/dispatch`, data);
}

export function dispatchStandardExecutionById(id, data) {
  return http.post(`${apiEndpointSingular}/${id}/dispatch-analysis`, data);
}

export function saveStandard(data) {
  return http.post(`${apiEndpointSingular}/save-standard`, data);
}

export function saveDuplicateStandard(data) {
  return http.post(`${apiEndpointSingular}/duplicate-standard`, data);
}

export function editStandard(id, data) {
  return http.post(`${apiEndpointSingular}/${id}/edit-standard`, data);
}

export function editStandardInfo(data) {
  return http.post(`${apiEndpointSingular}/edit-standard-info`, data);
}

export function saveFeedback(data) {
  return http.post(`${apiEndpointSingular}/save-feedback`, data);
}

export function deleteStandardInfo(data) {
  const queryParams = new URLSearchParams(data).toString();
  return http.delete(
    `${apiEndpointSingular}/delete-standard-info?${queryParams}`
  );
}

export function deleteStandard(id, jobId) {
  return http.delete(`${apiEndpointSingular}/${id}/delete?job_id=${jobId}`);
}

function getTabById(id) {
  const option = verificationTabOptions.find(
    (tabOption) => tabOption.id === id
  );
  return option ? option.tab : null;
}

export function getVerificationStandards(filterData, tabId) {
  const tab = getTabById(tabId);
  const filterString = getFilterStringVerificationStandard(filterData);
  return http.get(
    `${apiEndpointSingular}/standards?${filterString}filters[tab]=${tab}`
  );
}

export function getVerificationStandardsTabOffset(
  tabId,
  offset,
  filterData,
  filterAddon = undefined
) {
  const tab = getTabById(tabId);
  const filterString = getFilterStringVerificationStandard(filterData);
  return http.get(
    `${apiEndpointSingular}/standards?${filterString}filters[tab]=${tab}&offset=${offset}${
      filterAddon ?? ""
    }`
  );
}

export function getVerificationStandardSearch(search, filterData, tab) {
  const filterString = getFilterString(filterData);
  return http.get(
    `${apiEndpoint}?${filterString}filters[tab]=${tab}&search=${search}`
  );
}

// function getFilterString(filterData) {
//   return Object.entries(filterData)
//     .map(([key, value]) => `filters[${key}]=${value}`)
//     .join("&");
// }

// export function getVerificationStandardSearch(search, filterData) {
//   const filterString = getFilterString(filterData);

//   return new Promise((resolve) => {
//     setTimeout(() => {
//       const filteredData = mockVerificationStandardData.filter((standard) => {
//         let matchesSearch = true;

//         if (search) {
//           matchesSearch =
//             standard.standardName
//               .toLowerCase()
//               .includes(search.toLowerCase()) ||
//             standard.customer.toLowerCase().includes(search.toLowerCase()) ||
//             standard.job.toLowerCase().includes(search.toLowerCase()) ||
//             standard.industry.toLowerCase().includes(search.toLowerCase()) ||
//             standard.serviceCategory
//               .toLowerCase()
//               .includes(search.toLowerCase()) ||
//             standard.trade.toLowerCase().includes(search.toLowerCase());
//         }

//         if (filterString) {
//           const filters = new URLSearchParams(filterString);
//           filters.forEach((value, key) => {
//             if (key.startsWith("filters") && key.includes("industry")) {
//               if (
//                 !standard.industry.toLowerCase().includes(value.toLowerCase())
//               ) {
//                 matchesSearch = false;
//               }
//             }
//           });
//         }

//         return matchesSearch;
//       });

//       resolve({
//         data: {
//           data: filteredData,
//           filter_counts: filteredData.length,
//         },
//       });
//     }, 1000);
//   });
// }

// export function deleteVerificationStandard(id) {
//   return new Promise((resolve, reject) => {
//     setTimeout(() => {
//       if (id > 0) {
//         resolve({
//           success: true,
//           message: `Verfication with ID ${id} has been successfully deleted.`,
//         });
//       } else {
//         reject({
//           success: false,
//           message: `Error: Invalid ID provided.`,
//         });
//       }
//     }, 1000); // Retraso de 1 segundo
//   });
// }

// export function getVerificationStandardById(id) {
//   return new Promise((resolve) => {
//     setTimeout(() => {
//       const standard = mockVerificationStandardData.find(
//         (item) => item.id === Number(id)
//       );

//       if (standard) {
//         resolve({ data: standard });
//       } else {
//         resolve({ data: null }); // O puedes rechazar la promesa si prefieres
//       }
//     }, 1000);
//   });
// }

// export function getVerificationStandardStatus(verificationStandardId) {
//   return new Promise((resolve) => {
//     setTimeout(() => {
//       const mockStandardDetail = {
//         standardDetail: {
//           id: 3,
//           name: "Carpet Care Standard 3",
//           status: "1",
//           started_at: "2025-02-26T20:32:43.901Z",
//           saved: "1",
//           job_below_standard: [
//             {
//               id: 15,
//               standard_detail_id: "3",
//               label: "Vacuuming Completion",
//               description:
//                 "Visible loose dirt, dust, or debris remains on the carpets, indicating incomplete vacuuming.",
//             },
//             {
//               id: 16,
//               standard_detail_id: "3",
//               label: "Spot and Stain Removal",
//               description:
//                 "Stains are still visible after treatment attempts, indicating inadequate stain removal.",
//             },
//             {
//               id: 17,
//               standard_detail_id: "3",
//               label: "Deep Carpet Cleaning",
//               description:
//                 "The carpets show visible areas where embedded dirt or allergens remain, or the cleaning appears uneven.",
//             },
//             {
//               id: 18,
//               standard_detail_id: "3",
//               label: "Odor Control",
//               description:
//                 "Unpleasant odors persist, suggesting insufficient or ineffective deodorization.",
//             },
//             {
//               id: 19,
//               standard_detail_id: "3",
//               label: "High-Traffic Area Focus",
//               description:
//                 "High-traffic areas show excessive wear or dirt compared to other areas, with no visible evidence of additional attention.",
//             },
//             {
//               id: 20,
//               standard_detail_id: "3",
//               label: "Protective Treatment Application",
//               description:
//                 "No protection is apparent, or there is no evidence/documentation of protective treatments applied as required.",
//             },
//             {
//               id: 21,
//               standard_detail_id: "3",
//               label: "Inspection and Reporting",
//               description:
//                 "Missing or incomplete inspection reports or failure to identify visible issues with the carpet.",
//             },
//             {
//               id: 22,
//               standard_detail_id: "3",
//               label: "Emergency Services Response",
//               description:
//                 "Delayed response or incomplete stain removal during emergency services, leaving visibility of spills.",
//             },
//           ],
//           job_meet_standard: [
//             {
//               id: 15,
//               standard_detail_id: "3",
//               label: "Vacuuming Completion",
//               description:
//                 "All carpeted areas have been thoroughly vacuumed, with no visible loose dirt, dust, or debris remaining after cleaning.",
//             },
//             {
//               id: 16,
//               standard_detail_id: "3",
//               label: "Spot and Stain Removal",
//               description:
//                 "All identified stains have been treated with appropriate chemicals or methods, and the stains are no longer visible.",
//             },
//             {
//               id: 17,
//               standard_detail_id: "3",
//               label: "Deep Carpet Cleaning",
//               description:
//                 "The carpets appear uniformly clean and free of embedded dirt, allergens, and bacteria after shampooing, steam cleaning, or dry cleaning.",
//             },
//             {
//               id: 18,
//               standard_detail_id: "3",
//               label: "Odor Control",
//               description:
//                 "All carpets exhibit a neutral or pleasant scent, indicating effective deodorization.",
//             },
//             {
//               id: 19,
//               standard_detail_id: "3",
//               label: "High-Traffic Area Focus",
//               description:
//                 "High-traffic areas are visibly cleaner than before the service, with attention focused on wear-prone sections.",
//             },
//             {
//               id: 20,
//               standard_detail_id: "3",
//               label: "Protective Treatment Application",
//               description:
//                 "Evidence of applied protective treatment is visible, such as a water-repellent effect or documentation of application.",
//             },
//             {
//               id: 21,
//               standard_detail_id: "3",
//               label: "Inspection and Reporting",
//               description:
//                 "Inspection reports are completed and detail the condition of the carpets, including any wear or issues needing attention.",
//             },
//             {
//               id: 22,
//               standard_detail_id: "3",
//               label: "Emergency Services Response",
//               description:
//                 "Emergency carpet cleaning services were provided within the specified response time, with visible results removing spills or stains.",
//             },
//           ],
//           job_exceed_standard: [
//             {
//               id: 15,
//               standard_detail_id: "3",
//               label: "Vacuuming Completion",
//               description:
//                 "Carpeted areas are not only free of debris but show patterns or marks indicating detailed and methodical vacuuming.",
//             },
//             {
//               id: 16,
//               standard_detail_id: "3",
//               label: "Spot and Stain Removal",
//               description:
//                 "Stains are completely removed, with additional preventive measures taken to ensure they do not reappear.",
//             },
//             {
//               id: 17,
//               standard_detail_id: "3",
//               label: "Deep Carpet Cleaning",
//               description:
//                 "Carpets are impeccably clean, with attention to detail resulting in an enhanced appearance and tactile feel.",
//             },
//             {
//               id: 18,
//               standard_detail_id: "3",
//               label: "Odor Control",
//               description:
//                 "The environment is noticeably fresher, with carpets contributing to an inviting and pleasant atmosphere far exceeding standard odor control.",
//             },
//             {
//               id: 19,
//               standard_detail_id: "3",
//               label: "High-Traffic Area Focus",
//               description:
//                 "High-traffic areas exhibit less wear than expected for the traffic level, due to exceptional cleaning and care efforts.",
//             },
//             {
//               id: 20,
//               standard_detail_id: "3",
//               label: "Protective Treatment Application",
//               description:
//                 "Protective treatments are applied with extra measures taken to cover all areas uniformly, extending the lifespan significantly.",
//             },
//             {
//               id: 21,
//               standard_detail_id: "3",
//               label: "Inspection and Reporting",
//               description:
//                 "Reports include detailed photography and thorough analysis of carpet conditions, with proactive recommendations and solutions proposed.",
//             },
//             {
//               id: 22,
//               standard_detail_id: "3",
//               label: "Emergency Services Response",
//               description:
//                 "Rapid service that not only addresses the immediate issue but also provides preventive measures and follow-up to ensure no recurrence.",
//             },
//           ],
//         },
//       };

//       resolve({ data: mockStandardDetail });
//     }, 1000); // Simula un retraso de 1 segundo
//   });
// }

// // export function dispatchStandardExecution(data) {
// //   const {
// //     standards_input,
// //     standard_name,
// //     customer_id,
// //     service_category_id,
// //     jobs,
// //     industry_id,
// //     trade_id,
// //     area_building_id,
// //   } = data;

// //   // Validar que todos los campos requeridos estén presentes
// //   if (
// //     !standards_input ||
// //     !standard_name ||
// //     !customer_id ||
// //     !service_category_id ||
// //     !jobs ||
// //     !industry_id ||
// //     !trade_id ||
// //     !area_building_id
// //   ) {
// //     throw new Error("Todos los campos son requeridos.");
// //   }

// //   // Simular una respuesta del backend
// //   return new Promise((resolve) => {
// //     setTimeout(() => {
// //       resolve({
// //         verification_standard_job: {
// //           id: 6,
// //           standard_detail_id: "3",
// //           job_id: "11918",
// //           job: {
// //             id: 11918,
// //             job_number: "14396",
// //             job_description: "Walgreens - CHICAGO 252",
// //             site_number: "252",
// //           },
// //         },
// //       });
// //     }, 2000); // Simular un retraso de 2 segundos
// //   });
// // }
