import React from "react";
import { Box, Typography } from "@material-ui/core";
import HandThumbUp from "assets/icons/handThumbUp";
import HandsThumbUp from "assets/icons/handsThumbUp";
import HandThumbDown from "assets/icons/handThumbDown";
import useStyles from "./styles";

const standardsConfig = [
  {
    key: "meetsStandards",
    title: "Meets Standards:",
    icon: <HandThumbUp color="#9CBB65" width={20} height={20} />,
    dataKey: "job_meet_standard",
  },
  {
    key: "exceedsStandards",
    title: "Exceeds Standards:",
    icon: <HandsThumbUp color="#328914" width={24} height={24} />,
    dataKey: "job_exceed_standard",
  },
  {
    key: "belowStandards",
    title: "Below Standards:",
    icon: <HandThumbDown color="#747474" width={20} height={20} />,
    dataKey: "job_below_standard",
  },
];

const StandardsList = (props) => {
  const { standardsList } = props;
  const classes = useStyles();

  if (!standardsList) {
    return (
      <Typography className={classes.standardListDescription}>
        No standards available.
      </Typography>
    );
  }

  return (
    <Box className={classes.standardListContainer}>
      {standardsConfig.map((standard) => {
        const standardsData = standardsList[standard.dataKey];

        if (standardsData && standardsData.length > 0) {
          return (
            <Box key={standard.key}>
              <Box className={classes.standardListTitleContainer}>
                {standard.icon}
                <Typography className={classes.standardListTitle}>
                  {standard.title}
                </Typography>
              </Box>
              <ul>
                {standardsData.map((item, index) => (
                  <li key={index}>
                    <Typography className={classes.standardListDescription}>
                      <span className={classes.standardLabel}>
                        {item.label}:
                      </span>{" "}
                      {item.description}
                    </Typography>
                  </li>
                ))}
              </ul>
            </Box>
          );
        }
        return null;
      })}
    </Box>
  );
};

export default StandardsList;
