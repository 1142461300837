import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Box from "@material-ui/core/Box";
import RateIcon from "@material-ui/icons/RateReview";
import Typography from "@material-ui/core/Typography";
import FormInput from "../../components/ui/FormContent/formInput";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import React, { useEffect, useState } from "react";
import useStyles from "./dialog/styles";
import { ThumbsDown } from "./icon/thumbsDown";
import { ThumbsUp } from "./icon/thumbsUp";
import { TwoThumbsUp } from "./icon/twoThumbsUp";
import FormSelectAuto from "../../components/ui/FormContent/formSelectAuto";
import LoadingDialog from "../../components/ui/dialog/loadingDialog";
import SuccessDialog from "./dialog/successDialog";
import { useDialogSV } from "./WorkticketSVRatingDialogContext";
import { modifyRatingSV } from "../../services/lunaLensService";
import { useMutation, useQueryClient } from "react-query";
import { logException } from "../../components/util/logUtil";
import Skeleton from "@material-ui/lab/Skeleton";
import LoadingIndicator from "../../components/common/LoadingIndicator/loadingIndicator";

export function RatingDialogSV({
  open,
  handleClose,
  givenRating,
  isEditingRating = false,
  feedbackReasons,
  workticketId,
  changeSelectedRating,
  active_rating_id,
  feedbackLoading,
  isRefetchingSVRatingReasons,
}) {
  const initialData = {
    first_name: "",
    last_name: "",
    title: "",
    comment: "",
  };
  const [selectedReasonIds, setSelectedReasonIds] = useState([]);
  const [isSuccessDialogOpen, setIsSuccessDialogOpen] = useState(false);
  const classes = useStyles();
  const [dialogSVAction, dispatchDialogSVAction] = useDialogSV();
  const [isLoadingDialog, setIsLoadingDialog] = useState(false);
  const [data, setData] = useState(initialData);
  const handleBlur = async (event) => {
    data[event.name] = event.value;
    setData({ ...data });
  };

  const clickRating = (value) => {
    if (value === givenRating) {
      changeSelectedRating(4);
      return;
    }
    changeSelectedRating(value);
  };

  const [errors, setErrors] = useState({});
  const handleReasonChange = (event, newValue) => {
    setSelectedReasonIds((prev) => [...newValue]);
    setErrors((prev) => ({ ...prev, reason: false }));
  };
  const handleSuccessClose = () => {
    setIsSuccessDialogOpen(false);
  };
  const [isLoading, setIsLoading] = useState(false);
  const stringDetails = `Please rate this ticket by selecting one of the option below and provide additional 
feedback in the text box to help us improve our service. `;
  const buttonText = "Modify Rating";
  useEffect(() => {
    changeSelectedRating(givenRating);
  }, [givenRating]);
  const isAcceptDisabled =
    givenRating === 4 || !selectedReasonIds || data.comment.trim().length <= 0;
  const handleConfirm = async () => {
    dispatchDialogSVAction({
      type: "TOGGLE_MODIFY_RATING",
      open: false,
    });
    setIsLoadingDialog(true);
    setIsLoading(true);
    const reasonIds = selectedReasonIds.map((item) => item.value);
    const payload = {
      workticketId,
      feedbackObject: {
        rating_value: givenRating,
        reason_id: reasonIds,
        feedback: data.comment,
      },
    };
    modifyRatingMutation.mutate(payload);
  };
  const queryClient = useQueryClient();
  const modifyRatingMutation = useMutation({
    mutationFn: (data) => modifyRatingSV(data),
    onSuccess: () => {
      queryClient.invalidateQueries(["SVRatings"]);
      setTimeout(() => {
        setIsLoadingDialog(false);
        setErrors({});
        setSelectedReasonIds(null);
        setData({ ...data, comment: "" });
        setIsSuccessDialogOpen(true);
      }, 2000);
    },
    onError: (response) => {
      logException(response, "Error rating workticket");
      setIsLoading(false);
    },
  });
  return (
    <>
      <Dialog
        open={open}
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description"
        maxWidth={"md"}
        fullWidth={false}
      >
        <>
          <DialogContent className={classes.wrapperDialog}>
            <IconButton
              aria-label="close"
              onClick={() => handleClose()}
              className={classes.wrapperDialogClose}
            >
              <CloseIcon className={classes.iconDialogClose} />
            </IconButton>
            <Box className={classes.headerContainer}>
              <RateIcon className={classes.iconDialog} />
              <Typography
                variant="h6"
                gutterBottom
                className={classes.titleDialog}
              >
                {isEditingRating ? "Edit Rating" : "Rate this Service"}
              </Typography>
            </Box>
            <Box className={classes.contentDialogRatingWrapper}>
              <Typography
                variant="body1"
                gutterBottom
                className={classes.contentDialogRating}
              >
                {stringDetails}
              </Typography>
            </Box>
            <Box className={classes.ratingButtonsContainer}>
              <Button
                value={0}
                className={
                  givenRating === "0"
                    ? classes.didNotMeetStandardButtonActive
                    : classes.didNotMeetStandardButton
                }
                startIcon={<ThumbsDown />}
                onClick={() => clickRating("0")}
              >
                Did not meet the standard
              </Button>
              <Button
                value={1}
                className={
                  givenRating === "1"
                    ? classes.metStandardButtonActive
                    : classes.metStandardButton
                }
                startIcon={<ThumbsUp />}
                onClick={() => clickRating("1")}
              >
                Met the Standard
              </Button>
              <Button
                value={2}
                className={
                  givenRating === "2"
                    ? classes.exceededStandardButtonActive
                    : classes.exceededStandardButton
                }
                startIcon={<TwoThumbsUp />}
                onClick={() => clickRating("2")}
              >
                Exceeded the Standard
              </Button>
            </Box>
            {(feedbackLoading || isRefetchingSVRatingReasons) && (
              <LoadingIndicator />
            )}
            {!feedbackLoading && !isRefetchingSVRatingReasons && (
              <Box className={classes.contentDialogRatingWrapperDeleteRating}>
                <FormSelectAuto
                  name="reasons"
                  label="Reason*"
                  multiple={true}
                  placeholder="Select Reasons"
                  options={feedbackReasons}
                  handleChange={handleReasonChange}
                  value={selectedReasonIds}
                  // error={
                  //   errors.reason
                  //     ? [
                  //         {
                  //           key: "reasons",
                  //           message: errors.reason,
                  //         },
                  //       ]
                  //     : []
                  // }
                />
              </Box>
            )}
            <FormInput
              rows={7}
              multiline={true}
              gridSizes={[{ size: "md", val: 12 }]}
              name="comment"
              label="Feedback on Luna*"
              placeholder="Please provide feedback on Luna"
              value={data.comment}
              handleBlur={handleBlur}
            />
          </DialogContent>
          <DialogActions className={classes.wrapperDialogActionRating}>
            <Button
              onClick={() => handleClose()}
              className={classes.buttonOutlinedRating}
              variant="outlined"
              size="large"
              color="primary"
            >
              Cancel
            </Button>
            <Button
              onClick={handleConfirm}
              className={classes.buttonRateService}
              color="primary"
              variant="contained"
              size="large"
              disabled={isAcceptDisabled}
            >
              {buttonText}
            </Button>
          </DialogActions>
        </>
      </Dialog>
      <SuccessDialog
        title={"Rating Modified"}
        message={"Rating Modified Successfully"}
        open={isSuccessDialogOpen}
        handleClose={handleSuccessClose}
      />
      <LoadingDialog open={isLoadingDialog} />
    </>
  );
}
