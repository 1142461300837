import React from "react";
const Context = React.createContext(null);

export const Provider = Context.Provider;
export const Consumer = Context.Consumer;

const VerifyRedirectContext = React.createContext();
const initialState = {
  workticketId: null,
  workticketNumber: null,
  analysisId: null,
};
export const reducer = (state, action) => {
  switch (action.type) {
    case "SET_VERIFY_REDIRECT":
      return {
        ...state,
        workticketId: action.workticketId,
        workticketNumber: action.workticketNumber,
        analysisId: action.analysisId,
      };
    default:
      return state;
  }
};
const VerifyRedirectContextProvider = ({ children }) => (
  <VerifyRedirectContext.Provider
    value={React.useReducer(reducer, initialState)}
  >
    {children}
  </VerifyRedirectContext.Provider>
);
const useVerifyRedirect = () => {
  const context = React.useContext(VerifyRedirectContext);
  if (context === undefined) {
    throw new Error(
      "useVerifyRedirect must be used within a VerifyRedirectContextProvider"
    );
  }
  return context;
};

export { VerifyRedirectContextProvider, useVerifyRedirect };
