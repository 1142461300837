import React, { useContext, useEffect, useRef, useState } from "react";
import Box from "@material-ui/core/Box";
import CategoryHeader from "./categoryHeader";
import { Button } from "@material-ui/core";
import DialogFeedbackLuna from "./dialog/dialogFeedbackLuna";
import AnalysisList from "./analysisList";
import DialogSaveAnalysis from "./dialog/dialogSaveAnalysis";
import useLunaLensStore from "store/lunaLensAnalysis";
import { getAnalysisLensPdf, saveFeedback } from "services/lunaLensService";
import DialogLoadingLuna from "./dialog/dialogLoadingLuna";
import Typography from "@material-ui/core/Typography";
import EditIcon from "@material-ui/icons/Edit";
import SaveOutlinedIcon from "@material-ui/icons/SaveOutlined";
import useStyles from "./styles";
import DoneIcon from "@material-ui/icons/Done";
import GlobalUiContext from "contexts/globalUiContext";
// import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import AnalysisLunaChat from "./analysisLunaChat";
import LoadingStateHorizontal from "../LoadingStateHorizontal/LoadingStateHorizontal";
import FeedbackButton from "./feedbackButton";
import { hasPermission, permissionWorkticket } from "lib/permissions";
import PdfIcon from "./images/pdfIcon";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import LoadingIndicator from "../LoadingIndicator/loadingIndicator";
import { LunaLensOverrideIcon } from "./icons/LunaLensOverrideIcon";
import LunaLensOverride from "./dialog/LunaLensOverride";
import { AttachFileIcon } from "./icons/AttachFileIcon";
import { SuggestedPrompts } from "./SuggestedPrompts";
import { AnalysisSVActions } from "./analysisSVActions";
import { AnalysisSVInformation } from "./LensAnalysis/analysisSVInformation";

const AnalysisCard = ({
  newAnalysisFlag,
  loading,
  analysisThread,
  analysisSV,
  closeLensDialog,
  handleCategoryContext,
  indexThread,
  totalThreads,
  onHandleSaveAnalysis,
  responseText,
  onUpdateTrade,
  loadingUpdateAnalysis,
  setLoading,
  onHandleThumbUp,
  onHandleThumbDown,
  fillUp,
  fillDown,
  workticketNumber,
  workticketId,
  userId,
  userAnalysisId,
  clearContext,
  categoryRemoved,
  setCategoryRemoved,
  view,
  newWorkticketNumber,
  reportType,
  loadingAddAnalysis,
}) => {
  const classes = useStyles();

  const { globalUi } = useContext(GlobalUiContext);
  const { permissions } = globalUi;

  const [loadingPdf, setLoadingPdf] = useState(false);
  const ref = useRef();
  const [openFeedbackDialog, setOpenFeedbackDialog] = useState(false);
  const [openSaveAnalysisDialog, setOpenSaveAnalysisDialog] = useState(false);
  const [edit, setEdit] = useState(false);
  const [indexCategoryThumbDown, setIndexCategoryThumbDown] = useState();
  const [clearCategoryContext, setClearCategoryContext] = useState(false);
  const [openLoading, setOpenLoading] = useState(false);
  const setAnalysisFeedback = useLunaLensStore(
    (state) => state.setAnalysisFeedback
  );
  const analysisComment = useLunaLensStore((state) => state.analysisComment);

  useEffect(() => {
    if (ref.current) {
      ref.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  }, []);

  const handleCloseFeedbackDialog = () => {
    setOpenFeedbackDialog(false);
  };

  const handleThumbDownOpenDialog = (id, category, prompt, runId) => {
    setOpenFeedbackDialog(true);
    setIndexCategoryThumbDown({ id, category, prompt, runId });
  };

  const onHandleDone = async () => {
    await onUpdateTrade(analysisThread.id);
    setEdit(false);
  };

  const handleThumbUp = async (id, category, prompt, runId) => {
    setOpenLoading(true);
    const user = JSON.parse(localStorage.getItem("user"));
    const model =
      reportType === "1" ? "service verification" : "insights report";
    await saveFeedback({
      type: "positive",
      reason: null,
      comment: null,
      run_id: runId,
      session_id: null,
      workticket_analysis_id: id,
      category: category,
      feedback_date: new Date().toISOString(),
      feedback_score: 1,
      feedback_reasons: ["Luna response met or exceeded my expectations."],
      prompt: prompt,
      user_id: user.id,
      user_email: user.email,
      model: model,
    });
    setAnalysisFeedback(id, category, {
      type: "positive",
      reason: null,
      comment: null,
    });
    setOpenLoading(false);
  };
  return (
    <Box ref={ref}>
      <Box
        className={classes.analysisCardContainer}
        style={analysisComment ? { marginBottom: "32px" } : {}}
      >
        <Box className={classes.chatLensIconContainer}>
          <AnalysisLunaChat />
        </Box>
        <Box className={classes.chatLensMessageContainer}>
          <Typography
            style={{
              fontSize: "14px",
              fontWeight: 400,
            }}
            className={classes.analysisHeaderTitle}
          >
            {responseText}
          </Typography>
          {parseInt(analysisThread?.saved) === 1 && (
            <Typography
              style={{
                fontSize: "14px",
                fontWeight: 600,
              }}
            >
              (Saved)
            </Typography>
          )}
        </Box>
        {analysisThread?.luna_lens_analysis_categories
          ?.filter(
            (category) =>
              category.luna_lens_analysis_categories_trade &&
              category.luna_lens_analysis_categories_trade.length > 0
          )
          .map((category, index) => (
            <React.Fragment key={index}>
              <CategoryHeader
                id={category.id}
                categoryId={category.luna_lens_category.id}
                iconType={category.luna_lens_category.icon}
                tagColor={category.luna_lens_category.icon_color}
                tagBackgroundColor={
                  category.luna_lens_category.background_color
                }
                title={category.luna_lens_category.label}
                backGroundHeader={newAnalysisFlag ? "white" : "#F8F8F8"}
                feedback={newAnalysisFlag}
                fillUp={category?.feedback?.type === "positive"}
                fillDown={category?.feedback?.type === "negative"}
                onHandleThumbUp={() =>
                  handleThumbUp(
                    analysisThread.id,
                    category.luna_lens_category.value,
                    analysisThread.prompt,
                    analysisThread.run_id
                  )
                }
                onHandleThumbDown={() =>
                  handleThumbDownOpenDialog(
                    analysisThread.id,
                    category.luna_lens_category.value,
                    analysisThread.prompt,
                    analysisThread.run_id
                  )
                }
                edit={edit}
                categoryValue={category.luna_lens_category.value}
                loading={loading}
                handleCategoryContext={handleCategoryContext}
                indexThread={indexThread}
                totalThreads={totalThreads}
                userId={userId}
                userAnalysisId={userAnalysisId}
                loadingUpdateAnalysis={loadingUpdateAnalysis}
                analysisWorkticketId={analysisThread.id}
                analysis={analysisThread}
                action={category?.action}
                clearCategoryContext={clearCategoryContext}
                setClearCategoryContext={setClearCategoryContext}
                categoryRemoved={categoryRemoved}
                setCategoryRemoved={setCategoryRemoved}
                analysisReportType={reportType}
              />

              <AnalysisSVInformation
                complianceOnSite={
                  analysisSV?.luna_service_certification[0]?.compliance_on_site
                }
                complianceOnTime={
                  analysisSV?.luna_service_certification[0]?.compliance_on_time
                }
                requiredMedia={
                  analysisSV?.luna_service_certification[0]
                    ?.compliance_has_media
                }
                rating={
                  analysisSV?.luna_service_certification[0]?.rating_status
                }
              />
              <AnalysisList
                analysis={category?.luna_lens_analysis_categories_trade}
                background={newAnalysisFlag}
                edit={edit}
                thread={analysisThread.id}
                category={category.luna_lens_category.value}
                workticketId={
                  view === "tab" ? workticketId : analysisThread.workticket_id
                }
                workticketNumber={
                  view === "tab" ? workticketNumber : newWorkticketNumber
                }
                loadingUpdateAnalysis={loadingUpdateAnalysis}
                action={category?.action}
              />
              {reportType === "1" && (
                <Box className={classes.lunaCommentsContainer}>
                  <Typography component="span" className={classes.description}>
                    {analysisSV?.luna_service_certification[0]?.luna_comment}
                  </Typography>
                  <Box className={classes.suggestedStatusContainer}>
                    <Typography
                      component="span"
                      className={classes.suggestedStatus}
                    >
                      Suggested Status:{" "}
                      {
                        analysisSV?.luna_service_certification[0]
                          ?.suggested_action_reason
                      }
                    </Typography>
                  </Box>
                </Box>
              )}
            </React.Fragment>
          ))}

        <DialogFeedbackLuna
          open={openFeedbackDialog}
          handleCancel={handleCloseFeedbackDialog}
          thread={indexCategoryThumbDown}
        />
        <DialogLoadingLuna open={openLoading} handleCancel={setOpenLoading} />
        <DialogSaveAnalysis
          open={openSaveAnalysisDialog}
          handleCancel={() => {
            setOpenSaveAnalysisDialog(false);
          }}
          prompt={prompt}
          thread={analysisThread}
          onHandleDone={onHandleSaveAnalysis}
        />
      </Box>
      {!loading && !analysisComment && (
        <>
          {reportType === "1" &&
            analysisSV?.luna_service_certification[0]?.user_review_status ===
              "1" &&
            !loadingAddAnalysis &&
            parseInt(analysisThread.saved) !== 1 && (
              <>
                <Box className={classes.overrideButtonContainer}>
                  <LunaLensOverride
                    workticketId={workticketNumber}
                    mainClasses={classes}
                  />
                </Box>
              </>
            )}
          <Box className={classes.historyButtonContainer}>
            {!edit ? (
              <>
                {loadingPdf && <LoadingIndicator />}
                {!loadingPdf && (
                  <>
                    {reportType === "1" &&
                      parseInt(analysisThread.saved) === 1 &&
                      !loadingAddAnalysis && (
                        <AnalysisSVActions
                          analysisThread={analysisThread}
                          analysis={analysisSV}
                          workticketId={workticketId}
                          setLoading={setLoading}
                          clearContext={clearContext}
                          setClearCategoryContext={setClearCategoryContext}
                          reviewStatus={
                            analysisSV?.luna_service_certification[0]
                              ?.user_review_status
                          }
                        />
                      )}
                    {reportType === "0" && (
                      <>
                        {(userId === userAnalysisId ||
                          hasPermission(
                            permissionWorkticket.LUNA_LENS_MANAGE,
                            permissions
                          )) && (
                          <Box style={{ display: "flex", gap: "12px" }}>
                            <Button
                              className={classes.buttonAnalysisFilled}
                              startIcon={
                                <EditIcon style={{ fontSize: "18" }} />
                              }
                              onClick={() => {
                                setEdit(true);
                                setLoading(true);
                                clearContext();
                                setClearCategoryContext(true);
                              }}
                            >
                              Edit Answer
                            </Button>
                            {parseInt(analysisThread.saved) === 0 && (
                              <Button
                                className={classes.buttonHeaderAnalysis}
                                startIcon={
                                  <SaveOutlinedIcon
                                    style={{ color: "#4F98BC" }}
                                  />
                                }
                                onClick={() => {
                                  setOpenSaveAnalysisDialog(true);
                                }}
                              >
                                Save Report
                              </Button>
                            )}
                          </Box>
                        )}
                        <Box
                          style={{
                            display: "flex",
                            margin: "0 8px",
                            gap: "8px",
                          }}
                        >
                          <Button
                            className={classes.buttonHeaderAnalysis}
                            startIcon={
                              <PictureAsPdfIcon style={{ color: "#4F98BC" }} />
                            }
                            onClick={async () => {
                              try {
                                setLoadingPdf(true);
                                const result = await getAnalysisLensPdf(
                                  analysisThread.analysis_id
                                );
                                const file = new Blob([result.data], {
                                  type: "application/pdf",
                                });
                                const fileURL = URL.createObjectURL(file);
                                window.open(fileURL);
                                setLoadingPdf(false);
                              } catch (e) {
                                console.error("Error loading pdf", e);
                                setLoadingPdf(false);
                              }
                            }}
                          >
                            Print PDF
                          </Button>
                          <FeedbackButton
                            type="up"
                            filled={fillUp}
                            onHandleClick={onHandleThumbUp}
                          />
                          <FeedbackButton
                            type="down"
                            filled={fillDown}
                            onHandleClick={onHandleThumbDown}
                          />
                        </Box>
                      </>
                    )}
                  </>
                )}
              </>
            ) : loadingUpdateAnalysis ? (
              <Box className={classes.loadingContainer}>
                <LoadingStateHorizontal isVisible />
              </Box>
            ) : (
              <Button
                className={classes.buttonAnalysisFilled}
                startIcon={<DoneIcon style={{ fontSize: "18" }} />}
                onClick={onHandleDone}
              >
                Done
              </Button>
            )}
          </Box>
        </>
      )}
    </Box>
  );
};

export default AnalysisCard;
