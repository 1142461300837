import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    height: "100%",
    display: "flex",
    maxWidth: "100%",
  },
  contentContainer: {
    padding: "0 20px",
    flex: "1 1",
    display: "flex",
    flexDirection: "column",
    maxWidth: "100%",
  },
  rootContainer: {
    display: "flex",
    flexGrow: 1,
    height: "100vh",
  },
  tableContainer: {
    display: "flex",
    flexGrow: 1,
    maxWidth: "100%",
    maxHeight: "65%",
    [theme.breakpoints.down("xs")]: {
      maxHeight: "55%",
      paddingTop: "150px !important",
    },
    [theme.breakpoints.down("sm")]: {
      maxHeight: "55%",
      paddingTop: "100px !important",
    },
    [theme.breakpoints.down("md")]: {
      paddingTop: "50px !important",
    },
    [theme.breakpoints.down("lg")]: {
      paddingTop: "35px !important",
    },
    "& table": {
      minWidth: 800,
    },
  },
  tableContainerPagination: {
    width: "100%",
    "& > div > div": {
      marginBottom: 0,
      minHeight: "100%",
    },
  },
  tableContainerPaginationLoading: {
    width: "100%",
    display: "flex",
    height: "65vh",
    alignItems: "center",
    justifyContent: "center",
    "& > div": {
      minHeight: "0%",
    },
    [theme.breakpoints.down("sm")]: {
      height: "55vh",
    },
  },
  centerLoading: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
  tabItem: {
    display: "block",
  },
  tablePagination: {
    backgroundColor: "#F8F8F8",
    paddingTop: 8,
    paddingBottom: 8,
    paddingLeft: 16,
    paddingRight: 16,
  },
  tablePaginationLabels: {
    display: "flex",
    justifyContent: "end",
    alignItems: "center",
    gap: 20,
    [theme.breakpoints.down("sm")]: {
      justifyContent: "start",
    },
  },
  tablePaginationLabel: {
    fontSize: 12,
    fontWeight: 600,
  },
  tablePaginationActions: {
    display: "flex",
    gap: 4,
    marginTop: 4,
  },
  tablePaginationAction: {
    cursor: "pointer",
    "& svg": {
      fontSize: 14,
    },
  },
  menuIcon: {
    width: 24,
    height: 24,
    flexShrink: 0,
    borderRadius: 4,
    background: "var(--Grey-200, #ECECEC)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  menuText: {
    paddingLeft: 8,
    color: "var(--Grey-400, #747474)",
    fontSize: 14,
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
  },
  accordionRoot: {
    boxShadow: "none",
    border: "0px solid blue",
    position: "unset",
    borderWidth: 0,
    backgroundColor: "#F8F8F8",
    borderRadius: 0,
    marginBottom: 15,
    "&.MuiExpansionPanel-root:before": {
      display: "none",
      backgroundColor: "transparent",
    },
    "&.MuiIconButton-root": {
      position: "absolute",
      right: "0",
      top: "0",
    },
  },
  iconDown: {
    transform: "rotate(180deg)",
    transition: "transform 0.3s ease",
  },
  accordionSummary: {
    borderBottom: "none",
    color: "#747474",
    fontSize: 14,
    fontStyle: "normal",
    fontWeight: 400,
    minHeight: 40,
    height: 40,
    padding: "0 16px",
    "& .MuiAccordionSummary-content": {
      margin: "0",
    },
  },
  accordionSummaryExpanded: {
    height: "30px !important",
    minHeight: "30px !important",
  },
  accordionDetails: {
    flexDirection: "column",
    background: "#F8F8F8",
    padding: "5px 16px 16px",
  },
  textField: {
    borderRadius: 4,
    border: "1px solid  #D9D9D9",
    background: "#FFF",
    marginBottom: 10,
    "& .MuiInputBase-input": {
      padding: 10,
      fontSize: 14,
      fontStyle: "normal",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#D9D9D9",
      },
      "&:hover fieldset": {
        borderColor: "#D9D9D9",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#D9D9D9",
      },
    },
  },
  filterButtonContainer: {
    display: "flex",
    flexWrap: "wrap",
    gap: theme.spacing(1),
  },
  toggleButton: {
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  activeButtons: {
    color: "#4F98BC",
    borderRadius: 4,
    border: "1px solid #4F98BC",
    background: "#EDF5F8",
    fontSize: 14,
    fontStyle: "normal",
    fontWeight: 400,
    textTransform: "capitalize",
  },
  inactiveButton: {
    color: "#747474",
    borderRadius: 4,
    border: "1px solid #D9D9D9",
    background: "#FFF",
    fontSize: 14,
    fontStyle: "normal",
    fontWeight: 400,
    textTransform: "capitalize",
  },
  checkListContainer: {
    display: "flex",
    flexDirection: "column",
  },
  filterCheckbox: {
    color: "#D9D9D9",
    border: "none",
    padding: 0,
    paddingRight: 5,
    "& + .MuiFormControlLabel-label": {
      fontSize: 14,
      marginLeft: 0,
      color: "#747474",
    },
  },
  checkbox: {
    marginLeft: 0,
    marginRight: 0,
  },
  filterContainer: {
    padding: 20,
    backgroundColor: "white",
    width: "100%",
    minWidth: 400,
    maxWidth: 400,
    height: "auto",
    display: "flex",
    flexDirection: "column",

    [theme.breakpoints.down("sm")]: {
      padding: 20,
      width: "100%",
      minWidth: 375,
      maxWidth: 375,
    },
    [theme.breakpoints.down("xs")]: {
      padding: 20,
      width: "100%",
      minWidth: 350,
      maxWidth: 350,
    },
  },
  subContainer: {
    overflowY: "auto",
    maxHeight: "60vh",
  },
  filterHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 20,
  },
  headerTitle: {
    color: "#4D4D4D",
    fontSize: 16,
    fontStyle: "normal",
    fontWeight: 700,
    margin: 0,
  },
  buttonCancel: {
    borderRadius: 4,
    border: "1px solid  #D9D9D9",
    textTransform: "capitalize",
    marginRight: 10,
    maxHeight: 30,
    width: "100",
  },
  buttonApply: {
    borderRadius: 4,
    border: "1px solid #4F98BC",
    color: "#ffffff",
    fontSize: 14,
    textTransform: "capitalize",
    backgroundColor: "#4f97bb",
    maxHeight: 30,
    width: "100",
    "&:hover": {
      backgroundColor: "#4F98BC",
    },
  },
  accordionContainer: {
    overflowY: "auto",
    maxHeight: "50vh",
    "&::-webkit-scrollbar": {
      width: 6,
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "rgba(0, 0, 0, 0.2)",
      borderRadius: 5,
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "transparent",
    },
  },
  filterBtn: {
    textTransform: "none",
    color: "#4F98BC",
    backgroundColor: "#EDF5F8",
    border: "1px solid #EDF5F8",
    width: 100,
    fontSize: 14,
    paddingTop: 8,
    paddingBottom: 8,
    fontWeight: 400,
    "& svg": {
      marginRight: 8,
      width: 16,
      top: -2,
      position: "relative",
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: 10,
      marginLeft: 5,
    },
  },
  badgeFilters: {
    backgroundColor: "#4F98BC",
    color: "#FFF",
    borderRadius: 10,
    padding: "0 5px",
    marginLeft: 8,
    fontSize: 11,
    width: 25,
    fontWeight: 600,
  },
  containerBtnCreateStandard: {
    display: "inline-block",
    float: "left",
    "& button": {
      marginLeft: theme.spacing(1),
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      float: "none",
      marginTop: 10,
    },
  },
  btnNewStandard: {
    color: "#fff",
    backgroundColor: "#4F98BC !important",
    border: "1px solid #4F98BC",
    fontSize: 14,
    fontStyle: "normal",
    textTransform: "Capitalize",
    paddingLeft: 10,
    paddingRight: 10,
    marginLeft: 10,
    minHeight: 43,
    boxShadow: "none",
    "&:hover": {
      backgroundColor: "#4F98BC",
      boxShadow: "none",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: 12,
      paddingLeft: 5,
      paddingRight: 5,
      "&:hover": {
        backgroundColor: "#4F98BC",
      },
    },
  },
  textSeeMoreContainer: {
    fontSize: 14,
    fontWeight: 400,
    color: "#747474",
    whiteSpace: "pre-line",
  },
  bulletPoint: {
    width: 5,
    height: 5,
    borderRadius: "50%",
    backgroundColor: "#747474",
    marginRight: theme.spacing(1),
    marginTop: "6px",
    flexShrink: 0,
  },
  laberStandard: {
    fontSize: 14,
    fontWeight: 600,
    color: "#747474",
  },
  seeMoreText: {
    color: "#4F98BC",
    textDecoration: "underline",
    cursor: "pointer",
    marginLeft: 5,
  },
  tableHeaderIconContainer: {
    display: "inline-flex",
    alignItems: "center",
  },
  tableHeaderIconText: {
    marginLeft: theme.spacing(1),
  },
  todayButton: {
    backgroundColor: "#4F98BC",
    color: "#FFF",
    textAlign: "center",
    fontSize: 14,
    textTransform: "Capitalize",
    "&:hover": {
      backgroundColor: " #4F98BC",
      color: " #FFF",
      borderRadius: "4px",
    },
  },
  containerSearchAndFilters: {
    paddingBottom: theme.spacing(3),
  },
  filtersWrapper: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    gap: "16px",
    [theme.breakpoints.down("sm")]: {
      flexWrap: "wrap",
      justifyContent: "flex-start",
    },
    [theme.breakpoints.down("md")]: {
      paddingLeft: 10,
      flexWrap: "wrap",
      justifyContent: "flex-start",
    },
  },
  inputTextFilter: {
    borderRadius: 4,
    background: "#FFF",
    marginBottom: 10,
    "& .MuiInputBase-input": {
      padding: 10,
      fontSize: 14,
      fontStyle: "normal",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#D9D9D9",
      },
      "&:hover fieldset": {
        borderColor: "#D9D9D9",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#D9D9D9",
      },
    },
  },
  customScroll: {
    "&::-webkit-scrollbar": {
      width: "4px",
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "transparent",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "rgba(0, 0, 0, 0.2)",
      borderRadius: "4px",
    },
    "&::-webkit-scrollbar-thumb:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.3)",
    },
    "&::-webkit-scrollbar-button": {
      display: "none",
    },
  },
  buttonPadding: {
    paddingLeft: 20,
    paddingRight: 20,
    paddingBottom: 10,
  },
}));

export default useStyles;
