import React, { useState, useEffect } from "react";
import { Box, Typography } from "@material-ui/core";
import PropTypes from "prop-types";
import classNames from "classnames";
import useStyles from "./styles";

const PENDING = 0;
const COMPLETED = 1;
const FAILED = 2;

const ProgressBar = ({
  startDateTime,
  durationSeconds,
  status,
  errorMessage,
}) => {
  const classes = useStyles();
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    if (status === FAILED) {
      setProgress(50);
      return;
    }

    if (status === COMPLETED) {
      setProgress(100);
      return;
    }

    const startTime = new Date(startDateTime).getTime();
    const interval = setInterval(() => {
      const elapsedTime = (new Date().getTime() - startTime) / 1000;
      const newProgress = Math.min((elapsedTime / durationSeconds) * 100, 100);
      setProgress(newProgress);

      if (newProgress >= 100) clearInterval(interval);
    }, 1000);

    return () => clearInterval(interval);
  }, [startDateTime, durationSeconds, status]);

  const isFailed = status === FAILED;
  const isCompleted = status === COMPLETED || progress === 100;
  const isPending = status === PENDING;

  const getDisplayMessage = () => {
    if (isFailed) {
      return errorMessage
        ? errorMessage.length > 100
          ? errorMessage.slice(0, 100) + "..."
          : errorMessage
        : "There was an error in your analysis";
    } else if (isCompleted) {
      return "Analysis completed successfully";
    } else {
      return "Waiting to be completed";
    }
  };

  return (
    <Box className={classes.progressBarContainer}>
      <Box
        className={classNames(classes.progressBarBackground, {
          [classes.progressErrorBackground]: isFailed,
        })}
      >
        <Box
          className={classNames(classes.progressBarFill, {
            [classes.progressBarError]: isFailed,
          })}
          style={{ width: `${progress}%` }}
        />
      </Box>
      <Box className={classes.infoContainer}>
        {isPending && progress !== 100 && (
          <Typography className={classes.totalFilesLabel}></Typography>
        )}
        <Typography
          className={classNames(classes.timeLabel, {
            [classes.errorLabel]: isFailed,
          })}
        >
          {getDisplayMessage()}
        </Typography>
      </Box>
    </Box>
  );
};

ProgressBar.propTypes = {
  startDateTime: PropTypes.string.isRequired,
  durationSeconds: PropTypes.number.isRequired,
  status: PropTypes.oneOf([PENDING, FAILED, COMPLETED]).isRequired,
  errorMessage: PropTypes.string,
};

ProgressBar.defaultProps = {
  errorMessage: "",
  totalFiles: 0,
};

export default ProgressBar;
