export function TwoThumbsUpBigIcon() {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.5" y="0.5" width="39" height="39" rx="3.5" fill="#328914" />
      <rect x="0.5" y="0.5" width="39" height="39" rx="3.5" stroke="#328914" />
      <g clipPath="url(#clip0_1410_729)">
        <path
          d="M25.4769 22.8649C25.4769 24.5471 25.1059 26.1462 24.4289 27.5696C24.0486 28.383 23.5756 29.1409 23.0191 29.8249C22.6574 30.2685 22.101 30.4904 21.5259 30.4904H17.8068C17.3524 30.4904 16.9072 30.4256 16.4806 30.2778L13.5962 29.3257C13.1695 29.1779 12.7243 29.1039 12.2792 29.1039H11.5557C11.1662 29.1039 10.7952 28.8913 10.6561 28.5216C10.3408 27.7359 10.1738 26.8856 10.1738 25.989C10.1738 24.3715 10.7303 22.8834 11.6578 21.7095C11.7969 21.5247 12.0009 21.423 12.2142 21.3398C12.6501 21.1734 13.0397 20.8592 13.3365 20.4802C14.0506 19.5744 14.9595 18.8165 15.989 18.2619C16.666 17.9107 17.2411 17.3838 17.5286 16.6814C17.7233 16.1915 17.8254 15.6646 17.8254 15.1378V14.5462C17.8254 14.1673 18.1314 13.853 18.521 13.853C19.671 13.853 20.6077 14.7866 20.6077 15.9327C20.6077 16.2839 20.5799 16.6352 20.5243 16.9679C20.4315 17.6519 20.2275 18.3081 19.9307 18.9089C19.6896 19.4265 20.0327 20.092 20.6077 20.092H23.5107C24.4567 20.092 25.31 20.739 25.412 21.6818C25.4491 22.07 25.4769 22.4675 25.4769 22.8649Z"
          fill="white"
        />
        <path
          d="M33.9996 18.512C33.9996 21.1 33.1185 23.4755 31.6253 25.361C31.2914 25.7862 30.5958 26.1374 30.0579 26.1374H26.7561C27.2384 24.9913 27.3312 23.115 27.3312 21.4328C27.3312 21.4235 27.3312 21.4143 27.3312 21.405C27.3312 19.8799 26.0235 18.6599 24.4931 18.6599H22.462C21.4975 18.6599 21.887 17.6339 21.9705 17.0424C22.0261 16.6634 22.2209 16.0164 22.2301 15.8408C22.2394 15.6005 22.5733 15.3139 22.6753 15.2215C23.7326 14.2602 25.4855 13.7334 26.042 12.3285C26.2368 11.8386 26.3388 11.3117 26.3388 10.7849V10.1933C26.3388 9.83285 26.617 9.50935 26.9787 9.50011C28.1566 9.47238 29.1212 10.4152 29.1212 11.5798C29.1212 12.7444 28.8615 13.7241 28.407 14.6484C28.1566 15.1568 28.5554 15.7391 29.1212 15.7391H32.0334C32.9794 15.7391 33.8141 16.4231 33.9254 17.3566C33.9718 17.7356 33.9903 18.1238 33.9903 18.512H33.9996Z"
          fill="white"
        />
        <path
          d="M6.76979 21.9225C6.26896 23.1796 6 24.5568 6 25.9894C6 27.1171 6.16694 28.2077 6.48228 29.2337C6.72342 30.0194 7.48393 30.5 8.30937 30.5H9.15336C9.57072 30.5 9.82113 30.0378 9.63564 29.6681C9.08844 28.559 8.78238 27.3112 8.78238 25.9894C8.78238 24.4089 9.22756 22.93 9.98808 21.673C10.2107 21.3033 9.96025 20.7856 9.52435 20.7856H8.55051C7.78072 20.7856 7.0573 21.2016 6.76979 21.9225Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_1410_729">
          <rect
            width="28"
            height="21"
            fill="white"
            transform="translate(6 9.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
