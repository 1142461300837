export function ThumbsDownBigIcon() {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.5" y="0.5" width="39" height="39" rx="3.5" fill="#747474" />
      <rect x="0.5" y="0.5" width="39" height="39" rx="3.5" stroke="#747474" />
      <path
        d="M23.7303 13.5L24.7647 13.5C25.5455 14.6834 26 16.1011 26 17.625C26 19.1489 25.5455 20.5666 24.7647 21.75L24.6174 21.75C23.8111 21.75 23.0835 22.1958 22.5859 22.8303C21.8127 23.8162 20.8383 24.6366 19.7245 25.2298C19.0023 25.6144 18.3757 26.1857 18.0719 26.9454C17.8592 27.4769 17.75 28.0441 17.75 28.6166V29.25C17.75 29.6642 17.4142 30 17 30C15.7574 30 14.75 28.9926 14.75 27.75C14.75 26.5984 15.0096 25.5074 15.4734 24.5323C15.739 23.974 15.3664 23.25 14.7481 23.25L11.6223 23.25C10.5956 23.25 9.6767 22.556 9.56801 21.5351C9.52306 21.1129 9.5 20.6841 9.5 20.25C9.5 17.4024 10.4919 14.7864 12.149 12.7288C12.5366 12.2475 13.1358 12 13.7538 12L17.7698 12C18.2534 12 18.7339 12.078 19.1928 12.2309L22.3072 13.2691C22.7661 13.422 23.2466 13.5 23.7303 13.5Z"
        fill="white"
      />
      <path
        d="M29.6685 22.0229C30.2052 20.6611 30.5 19.1775 30.5 17.625C30.5 16.4049 30.3179 15.2274 29.9794 14.118C29.7201 13.268 28.8958 12.75 28.0071 12.75L27.0993 12.75C26.6538 12.75 26.3786 13.2483 26.5758 13.6478C27.1675 14.8471 27.5 16.1972 27.5 17.625C27.5 19.3332 27.0241 20.9302 26.1977 22.2907C25.9527 22.6941 26.226 23.25 26.6979 23.25H27.7506C28.5827 23.25 29.3635 22.797 29.6685 22.0229Z"
        fill="white"
      />
    </svg>
  );
}
