import Box from "@material-ui/core/Box";
import { Button } from "@material-ui/core";
import useStyles from "./styles";
import React, { useState } from "react";
import { CheckCircleOutlineOutlined } from "@material-ui/icons";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import { svConfirmAction } from "../../../services/lunaLensService";
import LoadingStateHorizontal from "../LoadingStateHorizontal/LoadingStateHorizontal";
import AnalysisLunaChat from "./analysisLunaChat";
import Typography from "@material-ui/core/Typography";

export function AnalysisSVActions({
  analysis,
  workticketId,
  clearContext,
  setClearCategoryContext,
  reviewStatus,
  analysisThread,
}) {
  const classes = useStyles();
  const [confirm, setConfirm] = useState(false);
  const [loading, setLoading] = useState(false);
  const suggested_action =
    analysis?.luna_service_certification[0]?.suggested_action;
  const confirmationMessage =
    suggested_action === "1"
      ? "Ok, I have confirmed the recalling of the workticket."
      : "Ok great, I have verified and confirmed the certification of the workticket 🥳";
  return (
    <>
      {loading && (
        <Box className={classes.loadingContainer}>
          <LoadingStateHorizontal isVisible />
        </Box>
      )}
      {!loading && (
        <>
          <Box className={classes.svContainerActionsChat}>
            <Box style={{ display: "flex", gap: "12px" }}>
              {(reviewStatus === "2" || confirm) && (
                <>
                  <Button
                    className={classes.buttonAnalysisFilled}
                    startIcon={<CheckCircleOutlineOutlined />}
                    // onClick={async () => {
                    //   clearContext();
                    //   setClearCategoryContext(true);
                    // }}
                  >
                    Confirmed
                  </Button>
                  <Button
                    className={classes.buttonHeaderAnalysis}
                    startIcon={
                      <PictureAsPdfIcon style={{ color: "#4F98BC" }} />
                    }
                    // onClick={async () => {
                    //   try {
                    //     setLoadingPdf(true);
                    //     const result = await getAnalysisLensPdf(
                    //       analysisThread.analysis_id
                    //     );
                    //     const file = new Blob([result.data], {
                    //       type: "application/pdf",
                    //     });
                    //     const fileURL = URL.createObjectURL(file);
                    //     window.open(fileURL);
                    //     setLoadingPdf(false);
                    //   } catch (e) {
                    //     console.error("Error loading pdf", e);
                    //     setLoadingPdf(false);
                    //   }
                    // }}
                  >
                    Print PDF
                  </Button>
                </>
              )}
              {reviewStatus === "0" && !confirm && (
                <>
                  <Button
                    className={classes.buttonAnalysisFilled}
                    disabled={loading}
                    onClick={async () => {
                      setLoading(true);
                      const response = await svConfirmAction({ workticketId });
                      if (response.data.message === "success") {
                        setConfirm(true);
                      }
                      clearContext();
                      setClearCategoryContext(true);
                      setLoading(false);
                    }}
                  >
                    Confirm
                  </Button>
                  <Button
                    disabled={loading}
                    className={classes.buttonHeaderAnalysis}
                    // onClick={() => {
                    //   setOpenSaveAnalysisDialog(true);
                    // }}
                  >
                    Override
                  </Button>
                </>
              )}
            </Box>
            {(reviewStatus === "2" || confirm) && (
              <Box
                className={classes.analysisCardContainer}
                // style={{ marginBottom: "32px" }}
              >
                <Box className={classes.chatLensIconContainer}>
                  <AnalysisLunaChat />
                </Box>
                <Typography>{confirmationMessage}</Typography>
              </Box>
            )}
          </Box>
        </>
      )}
      {/*<Box*/}
      {/*  className={classes.analysisCardContainer}*/}
      {/*  // style={{ marginBottom: "32px" }}*/}
      {/*>*/}
      {/*  <Box className={classes.chatLensIconContainer}>*/}
      {/*    <AnalysisLunaChat />*/}
      {/*  </Box>*/}
      {/*</Box>*/}
    </>
  );
}
