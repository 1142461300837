import React, { useState, useEffect } from "react";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Collapse from "@material-ui/core/Collapse";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CloseIcon from "@material-ui/icons/Close";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import InfoIcon from "@material-ui/icons/Info";
import Modal from "@material-ui/core/Modal";
import Typography from "@material-ui/core/Typography";
import LoadingIndicator from "components/common/LoadingIndicator/loadingIndicator";
import StandardsList from "../standardList";
import { getStandardJobs } from "services/verificationStandardService";
import useStyles from "./styles";

const STANDARD_TYPES = {
  CURRENT: "current",
  NEW: "new",
};

/**
 * A modal dialog component that allows users to compare and select standards
 * for various jobs. Users can choose to keep existing standards or adopt new ones.
 *
 * @param {Object} props - Component props.
 * @param {boolean} props.isOpen - Indicates if the dialog is open.
 * @param {function} props.onClose - Function to call when the dialog is closed.
 * @param {Object} props.comparisonData - Data for current and new standard assignments.
 * @param {function} props.onDecision - Callback function executed upon user decision.
 *
 * @returns {JSX.Element} A modal dialog for standard comparison and selection.
 */
const StandardComparisonDialog = (props) => {
  const classes = useStyles();
  const { isOpen, onClose, comparisonStandardData, onDecision } = props;
  const { currentStandardAssignments, newStandardAssignments } =
    comparisonStandardData || {};
  const [expandedJobs, setExpandedJobs] = useState({});
  const [expandedStandards, setExpandedStandards] = useState({});
  const [selectedStandards, setSelectedStandards] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [currentStandardsData, setCurrentStandardsData] = useState([]);

  // Fetch standard jobs data when the modal opens
  useEffect(() => {
    const fetchStandardJobs = async () => {
      if (isOpen && currentStandardAssignments?.length > 0) {
        setIsLoading(true);
        const jobIds = currentStandardAssignments.map((job) => job.value);

        try {
          const response = await getStandardJobs(jobIds);
          setCurrentStandardsData(response.data.standardDetails);
        } catch (error) {
          console.error("Error fetching standard jobs:", error);
        } finally {
          setIsLoading(false);
        }
      }
    };

    fetchStandardJobs();
  }, [isOpen, currentStandardAssignments]);

  // Transform current standards data for a specific job
  const transformCurrentStandards = (jobId) => {
    const jobStandards = currentStandardsData.find((standard) =>
      standard.verification_standard_job.some((job) => job.job_id === jobId)
    );

    if (!jobStandards) {
      return {
        job_meet_standard: [],
        job_exceed_standard: [],
        job_below_standard: [],
        standardId: null,
      };
    }

    return {
      job_meet_standard: jobStandards.job_meet_standard || [],
      job_exceed_standard: jobStandards.job_exceed_standard || [],
      job_below_standard: jobStandards.job_below_standard || [],
      standardId: jobStandards.id,
    };
  };

  // Transform new standards data
  const transformNewStandards = {
    job_meet_standard: newStandardAssignments.jobMeetStandard || [],
    job_exceed_standard: newStandardAssignments.jobExceedStandard || [],
    job_below_standard: newStandardAssignments.jobBelowStandard || [],
  };

  // Check if all items are selected
  const allItemsSelected = currentStandardAssignments.every((job) =>
    selectedStandards.some((selection) => selection.jobId === job.value)
  );

  // Get standards based on type (current or new)
  const getStandards = (job, type) =>
    type === STANDARD_TYPES.CURRENT
      ? transformCurrentStandards(job.value)
      : transformNewStandards;

  // Update selected standards state
  const updateSelectedStandards = (jobId, type) => {
    const standardsToSave = getStandards(
      currentStandardAssignments.find((job) => job.value === jobId),
      type
    );
    setSelectedStandards((prev) => [
      ...prev.filter((item) => item.jobId !== jobId),
      { type, jobId, standards: standardsToSave },
    ]);
  };

  // Toggle job expansion
  const handleToggleJob = (jobId) =>
    setExpandedJobs((prev) => ({ ...prev, [jobId]: !prev[jobId] }));

  // Toggle standards expansion
  const handleToggleStandards = (jobId) =>
    setExpandedStandards((prev) => ({ ...prev, [jobId]: !prev[jobId] }));

  // Handle "Keep" button click for a specific job
  const handleKeepClick = (jobId, type, event) => {
    event.stopPropagation();
    updateSelectedStandards(jobId, type);
  };

  // Handle "Keep Existing Standard" button click for all jobs
  const handleKeepExistingStandard = (event) => {
    event.stopPropagation();

    const data = {
      replace_jobs_standard: [],
    };

    onDecision(data);
    handleClose();
  };

  // Reset selectedStandards when the dialog closes
  const handleClose = () => {
    setSelectedStandards([]);
    onClose();
  };

  // Get current standard ID for a job
  const getCurrentStandardIdForJob = (jobId) => {
    const jobStandards = currentStandardsData.find((standard) =>
      standard.verification_standard_job.some(
        (job) => job.job_id === jobId.toString()
      )
    );

    if (!jobStandards) {
      console.warn(`No current standards found for job ID: ${jobId}`);
      return null;
    }

    const jobDetail = jobStandards.verification_standard_job.find(
      (job) => job.job_id === jobId.toString()
    );

    return jobDetail ? jobDetail.standard_detail_id : null;
  };

  /**
   * Save the selected standards.
   *
   * If all items are selected, collect the selection data and pass it to the onDecision callback.
   * The data is an object with a single property "replace_jobs_standard" which is an array of objects,
   * each containing a job ID and the current standard ID for that job.
   */
  const handleSaveSelection = () => {
    if (allItemsSelected) {
      const data = {
        replace_jobs_standard: selectedStandards
          .filter((selection) => selection.type === STANDARD_TYPES.NEW)
          .map((selection) => ({
            job: selection.jobId,
            current_standard_id: getCurrentStandardIdForJob(selection.jobId),
          })),
      };
      onDecision(data);
    }
  };

  return (
    <Modal open={isOpen} onClose={handleClose} className={classes.modal}>
      <Box className={classes.modalContent}>
        <Box className={classes.modalHeader}>
          <IconButton
            onClick={handleClose}
            className={classes.modalCloseButton}
          >
            <CloseIcon />
          </IconButton>
          <Typography className={classes.selectedText}>
            {currentStandardAssignments.length}/
            {currentStandardAssignments.length} jobs selected for this customer
          </Typography>
        </Box>
        <Box className={classes.modalBody}>
          {isLoading ? (
            <Box className={classes.loadingContainer}>
              <LoadingIndicator />
            </Box>
          ) : (
            <>
              <Box className={classes.bottomRow}>
                <Box>
                  <Box display="flex" alignItems="center">
                    <InfoIcon className={classes.jobIcon} />
                    <Typography className={classes.jobPreview_title}>
                      These jobs have an existing set of standards
                    </Typography>
                  </Box>
                  <Box ml={4}>
                    <Typography className={classes.jobPreview_subtitle}>
                      Choose whether to maintain the existing standard or adopt
                      the new one for each task.
                    </Typography>
                  </Box>
                </Box>
                <Box display="flex" alignItems="end">
                  <Button
                    type="button"
                    size="small"
                    variant="contained"
                    style={{ marginRight: 10 }}
                    className={classes.buttonWhite}
                    onClick={handleKeepExistingStandard}
                  >
                    Keep Existing Standard
                  </Button>
                  <Button
                    type="button"
                    size="small"
                    variant="contained"
                    color="secondary"
                    className={classes.buttonSecondary}
                    onClick={handleSaveSelection}
                    disabled={!allItemsSelected}
                  >
                    Save Selection
                  </Button>
                </Box>
              </Box>
              {currentStandardAssignments.map((job) => (
                <Box key={job.value} mt={2}>
                  <Box
                    className={classes.collapsibleContainer}
                    onClick={() => handleToggleJob(job.value)}
                  >
                    <IconButton>
                      <ExpandMoreIcon
                        style={{
                          transform: expandedJobs[job.value]
                            ? "rotate(180deg)"
                            : "rotate(0)",
                          transition: "transform 0.3s",
                        }}
                      />
                    </IconButton>
                    <Typography className={classes.labelFormText}>
                      {job.label}
                    </Typography>
                  </Box>
                  <Collapse
                    in={expandedJobs[job.value]}
                    timeout="auto"
                    unmountOnExit
                  >
                    <Box ml={4} mt={1}>
                      <Grid container spacing={3}>
                        <Grid item xs={6}>
                          <Box className={classes.jobPreview_flexRow}>
                            <Box
                              className={classes.jobPreview_clickableBox}
                              onClick={() => handleToggleStandards(job.value)}
                            >
                              <IconButton>
                                <ExpandMoreIcon
                                  style={{
                                    transform: expandedStandards[job.value]
                                      ? "rotate(180deg)"
                                      : "rotate(0)",
                                    transition: "transform 0.3s",
                                  }}
                                />
                              </IconButton>
                              <Typography
                                className={classes.jobPreview_subtitle}
                              >
                                Current Standards
                              </Typography>
                            </Box>
                            <Button
                              size="small"
                              variant="contained"
                              color="secondary"
                              className={classes.buttonSecondary}
                              disabled={selectedStandards.some(
                                (selection) =>
                                  selection.jobId === job.value &&
                                  selection.type === STANDARD_TYPES.NEW
                              )}
                              startIcon={
                                selectedStandards.some(
                                  (selection) =>
                                    selection.jobId === job.value &&
                                    selection.type === STANDARD_TYPES.CURRENT
                                ) ? (
                                  <CheckCircleIcon />
                                ) : null
                              }
                              onClick={(event) =>
                                handleKeepClick(
                                  job.value,
                                  STANDARD_TYPES.CURRENT,
                                  event
                                )
                              }
                            >
                              Keep
                            </Button>
                          </Box>
                          <Collapse
                            in={expandedStandards[job.value]}
                            timeout="auto"
                            unmountOnExit
                          >
                            <Box ml={2} mt={1}>
                              <StandardsList
                                standardsList={transformCurrentStandards(
                                  job.value
                                )}
                              />
                            </Box>
                          </Collapse>
                        </Grid>
                        <Grid item xs={6}>
                          <Box className={classes.jobPreview_flexRow}>
                            <Box
                              className={classes.jobPreview_clickableBox}
                              style={{ height: "50px" }}
                            >
                              <Typography
                                className={classes.jobPreview_subtitle}
                                style={{ paddingLeft: 40 }}
                              >
                                New Standard
                              </Typography>
                            </Box>
                            <Button
                              size="small"
                              variant="contained"
                              color="secondary"
                              className={classes.buttonSecondary}
                              disabled={selectedStandards.some(
                                (selection) =>
                                  selection.jobId === job.value &&
                                  selection.type === STANDARD_TYPES.CURRENT
                              )}
                              startIcon={
                                selectedStandards.some(
                                  (selection) =>
                                    selection.jobId === job.value &&
                                    selection.type === STANDARD_TYPES.NEW
                                ) ? (
                                  <CheckCircleIcon />
                                ) : null
                              }
                              onClick={(event) =>
                                handleKeepClick(
                                  job.value,
                                  STANDARD_TYPES.NEW,
                                  event
                                )
                              }
                            >
                              Keep
                            </Button>
                          </Box>
                          <Collapse
                            in={expandedStandards[job.value]}
                            timeout="auto"
                            unmountOnExit
                          >
                            <Box ml={2} mt={1}>
                              <StandardsList
                                standardsList={transformNewStandards}
                              />
                            </Box>
                          </Collapse>
                        </Grid>
                      </Grid>
                    </Box>
                  </Collapse>
                </Box>
              ))}
            </>
          )}
        </Box>
      </Box>
    </Modal>
  );
};

export default StandardComparisonDialog;
