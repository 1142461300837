import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import React from "react";
import useStyles from "../styles";
import { ThumbsDownSV } from "pages/workticketPage/icon/thumbsDownSV";
import { ThumbsUpSV } from "pages/workticketPage/icon/ThumbsUpSV";
import { TwoThumbsUpSV } from "pages/workticketPage/icon/twoThumbsUpSV";

const getRatingDisplay = (rating) => {
  switch (rating) {
    case "0":
      return {
        icon: <ThumbsDownSV strokeColor="#747474" />,
        text: "Did not meet the standard",
      };
    case "1":
      return {
        icon: <ThumbsUpSV thumbsUpColor="#9CBB65" />,
        text: "Met the Standard",
      };
    case "2":
      return {
        icon: <TwoThumbsUpSV strokeColor="#328914" />,
        text: "Exceeded the Standard",
      };
    default:
      return null;
  }
};

const InfoListItem = ({ label, value, classes, isRating = false }) => {
  if (!value) return null;

  if (isRating) {
    const ratingDisplay = getRatingDisplay(value);
    if (!ratingDisplay) return null;

    return (
      <Box className={classes.listContainer} style={{ display: "flex" }}>
        <span className={classes.bulletPoint}>•</span>
        <Box>
          <Typography component="span" className={classes.listSpan}>
            {label}:{" "}
          </Typography>
          <Box
            style={{
              display: "inline-flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {isRating && (
              <>
                <Box style={{ position: "relative" }}>
                  <Typography component="span" className={classes.description}>
                    <span
                      style={{ position: "absolute", top: "1px", left: "0" }}
                    >
                      {ratingDisplay.icon}
                    </span>
                    <span style={{ marginLeft: "20px" }}>
                      {ratingDisplay.text}
                    </span>
                  </Typography>
                </Box>
              </>
            )}
            {!isRating && (
              <Typography component="span" className={classes.description}>
                <span style={{ marginLeft: "4px", paddingTop: "2px" }}>
                  {ratingDisplay.icon}
                </span>
                <span style={{ marginLeft: "8px" }}>{value}</span>
              </Typography>
            )}
          </Box>
        </Box>
      </Box>
    );
  }

  return (
    <Box className={classes.listContainer} style={{ display: "flex" }}>
      <span className={classes.bulletPoint}>•</span>
      <Box>
        <Typography component="span" className={classes.listSpan}>
          {label}:{" "}
        </Typography>
        <Typography component="span" className={classes.description}>
          {value}
        </Typography>
      </Box>
    </Box>
  );
};

export function AnalysisSVInformation({
  complianceOnSite,
  complianceOnTime,
  requiredMedia,
  rating,
}) {
  const classes = useStyles();
  const infoItems = [
    { label: "Completed On Time", value: complianceOnTime },
    { label: "Verified On Site", value: complianceOnSite },
    { label: "Required Media", value: requiredMedia },
    { label: "Rating", value: rating, isRating: true },
  ];

  return (
    <>
      {infoItems.map(({ label, value, isRating }) => (
        <InfoListItem
          key={label}
          label={label}
          value={value}
          classes={classes}
          isRating={isRating}
        />
      ))}
    </>
  );
}
