import React, { useState, useContext } from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import { DialogCloseIcon } from "components/ui/core/dialogCloseIcon";
import { DialogTitle } from "components/ui/core/dialogTitle";
import TicketIcon from "@material-ui/icons/Receipt";
import { ButtonBase } from "components/ui/core/button";
import GlobalUiContext from "contexts/globalUiContext";
import { permissionWorkticket, hasPermission } from "lib/permissions";
import useStyles from "./styles";
import Button from "@material-ui/core/Button";
import SuccessDialog from "../../../../pages/workticketPage/dialog/successDialog";
import LoadingDialog from "../../../ui/dialog/loadingDialog";
import FormInput from "../../../ui/FormContent/formInput";
import FormSelectAuto from "../../../ui/FormContent/formSelectAuto";
import { LunaLensOverrideIcon } from "../icons/LunaLensOverrideIcon";

const certifiedToStandard = [
  {
    value: 1,
    label: "Verified",
  },
  {
    value: 2,
    label: "Recalled",
  },
  {
    value: 3,
    label: "Cancelled",
  },
];
const feedbackReasons = [
  {
    value: 1,
    label: "Incorrect",
  },
  {
    value: 2,
    label: "Not needed",
  },
];
const LunaLensOverride = (props) => {
  const initialData = {
    first_name: "",
    last_name: "",
    title: "",
    comment: "",
  };
  const buttonText = "Override";
  const classes = useStyles();
  const { mainClasses, workticketId } = props;
  const [open, setOpen] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const { globalUi } = useContext(GlobalUiContext);
  const { permissions } = globalUi;

  const handleQuote = () => {
    setOpen(true);
  };
  const [isLoadingDialog, setIsLoadingDialog] = useState(false);

  const dialogTitle = `${workticketId}`;
  const [isSuccessDialogOpen, setIsSuccessDialogOpen] = useState(false);
  const [data, setData] = useState(initialData);
  const [selectedReasonIds, setSelectedReasonIds] = useState([]);
  const [errors, setErrors] = useState({});
  const [selectedOverrideStatus, setSelectedOverrideStatus] = useState([]);
  const handleOverrideChange = (event, newValue) => {
    setSelectedOverrideStatus(newValue);
    setErrors((prev) => ({ ...prev, reason: false }));
  };
  const handleSuccessClose = () => {
    setIsSuccessDialogOpen(false);
  };
  const handleReasonChange = (event, newValue) => {
    setSelectedReasonIds(newValue);
    setErrors((prev) => ({ ...prev, reason: false }));
  };
  const handleBlur = async (event) => {
    data[event.name] = event.value;
    setData({ ...data });
  };
  return hasPermission(permissionWorkticket.REQUESTS_QUOTE, permissions) ? (
    <>
      <Button
        startIcon={<LunaLensOverrideIcon />}
        className={mainClasses.buttonHeaderMoreContext}
        onClick={() => {
          setOpenDialog(true);
          // setCategorySelected(!categorySelected);
          // handleCategoryContext(categoryValue);
        }}
      >
        Override
      </Button>
      {/*<ButtonBase*/}
      {/*  variant="outlined"*/}
      {/*  color="secondary"*/}
      {/*  onClick={() => setOpenDialog(true)}*/}
      {/*  className={classes.root}*/}
      {/*  // disabled={parseInt(row.status) !== 1}*/}
      {/*>*/}
      {/*  Override*/}
      {/*</ButtonBase>*/}
      {openDialog ? (
        <Dialog
          fullWidth={true}
          maxWidth="sm"
          open={openDialog}
          onClose={() => setOpenDialog(false)}
        >
          <DialogContent>
            <DialogCloseIcon handleClose={() => setOpenDialog(false)} />
            <DialogTitle title={dialogTitle} icon={<TicketIcon />} />
            <Box className={mainClasses.wrapperDialog}>
              <Typography className={mainClasses.titleInfoText} variant="body2">
                In order to override Luna’s decision you have to specify the
                reason and leave a note with any relevant information.
              </Typography>
            </Box>
            <Box className={mainClasses.formContainer}></Box>
            <FormSelectAuto
              name="status"
              label="Status it will be moved to*"
              placeholder="Select Status"
              options={certifiedToStandard}
              handleChange={handleOverrideChange}
              value={selectedOverrideStatus}
            />
            <FormSelectAuto
              name="reasons"
              label="Reason*"
              placeholder="Select Reasons"
              options={feedbackReasons}
              handleChange={handleReasonChange}
              value={selectedReasonIds}
            />
            <Box className={mainClasses.additionalContextContainer}>
              <FormInput
                rows={7}
                multiline={true}
                gridSizes={[{ size: "md", val: 12 }]}
                name="comment"
                label="Additional Context"
                placeholder="Pleae provide additional context"
                value={data.comment}
                handleBlur={handleBlur}
              />
            </Box>
          </DialogContent>
          <DialogActions className={mainClasses.wrapperDialogActionRating}>
            <Button
              onClick={() => setOpenDialog(false)}
              className={mainClasses.buttonOutlineCancel}
              variant="outlined"
              size="large"
              color="primary"
            >
              Cancel
            </Button>
            <Button
              // onClick={handleConfirm}
              className={mainClasses.buttonOverrideLuna}
              color="primary"
              variant="contained"
              size="large"
              // disabled={isAcceptDisabled}
            >
              {buttonText}
            </Button>
          </DialogActions>
        </Dialog>
      ) : null}
      <SuccessDialog
        title={"Luna Override"}
        message={"Luna was successfully overridden"}
        open={isSuccessDialogOpen}
        handleClose={handleSuccessClose}
      />
      <LoadingDialog open={isLoadingDialog} />
    </>
  ) : null;
};

export default LunaLensOverride;
