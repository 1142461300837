import { useWorkticketView } from "../../contexts/workticketViewContext";
import Skeleton from "@material-ui/lab/Skeleton";
import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import { ButtonBase } from "../../components/ui/core/button";
import Box from "@material-ui/core/Box";
import useStyles from "./styles";
import Typography from "@material-ui/core/Typography";
import { ThumbsUpBigIcon } from "./icon/thumbsUpBigIcon";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import { RatingDialogSV } from "./RatingDialogSV";
import { ThumbsDown } from "./icon/thumbsDown";
import { ThumbsUp } from "./icon/thumbsUp";
import { TwoThumbsUp } from "./icon/twoThumbsUp";
import { WorkticketSVRatingItem } from "./WorkticketSVRatingItem";
import { useDialogSV } from "./WorkticketSVRatingDialogContext";
import ConfirmDialog from "../../components/ui/dialog/confirmDialog";
import SuccessDialog from "./dialog/successDialog";
import LoadingDialog from "../../components/ui/dialog/loadingDialog";
import {
  deleteRatingSV,
  getSVWorkticketFeedbackreasons,
  getSVWorkticketRatings,
} from "../../services/lunaLensService";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { TwoThumbsUpBigIcon } from "./icon/TwoThumbsUpBigIcon";
import { ThumbsDownBigIcon } from "./icon/ThumbsDownBigIcon";
import { logException } from "../../components/util/logUtil";
import ErrorDialog from "../../components/ui/dialog/errorDialog";

export function WorkticketSVRating({ deletePerms, modifyPerms }) {
  const classes = useStyles();
  const [isSuccessDialogOpen, setIsSuccessDialogOpen] = useState(false);
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
  const [dialogSVAction, dispatchDialogSVAction] = useDialogSV();
  const { isModifyRatingOpen, isDeleteUserDialogOpen, ratingToDelete } =
    dialogSVAction;
  const [stateContext, dispatchContext] = useWorkticketView();
  const { workticket, isLoading } = stateContext ?? null;
  const [selectedRating, setSelectedRating] = useState(4);
  const [activeRatingId, setActiveRatingId] = useState(4);
  const [isOpenRating, setIsOpenRating] = useState(false);
  const [isDeleteRating, setIsDeleteRating] = useState(false);
  const [isEditRating, setIsEditRating] = useState(false);
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [isLoadingDialog, setIsLoadingDialog] = useState(false);
  const svRatingsQuery = useQuery(
    ["SVRatings", workticket?.id],
    () => getSVWorkticketRatings(workticket?.id),
    {
      enabled: workticket?.id !== 0 && workticket?.id !== undefined,
      staleTime: 4 * (60 * 1000),
      cacheTime: 6 * (60 * 1000),
    }
  );
  const active_rating_id = 1;
  const [feedbackReasons, setFeedbackReasons] = useState([]);
  const {
    data: svRatingsFeedbackreasons,
    isLoading: areSVRatingReasonsLoading,
    refetch: refetchSVRatingReasons,
    isRefetching: isRefetchingSVRatingReasons,
  } = useQuery(
    ["SVRatingsFeedbackreasons", selectedRating],
    () => getSVWorkticketFeedbackreasons({ selectedRating, activeRatingId }),
    {
      onSuccess: (data) => {
        setFeedbackReasons(
          data?.data?.ratingReasons?.map((reason) => ({
            value: reason.id,
            label: reason.reason,
          }))
        );
      },
      enabled: workticket?.id !== 0 && activeRatingId !== 4,
    }
  );
  const svRatingsData = svRatingsQuery.data;
  const handleSuccessClose = () => {
    dispatchDialogSVAction({
      type: "TOGGLE_DELETE_USER_DIALOG",
      open: false,
    });
    setIsSuccessDialogOpen(false);
  };
  const clickRating = (value) => {
    if (value === selectedRating) {
      setSelectedRating(4);
      setActiveRatingId(4);
      return;
    }
    setSelectedRating(value);
    refetchSVRatingReasons();
  };

  const queryClient = useQueryClient();
  useEffect(() => {
    if (!isOpenRating) {
      setTimeout(() => {
        setIsEditRating(false);
      }, 300);
    }
  }, [isOpenRating]);
  const handleClose = () => {
    setMenuAnchorEl(null);
  };
  const handleConfirmClose = () => {
    dispatchDialogSVAction({
      type: "TOGGLE_DELETE_USER_DIALOG",
      open: false,
    });
    setIsConfirmDialogOpen(false);
  };
  const handleConfirm = async () => {
    dispatchDialogSVAction({
      type: "TOGGLE_DELETE_USER_DIALOG",
      open: false,
    });
    setIsLoadingDialog(true);
    deletingRatingMutation.mutate(ratingToDelete);
  };
  const [isDeleteError, setIsDeleteError] = useState(false);
  const deletingRatingMutation = useMutation({
    mutationFn: (data) => deleteRatingSV(data),
    onSuccess: () => {
      queryClient.invalidateQueries(["SVRatings"]);
      setTimeout(() => {
        setIsLoadingDialog(false);
        setIsSuccessDialogOpen(true);
      }, 1000);
    },
    onError: (response) => {
      logException(response, "Error rating workticket");
      setIsLoadingDialog(false);
      setIsDeleteError(true);
    },
  });
  const handleOpenRating = () => {
    setIsOpenRating(true);
    handleClose();
    dispatchDialogSVAction({
      type: "TOGGLE_MODIFY_RATING",
      open: true,
    });
  };
  const handleCloseRating = (wt) => {
    dispatchDialogSVAction({
      type: "TOGGLE_MODIFY_RATING",
      open: false,
    });
    setIsOpenRating(false);
  };
  const handleDeleteRating = () => {
    setIsDeleteRating(true);
    handleClose();
  };
  const [hoveredIndex, setHoveredIndex] = useState(null);
  let menuItems = [
    {
      icon: <EditIcon fontSize="inherit" />,
      text: "Modify Rating",
      onClick: () => {
        setIsEditRating(true);
        handleOpenRating();
        handleClose();
      },
      iconClassName: classes.menuIconEdit,
      iconHoveredClassName: classes.menuIconEditHovered,
    },
    {
      icon: <DeleteIcon fontSize="inherit" />,
      text: "Delete",
      onClick: () => handleDeleteRating(),
      iconClassName: classes.menuIconDelete,
      iconHoveredClassName: classes.menuIconDeleteHover,
    },
  ];
  useEffect(() => {
    if (svRatingsData?.data?.ratings[0]?.rating_value) {
      setSelectedRating(svRatingsData?.data?.ratings[0]?.rating_value);
      setActiveRatingId(svRatingsData?.data?.ratings[0]?.id);
    }
  }, [svRatingsData]);

  if (isLoading || svRatingsQuery.isLoading) {
    return <Skeleton animation="wave" variant="rect" height={50} />;
  }
  const ratingReviewText = {
    0: {
      ratingText: "Service did not meet the standard",
    },
    1: {
      ratingText: "Service met the expected standard",
    },
    2: {
      ratingText: "Service exceeded the expected standard",
    },
    4: {
      ratingText: "Workticket Luna lens.review default",
    },
  };
  const activeRating = svRatingsData?.data?.ratings[0]?.rating_value;
  const ratingValue =
    svRatingsData?.data?.ratings[0]?.rating_value ?? "default";
  const ratingText =
    ratingReviewText[ratingValue]?.ratingText ?? ratingReviewText[4].ratingText;
  const reviewNumbers = svRatingsData?.data?.ratings?.length;
  const reviewSTR = reviewNumbers > 1 ? "reviews" : "review";
  const svRatings = svRatingsData?.data?.ratings;
  const serviceType = workticket?.service_type;
  return (
    <>
      {serviceType === "1" && (
        <Box className={classes.containerRatingSV}>
          <Typography className={classes.ratingSVTitle}>Rating</Typography>
          <Box className={classes.textAndIconsBigContainer}>
            <>
              {svRatings?.length === 0 && (
                <Box className={classes.ratingIconsContainer}>
                  <Box
                    onClick={() => {
                      if (svRatings?.length === 0) {
                        return;
                      }
                      clickRating("0");
                    }}
                    className={
                      svRatings?.length === 0
                        ? classes.ratingIconContainerDidNotMeetExpectationsNoHover
                        : selectedRating === "0"
                        ? classes.ratingIconContainerDidNotMeetExpectationsActive
                        : classes.ratingIconContainerDidNotMeetExpectations
                    }
                  >
                    <ThumbsDown />
                  </Box>
                  <Box
                    onClick={() => {
                      if (svRatings?.length === 0) {
                        return;
                      }
                      clickRating("1");
                    }}
                    className={
                      svRatings?.length === 0
                        ? classes.ratingIconContainerMetExpectationsNoHover
                        : selectedRating === "1"
                        ? classes.ratingIconContainerMetExpectationsActive
                        : classes.ratingIconContainerMetExpectations
                    }
                  >
                    <ThumbsUp />
                  </Box>
                  <Box
                    onClick={() => {
                      if (svRatings?.length === 0) {
                        return;
                      }
                      clickRating("2");
                    }}
                    className={
                      svRatings?.length === 0
                        ? classes.ratingIconContainerExceededExpectationsNoHover
                        : selectedRating === "2"
                        ? classes.ratingIconContainerExceededExpectationsActive
                        : classes.ratingIconContainerExceededExpectations
                    }
                  >
                    <TwoThumbsUp />
                  </Box>
                </Box>
              )}
            </>
            {svRatings?.length === 0 && (
              <Typography className={classes.notRatedYetText}>
                Service haven’t been rated yet
              </Typography>
            )}
          </Box>
          {svRatings?.length > 0 && (
            <Box className={classes.svRatingInformationContainer}>
              {activeRating === "0" && <ThumbsDownBigIcon />}
              {activeRating === "1" && <ThumbsUpBigIcon />}
              {activeRating === "2" && <TwoThumbsUpBigIcon />}
              <Box className={classes.ratingNumberInfo}>
                <Typography className={classes.svServiceDescription}>
                  {ratingText}
                </Typography>
                <Typography className={classes.svNumberReviews}>
                  {reviewNumbers} {reviewSTR}
                </Typography>
              </Box>
            </Box>
          )}
          {svRatings?.length > 0 && (
            <Box className={classes.ratingItemsScrollContainer}>
              {svRatings?.map((ratingItem, index) => (
                <WorkticketSVRatingItem
                  classes={classes}
                  key={ratingItem.id}
                  ratingItem={ratingItem}
                  index={index}
                  deletePerms={deletePerms}
                  modifyPerms={modifyPerms}
                />
              ))}
            </Box>
          )}
          {svRatings?.length > 0 && (
            <Grid container spacing={2}>
              <Grid item sm={12} xs={12}>
                <ButtonBase
                  // color="primary"
                  variant="contained"
                  disableRipple
                  fullWidth={true}
                  className={classes.buttonRatingSV}
                  onClick={handleOpenRating}
                  disableElevation
                >
                  Modify Rating
                </ButtonBase>
              </Grid>
            </Grid>
          )}
        </Box>
      )}
      <Menu
        id="simple-menu"
        anchorEl={menuAnchorEl}
        keepMounted
        open={Boolean(menuAnchorEl)}
        onClose={handleClose}
        PaperProps={{
          style: {
            borderRadius: 8,

            boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)",
          },
        }}
      >
        {menuItems.map((item, index) => (
          <MenuItem
            key={index}
            onClick={item.onClick}
            onMouseEnter={() => setHoveredIndex(index)}
            onMouseLeave={() => setHoveredIndex(null)}
            className={classes.menuItem}
          >
            <Box
              className={
                hoveredIndex === index
                  ? item.iconHoveredClassName
                  : item.iconClassName
              }
            >
              {item.icon}
            </Box>
            <Typography variant="caption" className={classes.menuText}>
              {item.text}
            </Typography>
          </MenuItem>
        ))}
      </Menu>
      <RatingDialogSV
        givenRating={selectedRating}
        active_rating_id={activeRating}
        open={isModifyRatingOpen}
        handleClose={handleCloseRating}
        isEditingRating={isEditRating}
        feedbackReasons={feedbackReasons}
        workticketId={workticket?.id}
        changeSelectedRating={setSelectedRating}
        feedbackLoading={areSVRatingReasonsLoading}
        isRefetchingSVRatingReasons={isRefetchingSVRatingReasons}
      />
      <ConfirmDialog
        open={isDeleteUserDialogOpen}
        handleClose={handleConfirmClose}
        message="Are you sure you want to delete this rating?"
        title="Delete Rating"
        handleConfirm={handleConfirm}
      />
      <SuccessDialog
        title={"Rating Deleted"}
        message={"Rating Deleted Successfully"}
        open={isSuccessDialogOpen}
        handleClose={handleSuccessClose}
      />
      <ErrorDialog
        title={"Error"}
        message={
          "An error occurred while deleting the rating. Please try again later."
        }
        handleClose={() => {
          setIsDeleteError(false);
        }}
        open={isDeleteError}
      />
      <LoadingDialog open={isLoadingDialog} />
    </>
  );
}
