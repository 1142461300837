import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import useStyles from "./styles";
import { DialogCloseIcon } from "../../../components/ui/core/dialogCloseIcon";
import { DialogTitle } from "../../../components/ui/core/dialogTitle";

const SuccessDialogSVVerifyAgain = (props) => {
  const { open, title, message, handleClose, handleViewDetails } = props;
  const classes = useStyles();

  return (
    <Dialog
      open={open}
      aria-labelledby="dialog-title"
      aria-describedby="dialog-description"
      maxWidth={"md"}
      fullWidth={false}
    >
      <DialogContent className={classes.wrapperDialogSV}>
        <DialogCloseIcon handleClose={handleClose} />
        <DialogTitle title={title} icon={<CheckCircleIcon />} />
        <Box className={classes.wrapperDialogValidation}>
          <Typography variant="body2">{message}</Typography>
        </Box>
      </DialogContent>
      <DialogActions className={classes.wrapperDialogAction}>
        <Button
          onClick={handleClose}
          className={classes.buttonOutlinedDialogSV}
          variant="outlined"
          size="large"
          color="primary"
        >
          Back To List View
        </Button>
        <Button
          onClick={handleViewDetails}
          className={classes.buttonDialogSV}
          color="secondary"
          variant="contained"
          disabled={true}
          size="large"
        >
          Open Insight Reports
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SuccessDialogSVVerifyAgain;
