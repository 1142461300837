import React from "react";
import classNames from "classnames";
import Chip from "@material-ui/core/Chip";
import PauseIcon from "@material-ui/icons/PauseCircleFilled";
import PlayIcon from "@material-ui/icons/PlayCircleFilled";
import DoneIcon from "@material-ui/icons/CheckCircle";
import CancelIcon from "@material-ui/icons/Cancel";
import ErrorIcon from "@material-ui/icons/Error";
import ArchivedIcon from "@material-ui/icons/Archive";
import ClockIcon from "@material-ui/icons/WatchLater";

import { workticketsStatusOptions } from "constants.js";
import { DaysFromNowStandard } from "components/util/timeFormat";
import useStyles from "./styles";

const WorkticketStatusChip = (props) => {
  const classes = useStyles();
  const {
    category,
    status,
    archived,
    dueDate,
    type,
    recalled,
    escalated,
    cancellation_reviewed,
  } = props;

  let statusSelected;
  let classChip;
  let iconChip;
  let labelChip;
  const dueTime = DaysFromNowStandard(dueDate);

  if (Number.isInteger(parseInt(status))) {
    statusSelected = workticketsStatusOptions.find(
      (statusItem) => statusItem.id === parseInt(status)
    );
  } else {
    statusSelected = { text: status };
  }

  if (!statusSelected) {
    return `Error status ${status}`;
  }

  if (archived) {
    classChip = classes.chipArchived;
    iconChip = <ArchivedIcon />;
    labelChip = "Archived";
  } else if (category === 4) {
    classChip = classes.chipEmergency;
    iconChip = <ErrorIcon />;
    labelChip = "Emergency";
  } else if (
    type === 4 &&
    (status < 2 || ["Not Started", "In Progress"].includes(statusSelected.text))
  ) {
    classChip = classes.chipCorrectiveAction;
    iconChip = <ErrorIcon />;
    labelChip = "Corrective Action";
  } else if (
    Number(escalated) === 1 &&
    (status < 2 || ["Not Started", "In Progress"].includes(statusSelected.text))
  ) {
    classChip = classes.chipEscalated;
    iconChip = <ErrorIcon />;
    labelChip = `Escalated ${recalled ? "- Recalled" : ""}`;
  } else if (
    Number(recalled) === 1 &&
    (status < 2 || ["Not Started", "In Progress"].includes(statusSelected.text))
  ) {
    classChip = classes.chipRecalled;
    iconChip = <ErrorIcon />;
    labelChip = "Recalled";
  } else if (
    dueTime > 1 &&
    (status < 2 || ["Not Started", "In Progress"].includes(statusSelected.text))
  ) {
    classChip = classes.chipOverdue;
    iconChip = <ErrorIcon />;
    labelChip = "Overdue";
  } else if (Number(status) === 3 && Number(cancellation_reviewed) === 0) {
    classChip = classes.chipCancelled;
    iconChip = <CancelIcon />;
    labelChip = "Pending Cancellation";
  } else if (Number(status) === 7) {
    classChip = classes.chipRecalled;
    iconChip = <ErrorIcon />;
    labelChip = "Pending Verification";
  } else {
    labelChip = statusSelected.text;
    switch (statusSelected.text) {
      case "Not Scheduled":
        classChip = classes.chipNotScheduled;
        iconChip = <PauseIcon />;
        break;
      case "Not Started":
        classChip = classes.chipNotStarted;
        iconChip = <PauseIcon />;
        break;
      case "In Progress":
        classChip = classes.chipInProgress;
        iconChip = <PlayIcon />;
        break;
      case "Verified":
        classChip = classes.chipDone;
        iconChip = <DoneIcon />;
        break;
      case "Completed":
        classChip = classes.chipCompleted;
        iconChip = <DoneIcon />;
        break;
      case "Cancelled":
        classChip = classes.chipCancelled;
        iconChip = <CancelIcon />;
        break;
      case "Postpone":
        classChip = classes.chipPostpone;
        iconChip = <CancelIcon />;
        break;
      case "Overdue":
        classChip = classes.chipOverdue;
        iconChip = <ErrorIcon />;
        break;
      case "Archived":
        classChip = classes.chipArchived;
        iconChip = <ArchivedIcon />;
        break;
      case "Request":
        classChip = classes.chipRequest;
        iconChip = <ClockIcon />;
        break;
      default:
        break;
    }
  }

  return (
    <Chip
      icon={iconChip}
      label={labelChip}
      className={classNames(classes.chip, classChip)}
    />
  );
};

export default WorkticketStatusChip;
