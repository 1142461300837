export function AttachFileIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="24" height="24" rx="3" fill="#E8F8CB" />
      <g clipPath="url(#clip0_629_10079)">
        <path
          d="M13.8483 8.72727L13.8483 15C13.8483 16.2055 12.872 17.1818 11.6665 17.1818C10.461 17.1818 9.48469 16.2055 9.48469 15L9.48469 8.18182C9.48469 7.42909 10.0956 6.81818 10.8483 6.81818C11.601 6.81818 12.212 7.42909 12.212 8.18182L12.212 13.9091C12.212 14.2091 11.9665 14.4545 11.6665 14.4545C11.3665 14.4545 11.121 14.2091 11.121 13.9091L11.121 8.72727H10.3029L10.3029 13.9091C10.3029 14.6618 10.9138 15.2727 11.6665 15.2727C12.4192 15.2727 13.0301 14.6618 13.0301 13.9091V8.18182C13.0301 6.97636 12.0538 6 10.8483 6C9.64287 6 8.6665 6.97636 8.6665 8.18182L8.6665 15C8.6665 16.6582 10.0083 18 11.6665 18C13.3247 18 14.6665 16.6582 14.6665 15L14.6665 8.72727H13.8483Z"
          fill="#328914"
        />
      </g>
      <defs>
        <clipPath id="clip0_629_10079">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(4 4)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
