import React, { useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import LoadingIndicator from "components/common/LoadingIndicator/loadingIndicator";
import { useTableDispatch, useTableState } from "contexts/tableContext";
import { useWorkticketAction } from "contexts/workticketActionContext";
import { DialogCloseIcon } from "../../../components/ui/core/dialogCloseIcon";
import { DialogTitle } from "../../../components/ui/core/dialogTitle";
import useStyles from "./styles";
import { bulkActionSV } from "../../../services/lunaLensService";
import { logException } from "../../../components/util/logUtil";
import SuccessDialogSVVerifyAgain from "./successDialogSVVerifyAgain";

const VerifyAgainBulkSV = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);
  const dispatchTable = useTableDispatch();
  const workticketAction = useWorkticketAction();
  const [state, dispatch] = workticketAction;
  const { openVerifyAgainBulk } = state;
  const classes = useStyles();
  const tableUi = useTableState();
  const { currentTab, selected, rows } = tableUi;

  const handleClose = () => {
    dispatch({
      type: "TOGGLE_VERIFY_AGAIN_BULK",
      open: false,
    });
  };

  const handleCloseSuccess = () => {
    setOpenSuccess(false);
  };

  const [selectedWorktickets, setSelectedWorktickets] = useState([]);
  const handleVerifyAgain = async () => {
    try {
      setIsLoading(true);
      setSelectedWorktickets(selected);
      await bulkActionSV({
        workticket_ids: selected,
        action: "3",
      });
      handleClose();
      setIsLoading(false);
      setOpenSuccess(true);
      dispatchTable({
        type: "SET_SELECTED",
        selected: [],
      });
    } catch (e) {
      setIsLoading(false);
      logException(e, "Cannot verify worktickets again");
    }
  };

  return (
    <>
      <Dialog
        open={openVerifyAgainBulk}
        onClose={handleClose}
        aria-labelledby="verify-again-dialog-title"
        aria-describedby="verify-again-dialog-description"
        maxWidth={"xs"}
        fullWidth={true}
      >
        {isLoading ? (
          <LoadingIndicator />
        ) : (
          <>
            <DialogContent className={classes.wrapperDialog}>
              <DialogCloseIcon handleClose={handleClose} />
              <DialogTitle title="Verify Again" />
              <Box className={classes.wrapperDialogValidation}>
                <Typography variant="body2">
                  Are you sure you want to verify {selected?.length} workticket
                  {selected?.length !== 1 ? "s" : ""} again?
                </Typography>
              </Box>
            </DialogContent>
            <DialogActions className={classes.wrapperDialogAction}>
              <Button
                onClick={handleClose}
                className={classes.buttonOutlinedDialog}
                variant="outlined"
                size="large"
                color="primary"
                disabled={isLoading}
              >
                Cancel
              </Button>
              <Button
                onClick={handleVerifyAgain}
                className={classes.buttonDialog}
                color="secondary"
                variant="contained"
                size="large"
                disabled={isLoading}
              >
                Verify Again
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>

      <SuccessDialogSVVerifyAgain
        open={openSuccess}
        title={"Success!"}
        message={`${selectedWorktickets?.length} workticket${
          selectedWorktickets?.length !== 1 ? "s" : ""
        } have been verified again.`}
        handleClose={handleCloseSuccess}
      />
    </>
  );
};

export default VerifyAgainBulkSV;
