export function ThumbsUpBigIcon() {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.5" y="0.5" width="39" height="39" rx="3.5" fill="#9CBB65" />
      <rect x="0.5" y="0.5" width="39" height="39" rx="3.5" stroke="#9CBB65" />
      <path
        d="M15.4928 26.5C15.0682 26.5 14.673 26.2635 14.5176 25.8684C14.1835 25.0187 14 24.0933 14 23.125C14 21.3759 14.5987 19.7667 15.6024 18.491C15.7533 18.2993 15.9746 18.1821 16.2021 18.094C16.675 17.9109 17.0925 17.5797 17.4141 17.1697C18.1873 16.1838 19.1617 15.3634 20.2755 14.7702C20.9977 14.3856 21.6243 13.8143 21.9281 13.0546C22.1408 12.5231 22.25 11.9559 22.25 11.3834V10.75C22.25 10.3358 22.5858 10 23 10C24.2426 10 25.25 11.0074 25.25 12.25C25.25 13.4016 24.9904 14.4926 24.5266 15.4677C24.261 16.026 24.6336 16.75 25.2519 16.75H28.3777C29.4044 16.75 30.3233 17.444 30.432 18.4649C30.4769 18.8871 30.5 19.3158 30.5 19.75C30.5 22.5976 29.5081 25.2136 27.851 27.2712C27.4634 27.7525 26.8642 28 26.2462 28H22.2302C21.7466 28 21.2661 27.922 20.8072 27.7691L17.6928 26.7309C17.2339 26.578 16.7534 26.5 16.2697 26.5H15.4928Z"
        fill="white"
      />
      <path
        d="M10.3315 18.7271C9.7948 20.0889 9.5 21.5725 9.5 23.125C9.5 24.3451 9.68208 25.5226 10.0206 26.632C10.2799 27.482 11.1042 28 11.9929 28H12.9007C13.3462 28 13.6214 27.5017 13.4242 27.1022C12.8325 25.9029 12.5 24.5528 12.5 23.125C12.5 21.4168 12.9759 19.8198 13.8023 18.4593C14.0473 18.0559 13.774 17.5 13.3021 17.5H12.2494C11.4173 17.5 10.6365 17.953 10.3315 18.7271Z"
        fill="white"
      />
    </svg>
  );
}
