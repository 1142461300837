import React from "react";
import { TableProvider } from "../../contexts/tableContext";
import VerificationStandardLayout from "./standardLayout";
import { VerificationStandardViewProvider } from "contexts/verificationStandardViewContext";
import { VerificationStandardProvider } from "contexts/verificationStandardContext";

const VerificationStandard = (props) => {
  const id = props.match.params.id;
  const mode = props.match.params.mode ?? (id === "new" ? "new" : "view");

  return (
    <>
      <TableProvider>
        <VerificationStandardProvider>
          <VerificationStandardViewProvider>
            <VerificationStandardLayout id={id} mode={mode} />
          </VerificationStandardViewProvider>
        </VerificationStandardProvider>
      </TableProvider>
    </>
  );
};

export default VerificationStandard;
