import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  wrapperDialog: {
    textAlign: "center",
  },
  wrapperDialogClose: {
    position: "absolute",
    right: 5,
    top: 5,
  },
  iconDialog: {
    color: "#747474",
    width: 44,
    height: 44,
    marginBottom: 5,
  },
  iconDialog2: {
    color: "#747474",
    width: 32,
    height: 32,
    marginBottom: 5,
  },
  iconSuccessDialog: {
    color: "#328914",
    width: 44,
    height: 44,
    marginBottom: 5,
  },
  iconErrorDialog: {
    color: "#F07A8C",
    width: 44,
    height: 44,
    marginBottom: 5,
  },
  iconsDialogClose: {
    color: "#979797",
    width: 20,
    height: 20,
  },
  titleDialog: {
    fontSize: 16,
    fontWeight: "bold",
    marginBottom: 5,
  },
  contentDialog: {
    fontSize: 14,
  },
  wrapperDialogAction2: {
    textAlign: "right",
    justifyContent: "right !important",
    marginRight: 46,
    marginBottom: 20,
  },
  wrapperDialogAction3: {
    marginTop: 16,
    display: "flex",
    justifyContent: "center !important",
    alignItems: "center",
    flexDirection: "row",
    textAlign: "center",
    marginBottom: 20,
    height: 40,
  },
  button: {
    textTransform: "Capitalize",
    paddingLeft: 50,
    paddingRight: 50,
    marginLeft: 10,
    fontWeight: "bold",
    backgroundColor: "#4f98bc",
    "&:hover": {
      backgroundColor: "#4f98bc",
    },
  },
  buttonSV: {
    textTransform: "Capitalize",
    fontWeight: "bold",
    backgroundColor: "#4f98bc",
    "&:hover": {
      backgroundColor: "#4f98bc",
    },
  },
  buttonOutlined: {
    textTransform: "Capitalize",
    // paddingLeft: 50,
    // paddingRight: 50,
    color: "#4f98bc",
    marginRight: 40,
  },
  formContainer: {
    paddingLeft: 50,
    paddingRight: 50,
    paddingTop: 20,
    paddingBottom: 20,
    textAlign: "left",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 20,
      paddingRight: 20,
    },
  },
  formContainer2: {
    paddingLeft: 30,
    paddingRight: 30,
    paddingTop: 20,
    paddingBottom: 0,
    textAlign: "left",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 10,
      paddingRight: 10,
    },
  },
  checkbox: {
    color: "#ececec",
  },
  labelCheckbox: {
    "& > span.MuiTypography-root": {
      color: "#4d4d4d",
      fontSize: 12,
    },
  },
  alertContainer: {
    border: "none",
    marginBottom: theme.spacing(1),
  },
  titleWarningDialog: {
    fontSize: 16,
  },
  dialogBodyContainer: {
    marginTop: 8,
    "& p": {
      fontSize: 14,
      marginBottom: 4,
    },
  },
  dialogListContainer: {
    marginTop: 8,
    marginBottom: 8,
    "& p": {
      fontWeight: "bold",
    },
  },
  headerContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    paddingTop: 24,
    paddingBottom: 16,
  },
  titleContainer: {
    marginBottom: 16,
  },
  inputNewComment: {
    border: "1px solid #dfdfdf",
  },
  listItemComment: {
    backgroundColor: "#ececec",
    alignItems: "flex-start",
  },
  containerComments: {
    marginTop: 20,
    position: "relative",
  },
  inputEditComment: {
    border: "1px solid #dfdfdf",
    padding: "10px !important",
    width: "98%",
  },
  buttonOutlinedComment: {
    marginRight: theme.spacing(1),
  },
  listContainer: {
    [theme.breakpoints.down("sm")]: {
      paddingRight: "16px !important",
    },
  },
  avatarComment: {
    fontSize: 14,
    color: "#747474",
    fontWeight: "bold",
    backgroundColor: "#dfdfdf",
  },
  listName: {
    fontWeight: "bold",
    marginRight: 20,
    [theme.breakpoints.down("sm")]: {
      clear: "both",
      display: "block",
      width: "100%",
    },
  },
  listText: {
    paddingRight: 50,
    [theme.breakpoints.down("sm")]: {
      clear: "both",
      display: "block",
      width: "100%",
      paddingRight: 0,
    },
  },
  listAction: {
    fontWeight: "bold",
    [theme.breakpoints.down("sm")]: {
      clear: "both",
      position: "relative !important",
      textAlign: "right",
    },
  },
  boxActionsComment: {
    marginTop: 10,
    textAlign: "right",
    marginRight: 8,
  },
  containerTabs: {
    display: "flex",
    flexDirection: "row",
    gap: 12,
  },
  buttonTabActive: {
    backgroundColor: "#4f98bc !important",
    color: "#FFFFFF !important",
  },
  buttonTab: {
    display: "flex",
    alignItems: "center",
    textTransform: "Capitalize",
    verticalAlign: "middle",
    fontWeight: "bold",
    fontSize: 12,
    minWidth: 100,
    padding: 8,
    backgroundColor: "#EDF5F8",
    color: "#4F98BC",
    borderRadius: 4,
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#c2d6e1",
    },
  },
  containerSubTabs: {
    borderBottom: "2px solid #ECECEC",
  },
  activityTab: {
    padding: 0,
    margin: 0,
    marginRight: "20px !important",
    textTransform: "capitalize",
    fontSize: 14,
    minWidth: "100px !important",
    "&.Mui-selected": {
      color: "#4f98bc",
      fontWeight: "bold",
      borderBottom: "2px solid",
    },
    "&.Mui-flexContainer": {
      borderBottom: "2px solid #ECECEC",
    },
    "& > span": {
      fontSize: 14,
    },
  },
  containerFooter: {
    display: "flex",
    flexDirection: "column",
    paddingLeft: 30,
    paddingRight: 40,
    paddingBottom: 20,
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 10,
      paddingRight: 20,
    },
  },

  containerInputComment: {
    width: "100%",
    marginBottom: theme.spacing(2),
    paddingLeft: 25,
    paddingRight: 30,
  },

  buttonContainer: {
    display: "flex",
    width: "100%",
    justifyContent: "flex-end",
    paddingLeft: 25,
    paddingRight: 35,
    paddingTop: 20,
  },
  label: {
    color: "#747474",
    fontSize: 12,
    fontStyle: "normalize",
    fontWeight: 600,
    marginBottom: 5,
  },
  listItem: {
    whiteSpace: "normal",
    wordBreak: "break-word",
    // paddingBottom: 10,
    "& > div:first-child": {
      minWidth: "32px !important",
    },
    "& > div > span": {
      fontSize: 14,
    },
  },
  noUsersFound: {
    display: "flex",
    justifyContent: "left",
    alignItems: "left",
    color: "#747474",
    fontSize: 12,
    fontWeight: 600,
    paddingBottom: theme.spacing(2),
  },
  popoverPaper: {
    maxWidth: "100%",
    overflow: "auto",
  },
  errorText: {
    color: "red",
    fontSize: 12,
    marginTop: 5,
  },
  containerLabelTooltip: {
    display: "flex",
    alignItems: "center",
  },
  iconContainer: {
    marginLeft: theme.spacing(1),
    cursor: "pointer",
  },
  icon: {
    fontSize: "16px",
    cursor: "pointer",
    paddingLeft: 5,
  },
  listContainerItems: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    maxHeight: 250,
    overflow: "auto",
  },
  buttonDropdown: {
    textTransform: "none",
    justifyContent: "space-between",
    minHeight: 41,
  },
  buttonDialog: {
    textTransform: "Capitalize",
    paddingLeft: 50,
    paddingRight: 50,
    marginLeft: 10,
    fontWeight: "bold",
    backgroundColor: "#4f98bc",
    "&:hover": {
      backgroundColor: "#4f98bc",
    },
  },
  buttonOutlinedDialogSV: {
    textTransform: "Capitalize",
    color: "#4f98bc",
    marginRight: 24,
    marginLeft: 10,
  },
  buttonOutlinedDialog: {
    textTransform: "Capitalize",
    paddingLeft: 50,
    paddingRight: 50,
    color: "#4f98bc",
    marginRight: 40,
  },
  root: {
    marginRight: 8,
    maxHeight: 30,
    minWidth: 110,
  },
  wrapperDialogAction: {
    textAlign: "center",
    justifyContent: "center !important",
    marginBottom: 20,
  },
  wrapperDialogSV: {
    textAlign: "center",
  },
  buttonDialogSV: {
    textTransform: "Capitalize",
    fontWeight: "bold",
    marginRight: 10,
    backgroundColor: "#4f98bc",
    "&:hover": {
      backgroundColor: "#4f98bc",
    },
  },
}));

export default useStyles;
