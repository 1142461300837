import React, { useState, useEffect } from "react";
import { v4 as uuidv4 } from "uuid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import FolderIcon from "assets/icons/folderIcon";
import ProgressBar from "./progressBar";
import StandardsAccordion from "./standardAccordion";
import MessageDialog from "./dialog/messageDialog";
import FeedbackDialog from "./dialog/feedbackDialog";
import { useVerificationStandardView } from "contexts/verificationStandardViewContext";
import {
  dispatchStandardExecutionById,
  dispatchStandardExecution,
  checkStandardStatusByStandardIdAndExecutionId,
  checkStandardStatus,
} from "services/verificationStandardService";
import useStyles from "./styles";

const fieldNames = {
  standardName: "Standard Name",
  customerId: "Customer",
  industryId: "Industry",
  tradeId: "Trade",
  serviceCategoryId: "Service Category",
  jobs: "Jobs",
};

const PROGRESS_STATUS = {
  PENDING: 0,
  COMPLETED: 1,
  FAILED: 2,
};

const MODES = {
  EDIT: "edit",
  DUPLICATE: "duplicate",
  VIEW: "view",
};

const addIdsToItems = (items) => {
  return Object.keys(items).map((key) => ({
    ...items[key],
    id: uuidv4(),
  }));
};

const formatJobs = (jobs) => {
  if (!Array.isArray(jobs)) return [String(jobs || "")];

  if (jobs.every((job) => typeof job === "string")) return jobs;

  return jobs.map((job) => String(job?.id || ""));
};

const StandardsDefinition = (props) => {
  const { mode } = props;
  const classes = useStyles();
  const [state, dispatch] = useVerificationStandardView();
  const { isTextAreaVisible, standardDefinition, validationErrors } = state;
  const [isMessageDialogOpen, setIsMessageDialogOpen] = useState(false);
  const [analysisStarted, setAnalysisStarted] = useState(false);
  const [isAnalysisCompleted, setIsAnalysisCompleted] = useState(false);
  const [isAnalysisLoading, setIsAnalysisLoading] = useState(false);
  const [verificationStandardId, setVerificationStandardId] = useState(null);
  const [standardExecutionId, setStandardExecutionId] = useState(null);
  const [progressStatus, setProgressStatus] = useState(0);
  const [errorMessage, setErrorMessage] = useState("");
  const [inputText, setInputText] = useState("");

  const isViewMode = mode === MODES.VIEW;
  const user = JSON.parse(localStorage.getItem("user"));

  useEffect(() => {
    if (!isTextAreaVisible) {
      handleClearClick();
    }
    // eslint-disable-next-line
  }, [isTextAreaVisible]);

  const handleStartClick = () => {
    dispatch({ type: "TOGGLE_TEXT_AREA" });
  };

  const handleTextChange = (event) => {
    setInputText(event.target.value);
  };

  const handleClearClick = () => {
    setInputText("");
    setIsAnalysisCompleted(false);
    setIsAnalysisLoading(false);
    setAnalysisStarted(false);
    setStandardExecutionId(null);
    setProgressStatus(PROGRESS_STATUS.PENDING);

    dispatch({ type: "RESET_STANDARD_DEFINITION" });
  };

  const validateRequiredFields = () => {
    const errorMessages = Object.entries(validationErrors)
      .filter(([error]) => error)
      .map(([field]) => fieldNames[field])
      .join(", ");

    if (errorMessages) {
      setErrorMessage("Missing required fields: " + errorMessages);
      setIsMessageDialogOpen(true);
      return false;
    }

    return true;
  };

  /**
   * Prepare the data required to start the analysis of a standard verification.
   * @returns {Object} The data to be sent to the API.
   */
  const buildAnalysisData = () => {
    const {
      standardName,
      customerId,
      serviceCategoryId,
      jobs,
      industryId,
      tradeId,
      areaOfBuildingId,
    } = state.standardDetails;

    return {
      standards_input: inputText,
      standard_name: standardName,
      customer_id: customerId,
      service_category_id: Number(serviceCategoryId),
      jobs: formatJobs(jobs),
      industry_id: Number(industryId),
      trade_id: Number(tradeId),
      area_building_id: Number(areaOfBuildingId) || null,
    };
  };

  /**
   * Initiates the execution of the verification standard analysis process.
   * Handles field validation, data preparation, and state updates.
   */
  const handleAnalyzeText = async () => {
    if (!validateRequiredFields()) {
      return;
    }

    setIsAnalysisLoading(true);
    setIsAnalysisCompleted(false);
    setProgressStatus(PROGRESS_STATUS.PENDING);

    try {
      const data = buildAnalysisData();

      const response = [MODES.EDIT, MODES.DUPLICATE].includes(mode)
        ? await dispatchStandardExecutionById(standardDefinition.id, data)
        : await dispatchStandardExecution(data);

      if (response?.data) {
        const { verificationStandardId, execution_key } = response.data;

        setVerificationStandardId(verificationStandardId);
        setStandardExecutionId(execution_key);
        setAnalysisStarted(true);
      } else {
        throw new Error("Invalid response data");
      }
    } catch (error) {
      setProgressStatus(PROGRESS_STATUS.FAILED);
      setAnalysisStarted(false);
      console.error("Error starting analysis:", error);
    }
  };

  /**
   * Periodically checks the status of a verification standard analysis
   * and updates the context state and UI accordingly.
   *
   * @function checkStatusAnalysis
   * @async
   * @returns {void}
   */
  useEffect(() => {
    let intervalId;

    const checkStatusAnalysis = async () => {
      if (!analysisStarted || !verificationStandardId) return;

      try {
        const requestData = {
          verification_standard_id: verificationStandardId,
        };

        if ([MODES.EDIT, MODES.DUPLICATE].includes(mode)) {
          requestData.execution_key = standardExecutionId;
        }

        const response = [MODES.EDIT, MODES.DUPLICATE].includes(mode)
          ? await checkStandardStatusByStandardIdAndExecutionId(requestData)
          : await checkStandardStatus(requestData);

        const status = [MODES.EDIT, MODES.DUPLICATE].includes(mode)
          ? response.data.standardDetail.standard.status
          : response.data.standardDetail.status;

        const { standardDetail } = response.data;

        switch (Number(status)) {
          case PROGRESS_STATUS.COMPLETED:
            setProgressStatus(PROGRESS_STATUS.COMPLETED);

            const payload = {};
            if ([MODES.EDIT, MODES.DUPLICATE].includes(mode)) {
              payload.id = standardDetail.standard.id;
              payload.name = standardDetail.standard.name;
              payload.status = standardDetail.standard.status;
              payload.jobBelowStandard = addIdsToItems(
                standardDetail.below_standard
              );
              payload.jobMeetStandard = addIdsToItems(
                standardDetail.meets_standard
              );
              payload.jobExceedStandard = addIdsToItems(
                standardDetail.exceeds_standard
              );
              payload.run_id = standardDetail.run_id;
            } else {
              payload.id = standardDetail.id;
              payload.name = standardDetail.name;
              payload.status = standardDetail.status;
              payload.started_at = standardDetail.started_at || "";
              payload.saved = standardDetail.saved || "";
              payload.jobBelowStandard = standardDetail.job_below_standard;
              payload.jobMeetStandard = standardDetail.job_meet_standard;
              payload.jobExceedStandard = standardDetail.job_exceed_standard;
              payload.verificationStandardJob =
                standardDetail.verification_standard_job || [];
              payload.run_id = standardDetail.run_id;
            }

            setTimeout(() => {
              if (!intervalId) return;

              setAnalysisStarted(false);
              setIsAnalysisCompleted(true);
              setIsAnalysisLoading(false);
              dispatch({
                type: "SET_STANDARD_DEFINITION",
                payload: payload,
              });
              clearInterval(intervalId);
            }, 1000);
            break;

          case PROGRESS_STATUS.FAILED:
            setProgressStatus(PROGRESS_STATUS.FAILED);
            setAnalysisStarted(false);
            clearInterval(intervalId);
            break;

          default:
            setProgressStatus(PROGRESS_STATUS.PENDING);
            break;
        }
      } catch (error) {
        setProgressStatus(PROGRESS_STATUS.FAILED);
        setAnalysisStarted(false);
        clearInterval(intervalId);
      }
    };

    if (analysisStarted) {
      checkStatusAnalysis();
      intervalId = setInterval(checkStatusAnalysis, 5000);
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [
    analysisStarted,
    verificationStandardId,
    standardExecutionId,
    dispatch,
    mode,
  ]);

  const handleCloseSuccessDialog = () => {
    setIsMessageDialogOpen(false);
  };

  if (isViewMode) {
    return (
      <Box>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <StandardsAccordion mode={mode} />
          </Grid>
        </Grid>
      </Box>
    );
  }

  return (
    <Box>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          {!isTextAreaVisible && (
            <Box className={classes.imageContainer}>
              <FolderIcon className={classes.icon} />
              <Typography variant="h6" className={classes.imageText}>
                Create New Set of Standards
              </Typography>
              <Typography
                variant="body2"
                color="textSecondary"
                className={classes.imageDescription}
              >
                Luna will analyze and summarize the text you input here and will
                come up with a structured answer with the standards for this
                customer.
              </Typography>
              <Button
                size="small"
                variant="contained"
                color="secondary"
                className={classes.buttonSecondary}
                onClick={handleStartClick}
              >
                Start
              </Button>
            </Box>
          )}
          {isTextAreaVisible && (
            <Box className={classes.definitionStandardContainer}>
              <TextareaAutosize
                minRows={2}
                maxRows={20}
                style={{
                  width: "100%",
                  padding: "10px",
                  resize: "none",
                  height: "100%",
                  overflow: "auto",
                  border: "none",
                  outline: "none",
                  scrollbarWidth: "thin",
                  scrollbarColor: "transparent",
                }}
                placeholder="Paste your content here"
                value={inputText}
                onChange={handleTextChange}
              />
              {/* ProgressBar (only visible when isAnalysisLoading is true) */}
              {isAnalysisLoading && (
                <Box padding={2}>
                  <ProgressBar
                    startDateTime={new Date().toISOString()}
                    durationSeconds={20}
                    status={progressStatus}
                    errorMessage=""
                  />
                </Box>
              )}
              {/* Footer Actions */}
              <Box className={classes.standarFooterContainer}>
                {/* Feedback Buttons (only visible when isAnalysisCompleted is true) */}
                {isAnalysisCompleted && !isAnalysisLoading && (
                  <Box className={classes.feedBackButtonsContainer}>
                    <FeedbackDialog
                      runId={standardDefinition.run_id}
                      userId={user.id}
                      userEmail={user.email}
                      standardDetailId={standardDefinition.id}
                    />
                  </Box>
                )}
                {/* Analysis Buttons */}
                <Box className={classes.analisisButtonsContainer}>
                  <Button
                    type="button"
                    variant="outlined"
                    color="secondary"
                    onClick={handleClearClick}
                    disabled={
                      inputText === "" ||
                      (isAnalysisLoading &&
                        progressStatus === PROGRESS_STATUS.PENDING)
                    }
                    className={classes.clearButton}
                  >
                    Clear All
                  </Button>
                  <Button
                    type="button"
                    variant="outlined"
                    color="secondary"
                    onClick={handleAnalyzeText}
                    disabled={
                      inputText === "" ||
                      (isAnalysisLoading &&
                        progressStatus === PROGRESS_STATUS.PENDING)
                    }
                    className={classes.buttonWhite}
                  >
                    Analyze Content
                  </Button>
                </Box>
              </Box>
            </Box>
          )}
        </Grid>
        <Grid item xs={12} sm={6}>
          <Box className={classes.standardAccordionContainer}>
            <StandardsAccordion mode={mode} />
          </Box>
        </Grid>
      </Grid>

      <MessageDialog
        open={isMessageDialogOpen}
        title="Error"
        message={errorMessage}
        handleClose={handleCloseSuccessDialog}
        status="error"
      />
    </Box>
  );
};

export default StandardsDefinition;
