import React from "react";

const VerificationIcon = ({ color = "#4D4D4D", width = 24, height = 24 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.6837 15.1828C20.8235 14.5785 21.5999 13.3799 21.5999 12C21.5999 10.6201 20.8235 9.42153 19.6837 8.81728C20.0624 7.58405 19.7639 6.18753 18.7881 5.21177C17.8124 4.23602 16.4159 3.93749 15.1826 4.31617C14.5784 3.1764 13.3798 2.40002 11.9999 2.40002C10.62 2.40002 9.4214 3.17642 8.81715 4.3162C7.58393 3.93753 6.18742 4.23607 5.21168 5.21181C4.23593 6.18756 3.9374 7.58406 4.31607 8.81728C3.17629 9.42153 2.3999 10.6201 2.3999 12C2.3999 13.3799 3.17629 14.5785 4.31607 15.1828C3.93739 16.416 4.23592 17.8125 5.21167 18.7883C6.18742 19.764 7.58394 20.0626 8.81716 19.6839C9.42142 20.8236 10.62 21.6 11.9999 21.6C13.3798 21.6 14.5784 20.8236 15.1827 19.6838C16.4159 20.0625 17.8124 19.764 18.7881 18.7882C19.7639 17.8125 20.0624 16.416 19.6837 15.1828ZM16.6278 9.82938C16.9201 9.42739 16.8312 8.86452 16.4293 8.57216C16.0273 8.27981 15.4644 8.36868 15.172 8.77067L10.9915 14.5189L8.7363 12.2636C8.38483 11.9122 7.81498 11.9122 7.46351 12.2636C7.11203 12.6151 7.11203 13.1849 7.46351 13.5364L10.4635 16.5364C10.6496 16.7225 10.9079 16.8179 11.1703 16.7973C11.4327 16.7767 11.673 16.6423 11.8278 16.4294L16.6278 9.82938Z"
      fill={color}
    />
  </svg>
);

export default VerificationIcon;
