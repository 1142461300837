import React, { useEffect, useState } from "react";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import StandardBreadcrumb from "./standardBreadcrumb";
import StandardSteps from "./standardSteps";
import LoadingIndicator from "components/common/LoadingIndicator/loadingIndicator";
import { useQuery } from "react-query";
import { getFilterCustomers } from "components/util/filterUtil";
import { logException } from "components/util/logUtil";
import { useVerificationStandardView } from "contexts/verificationStandardViewContext";
import { getIndustries } from "services/serviceService";
import { getTrades } from "../../services/tradesService";
import {
  getAreaBuilding,
  getStandardById,
} from "services/verificationStandardService";
import useStyles from "./styles";

function useVerificationStandardItem(id, mode) {
  return useQuery(
    ["verification-standard", id],
    async () => {
      if (mode === "new") {
        return {};
      }
      const response = await getStandardById(id);
      return response.data || {};
    },
    { enabled: id !== "new" && mode !== "new" }
  );
}

const VerificationStandardLayout = React.memo(({ id, mode }) => {
  const classes = useStyles();
  const [customers, setCustomers] = useState([]);
  const [industries, setIndustries] = useState([]);
  const [trades, setTrades] = useState([]);
  const [areaBuilding, setAreaBuilding] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [activityList, setActivityList] = useState([]);
  const [, dispatch] = useVerificationStandardView();
  const { data } = useVerificationStandardItem(id, mode);

  // Load initial data (customers, industries, trades, construction areas)
  useEffect(() => {
    const loadData = async () => {
      try {
        const customersResult = await getFilterCustomers();
        setCustomers(customersResult);

        const tradesResult = await getTrades();
        if (tradesResult.data?.data) {
          setTrades(
            tradesResult.data.data.map(({ id, trade_name }) => ({
              value: id,
              label: trade_name,
            }))
          );
        }

        const areaBuildingResult = await getAreaBuilding();
        if (areaBuildingResult.data?.areaOfBuildings) {
          setAreaBuilding(
            areaBuildingResult.data.areaOfBuildings.map(({ id, name }) => ({
              value: id,
              label: name,
            }))
          );
        }

        const industriesResult = await getIndustries();
        if (industriesResult.data?.data?.industries) {
          setIndustries(
            industriesResult.data.data.industries.map(
              ({ industry_id, name }) => ({
                value: industry_id,
                label: name,
              })
            )
          );
        }
      } catch (e) {
        console.error("Error loading data:", e);
        logException(e, "Error loading initial data");
      } finally {
        setIsLoading(false);
      }
    };

    loadData();
  }, [dispatch]);

  useEffect(() => {
    const loadVerificationStandard = async () => {
      try {
        if (mode === "new") return;

        if (!data || !["edit", "duplicate", "view"].includes(mode)) {
          return;
        }

        const { standard } = data;

        const standardDetails = {
          standardName:
            mode === "duplicate" ? `${standard.name} (Copy)` : standard.name,
          ...(mode !== "duplicate" && {
            customerId: standard.customer_id,
            industryId: standard.industry_id,
            tradeId: standard.work_category_trade.trade_id,
            serviceCategoryId:
              standard.work_category_trade.work_item_category_id,
            areaOfBuildingId: standard.building_area_id,
            jobs: standard.verification_standard_job.map((job) => ({
              id: job.job.id,
              jobNumber: job.job.job_number,
              jobDescription: job.job.job_description,
              siteNumber: job.job.site_number,
            })),
          }),
        };
        const standardDefinition = {
          id: standard.id,
          name: standard.name,
          status: standard.status,
          started_at: standard.started_at,
          saved: standard.saved,
          jobBelowStandard: standard.job_below_standard,
          jobMeetStandard: standard.job_meet_standard,
          jobExceedStandard: standard.job_exceed_standard,
          verificationStandardJob: standard.verification_standard_job,
          run_id: standard.run_id,
        };

        setActivityList(standard.standard_historic_activity);

        dispatch({
          type: "SET_STANDARD_DETAILS",
          payload: standardDetails,
        });
        dispatch({
          type: "SET_STANDARD_DEFINITION",
          payload: standardDefinition,
        });
      } catch (e) {
        console.error("Error loading standard data:", e);
        logException(e, "Cannot load standard data");
      }
    };

    if (!isLoading && id !== "new") {
      loadVerificationStandard();
    }
  }, [id, mode, data, isLoading, dispatch]);

  if (isLoading) {
    return <LoadingIndicator />;
  }

  return (
    <>
      <StandardBreadcrumb mode={mode} />
      <Grid container className={classes.root}>
        <Grid item xs={12} sm={12} md={12}>
          <Box className={classes.containerBody}>
            <Box p={2}>
              <StandardSteps
                customerData={customers}
                industryData={industries}
                tradeData={trades}
                areaBuildingData={areaBuilding}
                mode={mode}
                standardData={mode !== "new" ? data : null}
                activityList={activityList}
              />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </>
  );
});

export default VerificationStandardLayout;
