import React from "react";
const Context = React.createContext(null);

export const Provider = Context.Provider;
export const Consumer = Context.Consumer;

const VerificationStandardContext = React.createContext();

const initialState = {
  openDelete: false,
};

export const reducer = (state, action) => {
  switch (action.type) {
    case "TOGGLE_DELETE":
      return {
        ...state,
        openDelete: action.open,
      };
    default:
      return initialState;
  }
};

const VerificationStandardActionProvider = ({ children }) => (
  <VerificationStandardContext.Provider
    value={React.useReducer(reducer, initialState)}
  >
    {children}
  </VerificationStandardContext.Provider>
);

function useVerificationStandardAction() {
  const context = React.useContext(VerificationStandardContext);
  if (context === undefined) {
    throw new Error(
      "useVerificationStandard must be used within a VerificationStandardProvider"
    );
  }
  return context;
}

export { VerificationStandardActionProvider, useVerificationStandardAction };
