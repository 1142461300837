import { createContext, useContext, useReducer } from "react";
import * as verficationStandardService from "../services/verificationStandardService";
import { getAiStandardSearch } from "../services/aiServices";
import { merge } from "lodash";

const defaultState = {
  verificationStandardListLoading: true,
  verificationStandardSelected: null,
  verificationStandardList: [],
  verificationStandardCount: [],
  verificationStandardDetails: null,
};

const VerificationStandardStateContext = createContext();
const VerificationStandardDispatchContext = createContext();

function verificationStandardReducer(state, action) {
  switch (action.type) {
    case "SET_LOADING":
      return {
        ...state,
        verificationStandardListLoading: action.verificationStandardListLoading,
      };
    case "SET_VERIFICATION_STANDARD_LIST":
      return {
        ...state,
        verificationStandardList: action.verificationStandardList,
      };
    case "SET_VERIFICATION_STANDARD_COUNT":
      return {
        ...state,
        verificationStandardCount: action.verificationStandardCount,
      };
    case "SET_VERIFICATION_STANDARD_SELECTED":
      return {
        ...state,
        verificationStandardSelected: action.verificationStandardSelected,
      };
    case "SET_VERIFICATION_STANDARD_DETAILS":
      return {
        ...state,
        verificationStandardDetails: action.verificationStandardDetails,
      };
    case "UPDATE_VERIFICATION_STANDARD_STATUS":
      const { verificationStandardId, statusId } = action.payload;
      const updatedVerificationStandardList =
        state.verificationStandardList.map((verificationStandard) =>
          verificationStandard.id === verificationStandardId
            ? { ...verificationStandard, status: statusId }
            : verificationStandard
        );
      return {
        ...state,
        verificationStandardList: updatedVerificationStandardList,
      };
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function VerificationStandardProvider({ initialState, children }) {
  var mergedState = merge({}, defaultState, initialState);
  const [state, dispatch] = useReducer(
    verificationStandardReducer,
    mergedState
  );
  return (
    <VerificationStandardStateContext.Provider value={state}>
      <VerificationStandardDispatchContext.Provider value={dispatch}>
        {children}
      </VerificationStandardDispatchContext.Provider>
    </VerificationStandardStateContext.Provider>
  );
}

function useVerificationStandardState() {
  const context = useContext(VerificationStandardStateContext);
  if (context === undefined) {
    throw new Error(
      "useVerificationStandardState must be used within a VerificationStandardProvider"
    );
  }
  return context;
}

function useVerificationStandardDispatch() {
  const context = useContext(VerificationStandardDispatchContext);
  if (context === undefined) {
    throw new Error(
      "useVerificationStandardDispatch must be used within a VerificationStandardProvider"
    );
  }
  return context;
}

async function getVerificationStandard(dispatch, id) {
  try {
    dispatch({
      type: "SET_VERIFICATION_STANDARD_DETAILS",
      verificationStandardDetails: [],
    });
  } catch (error) {
    console.log(error);
  }
}

async function getVerificationStandardList(dispatch, filterData, tab) {
  try {
    const response = await verficationStandardService.getVerificationStandards(
      filterData,
      tab
    );
    dispatch({
      type: "SET_VERIFICATION_STANDARD_LIST",
      verificationStandardList: response.data.data.standards,
    });
    dispatch({
      type: "SET_VERIFICATION_STANDARD_COUNT",
      verificationStandardCount: response.data.data.filter_counts,
    });
    dispatch({
      type: "SET_LOADING",
      verificationStandardListLoading: false,
    });
  } catch (error) {
    console.log(error);
  }
}

async function setVerificationStandardList(dispatch, data) {
  try {
    const response = data;
    dispatch({
      type: "SET_VERIFICATION_STANDARD_LIST",
      verificationStandardList: data.data.standards,
    });
    dispatch({
      type: "SET_VERIFICATION_STANDARD_COUNT",
      verificationStandardCount: data.data.filter_counts ?? 0,
    });
    dispatch({
      type: "SET_LOADING",
      verificationStandardListLoading: false,
    });
  } catch (error) {
    console.log(error);
  }
}

async function getVerificationStandardListDataSet(
  verificationStandardList,
  dispatch,
  tab,
  offset,
  filterData,
  filterAddon = undefined
) {
  try {
    const result =
      await verficationStandardService.getVerificationStandardsTabOffset(
        tab,
        offset,
        filterData,
        filterAddon
      );

    const updateVerificationStandardList = [
      ...verificationStandardList,
      ...result.data.data.standards,
    ];
    dispatch({
      type: "SET_VERIFICATION_STANDARD_LIST",
      verificationStandardList: updateVerificationStandardList,
    });

    return true;
  } catch (error) {
    console.log(error);
  }
}

async function searchAiStandards(userEmail, userId, search) {
  try {
    const standardListResult = await getAiStandardSearch(
      userEmail,
      userId,
      search
    );
    return standardListResult.data.data;
  } catch (error) {
    console.log(error);
  }
}

export {
  VerificationStandardProvider,
  useVerificationStandardState,
  useVerificationStandardDispatch,
  getVerificationStandard,
  getVerificationStandardList,
  getVerificationStandardListDataSet,
  setVerificationStandardList,
  searchAiStandards,
};
