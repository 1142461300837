import React, { useState, useEffect, useRef } from "react";
import InputAdornment from "@material-ui/core/InputAdornment";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import SearchIcon from "@material-ui/icons/Search";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import lunaAnimatedSpinner from "../../../assets/icons/loadingLunaSearch.gif";
import lunaSolidPlaceholder from "../../../assets/icons/solidLunaSearch.jpg";
import { useFilterState } from "contexts/filterContext";
import classnames from "classnames";
import useStyles from "./styles";
import LunaInputSearch from "../LunaSearch/lunaInputSearch";
import { Mixpanel } from "services/mixpanel";

const SearchBar = (props) => {
  const [stateValue, setStateValue] = useState("");
  const [anchor, setAnchorEl] = useState(null);
  const { searchString } = useFilterState();
  const [enableParameters, setEnableParameters] = useState(false);
  const [configuredParameters, setConfiguredParameters] = useState(false);
  const [selectedSuggestedAction, setSelectedSuggestedAction] = useState();
  const [indexTextPosition, setIndexTextPosition] = useState();
  const textFieldRef = useRef();
  const classes = useStyles();
  const {
    handleSearch,
    placeholder,
    controlledSearch,
    isSearchAi,
    handleSearchClear,
    isLoadingSearch,
    searchTriggered,
    module,
  } = props;

  const user = JSON.parse(localStorage.getItem("user"));

  useEffect(() => {
    if (searchString !== "") {
      setStateValue(searchString);
    } else {
      setStateValue("");
    }
  }, [searchString]);

  useEffect(() => {
    if (isLoadingSearch) {
      setAnchorEl(null);
    }
  }, [isLoadingSearch]);

  useEffect(() => {
    if (configuredParameters && indexTextPosition) {
      setEnableParameters(false);
      setConfiguredParameters(false);
      textFieldRef?.current.focus();
      textFieldRef?.current.setSelectionRange(
        indexTextPosition,
        indexTextPosition
      );
    }
  }, [configuredParameters, indexTextPosition]);

  const handleChange = (event) => {
    setStateValue(event.target.value);
    if (event.target.value === "") {
      handleSearchClear();
      textFieldRef.current.blur();
    }
  };

  const handleClear = () => {
    setStateValue("");
    handleSearchClear();
  };

  const getStartAdornment = () => {
    if (isSearchAi) {
      return (
        <InputAdornment position="start">
          <img
            src={isLoadingSearch ? lunaAnimatedSpinner : lunaSolidPlaceholder}
            width="40"
            height="40"
            alt="Loading"
          />
        </InputAdornment>
      );
    }
    return null;
  };

  const getEndAdornment = () => {
    return (
      <InputAdornment position="end">
        {searchTriggered ||
        searchString !== "" ||
        (controlledSearch && stateValue !== "") ? (
          <IconButton
            aria-label="clear"
            className={classes.clearSearchButton}
            onClick={handleClear}
          >
            <CloseIcon />
          </IconButton>
        ) : (
          <SearchIcon
            className={classes.searchIcon}
            onClick={(ev) => {
              handleSearch(ev, stateValue);
              if (isSearchAi) {
                Mixpanel.identify(user.id);
                Mixpanel.track(`Luna Search ${module}`, {
                  searchPrompt: stateValue,
                });
              }
            }}
          />
        )}
      </InputAdornment>
    );
  };

  const focus = (event) => {
    if (stateValue.length > 0) {
      return;
    }
    setAnchorEl(event.currentTarget);
  };

  const blur = (e) => {
    if (
      e.relatedTarget &&
      (e.relatedTarget.classList.contains("MuiButton-root") ||
        e.relatedTarget.classList.contains("MuiPaper-rounded"))
    ) {
      e.target.focus();
      return;
    }
    setAnchorEl(null);
  };

  const onHandleSelectedSuggestedAction = (suggestedAction) => {
    if (suggestedAction?.placeholders[0] !== "") {
      setEnableParameters(true);
      setSelectedSuggestedAction(suggestedAction);
      setAnchorEl(null);
      return;
    }
    setStateValue(suggestedAction.prompt);
  };

  const onHandleSelectedSuggestedActionParameter = (
    formattedPrompt,
    positionIndex
  ) => {
    setStateValue(formattedPrompt);
    setIndexTextPosition(positionIndex + 1);
    setEnableParameters(false);
    setConfiguredParameters(true);
  };

  const id = "popperMenu";

  return isSearchAi ? (
    <LunaInputSearch
      enableParameters={enableParameters}
      selectedSuggestedAction={selectedSuggestedAction}
      onHandleSelectedSuggestedActionParameter={
        onHandleSelectedSuggestedActionParameter
      }
      handleSearch={handleSearch}
      stateValue={stateValue}
      id={id}
      handleChange={handleChange}
      placeholder={placeholder}
      isSearchAi={isSearchAi}
      isLoadingSearch={isLoadingSearch}
      module={module}
      startAdornment={getStartAdornment()}
      endAdornment={getEndAdornment()}
      anchor={anchor}
      focus={focus}
      blur={blur}
      textFieldRef={textFieldRef}
      onHandleSelectedSuggestedAction={onHandleSelectedSuggestedAction}
    />
  ) : (
    <OutlinedInput
      name="searchField"
      onKeyPress={(ev) => handleSearch(ev, stateValue)}
      value={stateValue}
      onChange={handleChange}
      inputProps={{ autoComplete: "off" }}
      placeholder={placeholder ? placeholder : undefined}
      startAdornment={getStartAdornment()}
      endAdornment={getEndAdornment()}
      disabled={isSearchAi && isLoadingSearch}
      className={classnames(classes.inputField, {
        [classes.inputFieldSearchAi]: isSearchAi && module === "partners",
        [classes.inputFieldWorkticketSearchAi]:
          isSearchAi && module === "worktickets",
        [classes.inputFieldStandardSearchAi]:
          isSearchAi && module === "standards",
      })}
    />
  );
};

export default SearchBar;
