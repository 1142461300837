export function ThumbsDownSV({ strokeColor }) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_76_6294)">
        <path
          d="M4.99873 10.1665H2.91485C2.23042 10.1665 1.6178 9.70384 1.54534 9.02326C1.51537 8.74178 1.5 8.45594 1.5 8.1665C1.5 6.26809 2.16125 4.52412 3.266 3.15236C3.5244 2.83149 3.92387 2.6665 4.33585 2.6665L7.01317 2.6665C7.33561 2.6665 7.65595 2.71849 7.96185 2.82045L10.0381 3.51255C10.344 3.61452 10.6644 3.6665 10.9868 3.6665L11.8497 3.6665M4.99873 10.1665C5.41092 10.1665 5.6593 10.6491 5.48225 11.0214C5.17304 11.6714 5 12.3987 5 13.1665C5 13.9949 5.67157 14.6665 6.5 14.6665C6.77614 14.6665 7 14.4426 7 14.1665V13.7442C7 13.3626 7.07282 12.9844 7.21457 12.6301C7.41714 12.1236 7.83488 11.7427 8.31631 11.4864C9.05887 11.0909 9.70849 10.5439 10.2239 9.88673C10.5557 9.46373 11.0407 9.1665 11.5783 9.1665H11.8341M4.99873 10.1665H6.46667M11.8497 3.6665C11.8567 3.69984 11.868 3.73292 11.8838 3.76504C12.2783 4.56456 12.5 5.46465 12.5 6.4165C12.5 7.40767 12.2597 8.3427 11.8341 9.1665M11.8497 3.6665C11.7983 3.4234 11.9717 3.1665 12.2329 3.1665H12.8381C13.4305 3.1665 13.9801 3.51185 14.153 4.07853C14.3786 4.81809 14.5 5.60312 14.5 6.4165C14.5 7.45148 14.3035 8.44055 13.9457 9.34843C13.7423 9.86448 13.2218 10.1665 12.6671 10.1665H11.9653C11.6506 10.1665 11.4685 9.7959 11.6318 9.527C11.7032 9.40942 11.7707 9.2892 11.8341 9.1665"
          stroke={strokeColor}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_76_6294">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(16 16) rotate(-180)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
