import React, { useState, useEffect, useMemo, useCallback } from "react";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import FormHelperText from "@material-ui/core/FormHelperText";
import IconButton from "@material-ui/core/IconButton";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import InfoIcon from "@material-ui/icons/Info";
import LinearProgress from "@material-ui/core/LinearProgress";
import JobSelectionDialog from "./dialog/jobSelectionDialog";
import FormSelectAuto from "components/ui/FormContent/formSelectAuto";
import { useVerificationStandardView } from "contexts/verificationStandardViewContext";
import {
  getServiceCategory,
  getJobs,
} from "services/verificationStandardService";
import useStyles from "./styles";

const validModes = ["edit", "view"];
const requiredFields = [
  { key: "standardName", message: "Standard Name is required." },
  { key: "customerId", message: "Customer is required." },
  { key: "industryId", message: "Industry is required." },
  { key: "tradeId", message: "Trade is required." },
  { key: "serviceCategoryId", message: "Service Category is required." },
  { key: "jobs", message: "Jobs is required." },
];

const getDisabledStyles = (isEditOrViewMode) => ({
  pointerEvents: isEditOrViewMode ? "none" : "auto",
  opacity: isEditOrViewMode ? 0.8 : 1,
});

const StandardDetails = (props) => {
  const { customerData, industryData, tradeData, areaBuildingData, mode } =
    props;
  const classes = useStyles();
  const [state, dispatch] = useVerificationStandardView();
  const { standardDetails } = state;
  const [currentFilterType, setCurrentFilterType] = useState("");
  const [formData, setFormData] = useState(standardDetails);
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [jobs, setJobs] = useState([]);
  const [selectedJobs, setSelectedJobs] = useState({ jobs: [] });
  const [serviceCategoriesData, setServiceCategoriesData] = useState([]);
  const [touchedFields, setTouchedFields] = useState({});
  const [validationErrors, setValidationErrors] = useState({});
  const isEditOrViewMode = validModes.includes(mode);

  useEffect(() => {
    setFormData(standardDetails);
  }, [standardDetails]);

  const loadServiceCategories = useCallback(async () => {
    if (!formData.tradeId) return;

    try {
      const result = await getServiceCategory({
        trade_id: formData.tradeId,
      });

      if (result.data?.service_categories) {
        setServiceCategoriesData(
          result.data.service_categories.map(({ id, name }) => ({
            value: id,
            label: name,
          }))
        );
      }
    } catch (e) {
      console.error("Error loading service categories:", e);
    }
  }, [formData.tradeId]);

  const areRequiredFieldsFilled = useCallback(() => {
    return [
      formData.customerId,
      formData.industryId,
      formData.tradeId,
      formData.serviceCategoryId,
    ].every((field) => field);
  }, [
    formData.customerId,
    formData.industryId,
    formData.tradeId,
    formData.serviceCategoryId,
  ]);

  const loadJobs = useCallback(async () => {
    if (!areRequiredFieldsFilled()) return;

    setIsLoading(true);

    try {
      const data = {
        customer_id: formData.customerId,
        industry_id: formData.industryId,
        trade_id: formData.tradeId,
        service_category_id: formData.serviceCategoryId,
        ...(formData.areaOfBuildingId && {
          area_building_id: formData.areaOfBuildingId,
        }),
      };

      const response = await getJobs(data);
      const jobs = response.data?.jobs || [];

      const jobsWithStandards = jobs.map((job) => ({
        value: job.id,
        label: `${job.job_number} - ${job.job_description}`,
        hasStandards: job.standard_id !== null,
        verification_standard_id: job.standard_id || null,
      }));

      setJobs(jobsWithStandards);

      if (isEditOrViewMode && formData.jobs?.length > 0) {
        const assignedJobs = jobsWithStandards.filter((job) =>
          formData.jobs.some((formJob) => String(formJob.id) === job.value)
        );

        const selectedJobsData = assignedJobs.map((job) => ({
          value: job.value,
          label: job.label,
          hasStandards: job.hasStandards,
          verification_standard_id: job.verification_standard_id,
        }));

        setSelectedJobs({ jobs: selectedJobsData });
        dispatch({
          type: "SET_SELECTED_JOBS",
          payload: selectedJobsData,
        });
        dispatch({
          type: "SET_INITIAL_JOBS",
          payload: selectedJobsData,
        });
      }
    } catch (error) {
      console.error("Error loading jobs:", error);
    } finally {
      setIsLoading(false);
    }
    //eslint-disable-next-line
  }, [areRequiredFieldsFilled]);

  useEffect(() => {
    loadServiceCategories();
    loadJobs();
  }, [loadServiceCategories, loadJobs]);

  const validateForm = useCallback(() => {
    const newErrors = requiredFields.reduce((acc, { key, message }) => {
      if (key === "jobs") {
        if (!formData[key] || formData[key].length === 0) {
          acc[key] = message;
        }
      } else if (!formData[key]) {
        acc[key] = message;
      }
      return acc;
    }, {});

    setValidationErrors(newErrors);
    dispatch({
      type: "SET_VALIDATION_ERRORS",
      payload: newErrors,
    });

    return Object.keys(newErrors).length === 0;
  }, [formData, dispatch]);

  useEffect(() => {
    const isValidForm = validateForm();
    if (isValidForm) {
      dispatch({
        type: "SET_STANDARD_DETAILS",
        payload: formData,
      });
    }
    // eslint-disable-next-line
  }, [dispatch, formData]);

  const handleChange = useCallback((e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
    setTouchedFields((prev) => ({ ...prev, [name]: true }));
  }, []);

  const handleChangeSelect = useCallback(
    (name) => (event, item) => {
      const value = item ? item.value : null;

      // Define dependencies between fields
      const specialCases = {
        tradeId: {
          serviceCategoryId: null,
          jobs: [],
        },
        serviceCategoryId: {
          jobs: [],
        },
      };

      setFormData((prev) => ({
        ...prev,
        [name]: value,
        ...(specialCases[name] || {}),
      }));

      // Clear serviceCategoriesData if tradeId is empty
      if (name === "tradeId" && !value) {
        setServiceCategoriesData([]);
      }

      // Clear serviceCategoriesData if tradeId is empty
      if (name === "tradeId") {
        setServiceCategoriesData(value ? serviceCategoriesData : []);
      }

      // Reset selectedJobs if tradeId or serviceCategoryId are null
      if (["tradeId", "serviceCategoryId"].includes(name) && !value) {
        setSelectedJobs({ jobs: [] });
      }

      // Mark the field as "touched"
      setTouchedFields((prev) => ({ ...prev, [name]: true }));
    },
    //eslint-disable-next-line
    []
  );

  const setSelectedJobsInForm = useCallback(
    (selectedJobs) => {
      if (!Array.isArray(selectedJobs)) {
        console.error("selectedJobs is not an array:", selectedJobs);
        return;
      }

      dispatch({
        type: "SET_SELECTED_JOBS",
        payload: selectedJobs,
      });

      // Get the values of the selected jobs
      const jobValues = selectedJobs.map((job) => job.value);
      setFormData((prev) => ({ ...prev, jobs: jobValues }));

      // Clear the jobs error if jobs are selected
      setValidationErrors((prev) => ({
        ...prev,
        jobs: undefined,
      }));

      // Mark the field as "touched"
      setTouchedFields((prev) => ({ ...prev, jobs: true }));
    },
    [dispatch]
  );

  const handleOpenModal = useCallback(
    (type) => {
      if (!formData.customerId) return;
      setCurrentFilterType(type);
      setIsModalOpen(true);
    },
    [formData.customerId]
  );

  const handleModalChange = useCallback(
    (selected) => {
      setSelectedJobsInForm(selected);
      setSelectedJobs((prevSelected) => ({
        ...prevSelected,
        [currentFilterType]: selected,
      }));
      setIsModalOpen(false);
    },
    [currentFilterType, setSelectedJobsInForm]
  );

  const handleCloseModal = useCallback(() => setIsModalOpen(false), []);

  const renderSelectedValues = useCallback((items) => {
    return items.map((item) => item.label).join(", ");
  }, []);

  const jobsFieldValue = useMemo(() => {
    return renderSelectedValues(selectedJobs.jobs);
  }, [selectedJobs.jobs, renderSelectedValues]);

  const shouldShowError = (fieldName) => {
    return touchedFields[fieldName] && validationErrors[fieldName];
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={3} md={3}>
        <Box fullWidth sx={getDisabledStyles(isEditOrViewMode)}>
          <FormControl fullWidth>
            <FormLabel className={classes.label}>Standard Name *</FormLabel>
            <TextField
              fullWidth
              variant="outlined"
              name="standardName"
              placeholder="Write Standard Name"
              value={formData.standardName}
              onChange={handleChange}
              className={classes.inputFields}
              autoComplete="off"
            />
            {shouldShowError("standardName") && (
              <FormHelperText error>
                {validationErrors.standardName}
              </FormHelperText>
            )}
          </FormControl>
        </Box>
      </Grid>
      <Grid item xs={12} sm={3} md={3}>
        <Box fullWidth sx={getDisabledStyles(isEditOrViewMode)}>
          <FormControl fullWidth>
            <FormLabel className={classes.label}>Customer *</FormLabel>
            <FormSelectAuto
              gridSizes={[{ size: "md", val: 12 }]}
              name="customerId"
              placeholder={"Select Customer"}
              multiple={false}
              options={customerData}
              handleChange={handleChangeSelect("customerId")}
              value={customerData.find(
                (customer) =>
                  Number(customer.value) === Number(formData.customerId)
              )}
            />
            {shouldShowError("customerId") && (
              <FormHelperText error>
                {validationErrors.customerId}
              </FormHelperText>
            )}
          </FormControl>
        </Box>
      </Grid>
      <Grid item xs={12} sm={3} md={3}>
        <Box fullWidth sx={getDisabledStyles(isEditOrViewMode)}>
          <FormControl fullWidth>
            <FormLabel className={classes.label}>Industry *</FormLabel>
            <FormSelectAuto
              gridSizes={[{ size: "md", val: 12 }]}
              name="industryId"
              placeholder={"Select Industry"}
              multiple={false}
              handleChange={handleChangeSelect("industryId")}
              options={industryData}
              value={industryData.find(
                (industry) =>
                  Number(industry.value) === Number(formData.industryId)
              )}
            />
            {shouldShowError("industryId") && (
              <FormHelperText error>
                {validationErrors.industryId}
              </FormHelperText>
            )}
          </FormControl>
        </Box>
      </Grid>
      <Grid item xs={12} sm={3} md={3}>
        <Box fullWidth sx={getDisabledStyles(isEditOrViewMode)}>
          <FormControl fullWidth>
            <FormLabel className={classes.label}>Trade *</FormLabel>
            <FormSelectAuto
              gridSizes={[{ size: "md", val: 12 }]}
              name="tradeId"
              placeholder={"Select Trade"}
              multiple={false}
              handleChange={handleChangeSelect("tradeId")}
              options={tradeData}
              value={tradeData.find(
                (trade) => Number(trade.value) === Number(formData.tradeId)
              )}
            />
            {shouldShowError("tradeId") && (
              <FormHelperText error>{validationErrors.tradeId}</FormHelperText>
            )}
          </FormControl>
        </Box>
      </Grid>
      <Grid item xs={12} sm={3} md={3}>
        <Box fullWidth sx={getDisabledStyles(isEditOrViewMode)}>
          <FormControl fullWidth>
            <FormLabel className={classes.label}>Service Category *</FormLabel>
            <FormSelectAuto
              gridSizes={[{ size: "md", val: 12 }]}
              name="serviceCategoryId"
              placeholder="Select Service Category"
              multiple={false}
              handleChange={handleChangeSelect("serviceCategoryId")}
              options={serviceCategoriesData}
              value={serviceCategoriesData.find(
                (serviceCategory) =>
                  serviceCategory.value === formData.serviceCategoryId
              )}
              readOnly={!formData.tradeId}
            />
            {shouldShowError("serviceCategoryId") && (
              <FormHelperText error>
                {validationErrors.serviceCategoryId}
              </FormHelperText>
            )}
          </FormControl>
        </Box>
      </Grid>
      <Grid item xs={12} sm={3} md={3}>
        <Box fullWidth sx={getDisabledStyles(isEditOrViewMode)}>
          <FormControl fullWidth>
            <FormLabel className={classes.label}>Area of Building </FormLabel>
            <FormSelectAuto
              gridSizes={[{ size: "md", val: 12 }]}
              name="areaOfBuildingId"
              placeholder={"Select Area of Building"}
              multiple={false}
              handleChange={handleChangeSelect("areaOfBuildingId")}
              options={areaBuildingData}
              value={areaBuildingData.find(
                (areaBuilding) =>
                  Number(areaBuilding.value) ===
                  Number(formData.areaOfBuildingId)
              )}
            />
          </FormControl>
        </Box>
      </Grid>
      <Grid item xs={12} sm={3} md={3}>
        <Box
          fullWidth
          sx={{
            pointerEvents: mode === "view" ? "none" : "auto",
            opacity: mode === "view" ? 0.8 : 1,
          }}
        >
          <FormControl fullWidth>
            <FormLabel className={classes.label}>Jobs *</FormLabel>
            {isLoading ? (
              <LinearProgress color="secondary" />
            ) : (
              <>
                <Box
                  onClick={() => handleOpenModal("jobs")}
                  className={classes.box}
                >
                  <TextField
                    required
                    fullWidth
                    id="jobs"
                    name="jobs"
                    variant="standard"
                    placeholder="Select Jobs"
                    value={jobsFieldValue}
                    InputProps={{
                      className: classes.inputHeight,
                      disableUnderline: true,
                      readOnly: true,
                      endAdornment: (
                        <IconButton disabled={!areRequiredFieldsFilled()}>
                          <ArrowRightIcon />
                        </IconButton>
                      ),
                    }}
                    className={classes.inputFieldFilters}
                    disabled={!areRequiredFieldsFilled()}
                  />
                </Box>
                {shouldShowError("jobs") && (
                  <FormHelperText error>{validationErrors.jobs}</FormHelperText>
                )}
              </>
            )}
          </FormControl>
          {mode !== "view" &&
            selectedJobs.jobs.some((job) => job.hasStandards) && (
              <Box className={classes.jobIconBox}>
                <Box display="flex" alignItems="center">
                  <InfoIcon className={classes.jobIcon} fontSize="small" />
                </Box>
                <Typography className={classes.jobText}>
                  You've chosen jobs with existing standards.
                </Typography>
              </Box>
            )}
        </Box>
      </Grid>
      <JobSelectionDialog
        isOpen={isModalOpen}
        onCloseModal={handleCloseModal}
        filterType={currentFilterType}
        optionsList={jobs}
        currentSelections={selectedJobs}
        onFilterChange={handleModalChange}
        isLoading={isLoading}
      />
    </Grid>
  );
};

export default StandardDetails;
