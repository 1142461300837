import React, { useState, useEffect } from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Modal from "@material-ui/core/Modal";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import InfoIcon from "@material-ui/icons/Info";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Collapse from "@material-ui/core/Collapse";
import StandardsList from "../standardList";
import LoadingIndicator from "components/common/LoadingIndicator/loadingIndicator";
import { checkStandardStatus } from "services/verificationStandardService";
import useStyles from "./styles";

const JobStandardPreviewDialog = (props) => {
  const classes = useStyles();
  const { isOpen, onCloseModal, selectedJob } = props;
  const [expandedJobs, setExpandedJobs] = useState({});
  const [expandedStandards, setExpandedStandards] = useState({});
  const [standardsList, setStandardsList] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [lastVerificationStandardId, setLastVerificationStandardId] =
    useState(null);

  useEffect(() => {
    if (selectedJob) {
      setExpandedJobs({ [selectedJob.value]: true });
      setExpandedStandards({ [selectedJob.value]: true });

      const { verification_standard_id } = selectedJob;

      if (
        verification_standard_id &&
        verification_standard_id !== lastVerificationStandardId
      ) {
        fetchStandardData(verification_standard_id);
        setLastVerificationStandardId(verification_standard_id);
      }
    }
  }, [selectedJob, lastVerificationStandardId]);

  const fetchStandardData = async (verificationStandardId) => {
    setIsLoading(true);
    try {
      const response = await checkStandardStatus({
        verification_standard_id: verificationStandardId,
      });

      setStandardsList(response.data.standardDetail);
    } catch (error) {
      console.error("Error fetching standard data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleToggle = (setter, key) => {
    setter((prev) => ({ ...prev, [key]: !prev[key] }));
  };

  return (
    <Modal open={isOpen} onClose={onCloseModal} className={classes.modal}>
      <Box className={classes.modalContent}>
        <Box className={classes.modalHeader}>
          <IconButton
            onClick={onCloseModal}
            className={classes.modalCloseButton}
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <Box className={classes.modalBody}>
          <Box className={classes.bottomRow}>
            <Box display="flex" alignItems="center">
              <InfoIcon className={classes.jobIcon} />
              <Typography className={classes.jobPreview_title}>
                These jobs have an existing set of standards
              </Typography>
            </Box>
          </Box>
          {isLoading ? (
            <Box className={classes.loadingContainer}>
              <LoadingIndicator />
            </Box>
          ) : (
            selectedJob && (
              <Box key={selectedJob.value} mt={2}>
                <Box
                  className={classes.jobPreview_clickableBox}
                  onClick={() =>
                    handleToggle(setExpandedJobs, selectedJob.value)
                  }
                >
                  <IconButton>
                    <ExpandMoreIcon
                      style={{
                        transform: expandedJobs[selectedJob.value]
                          ? "rotate(180deg)"
                          : "rotate(0)",
                        transition: "transform 0.3s",
                      }}
                    />
                  </IconButton>
                  <Typography className={classes.jobPreview_label}>
                    {selectedJob.label}
                  </Typography>
                </Box>
                <Collapse
                  in={expandedJobs[selectedJob.value]}
                  timeout="auto"
                  unmountOnExit
                >
                  <Box ml={4} mt={1}>
                    {selectedJob.hasStandards && (
                      <>
                        <Box className={classes.jobPreview_flexRow}>
                          <Box
                            className={classes.jobPreview_clickableBox}
                            onClick={() =>
                              handleToggle(
                                setExpandedStandards,
                                selectedJob.value
                              )
                            }
                          >
                            <IconButton>
                              <ExpandMoreIcon
                                style={{
                                  transform: expandedStandards[
                                    selectedJob.value
                                  ]
                                    ? "rotate(180deg)"
                                    : "rotate(0)",
                                  transition: "transform 0.3s",
                                }}
                              />
                            </IconButton>
                            <Typography className={classes.jobPreview_label}>
                              Current Standards
                            </Typography>
                          </Box>
                        </Box>

                        <Collapse
                          in={expandedStandards[selectedJob.value]}
                          timeout="auto"
                          unmountOnExit
                        >
                          {standardsList ? (
                            <StandardsList
                              selectedJob={selectedJob}
                              standardsList={standardsList}
                            />
                          ) : (
                            <Box className={classes.jobPreview_flexRow}>
                              <Typography className={classes.jobPreview_label}>
                                No standards available.
                              </Typography>
                            </Box>
                          )}
                        </Collapse>
                      </>
                    )}
                  </Box>
                </Collapse>
              </Box>
            )
          )}
        </Box>
      </Box>
    </Modal>
  );
};

export default JobStandardPreviewDialog;
