import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Box from "@material-ui/core/Box";
import RateIcon from "@material-ui/icons/RateReview";
import Typography from "@material-ui/core/Typography";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import React, { useEffect, useState } from "react";
import useStyles from "./styles";
import LoadingIndicator from "../../../components/common/LoadingIndicator/loadingIndicator";
import FormInput from "../../../components/ui/FormContent/formInput";
import FormSelectAuto from "../../../components/ui/FormContent/formSelectAuto";
import { DeleteBin } from "pages/partnerPage/icons/DeleteBin";
import LoadingDialog from "components/ui/dialog/loadingDialog";

export function DeleteLunaCommentDialog({
  open,
  handleClose,
  feedbackReasons,
  workticketId,
  feedbackLoading,
  isRefetchingSVRatingReasons,
  selectedReasonIds,
  setSelectedReasonIds,
  data,
  setData,
  handleConfirm,
  isLoadingDeletingLunaComment,
}) {
  const [isSuccessDialogOpen, setIsSuccessDialogOpen] = useState(false);
  const classes = useStyles();
  const [isLoadingDialog, setIsLoadingDialog] = useState(false);
  const handleBlur = async (event) => {
    data[event.name] = event.value;
    setData({ ...data });
  };

  const [errors, setErrors] = useState({});
  const handleReasonChange = (event, newValue) => {
    setSelectedReasonIds((prev) => [...newValue]);
    setErrors((prev) => ({ ...prev, reason: false }));
  };
  const handleSuccessClose = () => {
    setIsSuccessDialogOpen(false);
  };
  const buttonText = "Delete Comment";

  return (
    <>
      {!isLoadingDeletingLunaComment && (
        <Dialog
          open={open}
          aria-labelledby="dialog-title"
          aria-describedby="dialog-description"
          maxWidth={"md"}
          fullWidth={false}
        >
          <>
            <DialogContent className={classes.wrapperDialog}>
              <IconButton
                aria-label="close"
                onClick={() => handleClose()}
                className={classes.wrapperDialogClose}
              >
                <CloseIcon className={classes.iconDialogClose} />
              </IconButton>
              <Box className={classes.headerContainer}>
                <DeleteBin className={classes.iconDialog} />
                <Typography
                  variant="h6"
                  gutterBottom
                  className={classes.titleDialog}
                >
                  Delete Luna's Comment
                </Typography>
              </Box>
              {(feedbackLoading || isRefetchingSVRatingReasons) && (
                <LoadingIndicator />
              )}
              <Box className={classes.contentDialogRatingWrapperDeleteComment}>
                <FormSelectAuto
                  name="reasons"
                  label="Reason*"
                  multiple={true}
                  placeholder="Select Reasons"
                  options={feedbackReasons}
                  handleChange={handleReasonChange}
                  value={selectedReasonIds}
                  // error={
                  //   errors.reason
                  //     ? [
                  //         {
                  //           key: "reasons",
                  //           message: errors.reason,
                  //         },
                  //       ]
                  //     : []
                  // }
                />
              </Box>
              <FormInput
                rows={7}
                multiline={true}
                gridSizes={[{ size: "md", val: 12 }]}
                name="comment"
                label="Feedback on Luna*"
                placeholder="Please provide feedback on Luna"
                value={data.comment}
                handleBlur={handleBlur}
              />
            </DialogContent>
            <DialogActions className={classes.wrapperDialogActionRating}>
              <Button
                onClick={() => handleClose()}
                className={classes.buttonOutlinedRating}
                variant="outlined"
                size="large"
                color="primary"
              >
                Cancel
              </Button>
              <Button
                onClick={handleConfirm}
                className={classes.buttonRateService}
                color="primary"
                variant="contained"
                size="large"
                // disabled={isAcceptDisabled}
              >
                {buttonText}
              </Button>
            </DialogActions>
          </>
        </Dialog>
      )}
      <LoadingDialog open={isLoadingDeletingLunaComment} />
    </>
  );
}
