import React, { useContext, useReducer } from "react";

const VerificationStandardViewContext = React.createContext();

const initialState = {
  standardDetails: {
    standardName: "",
    customerId: null,
    industryId: null,
    tradeId: null,
    serviceCategoryId: null,
    areaOfBuildingId: null,
    jobs: [],
  },
  standardDefinition: {
    id: null,
    name: "",
    status: "",
    started_at: "",
    saved: "",
    jobBelowStandard: [],
    jobMeetStandard: [],
    jobExceedStandard: [],
    verificationStandardJob: [],
    run_id: null,
  },
  initialJobs: [],
  selectedJobs: [],
  validationErrors: {},
  isTextAreaVisible: false,
  isLoading: false,
  areaBuildingData: [],
};

const reducer = (state, action) => {
  switch (action.type) {
    case "SET_STANDARD_DETAILS":
      return {
        ...state,
        standardDetails: action.payload,
      };
    case "SET_STANDARD_DEFINITION":
      return {
        ...state,
        standardDefinition: action.payload,
      };
    case "SET_SELECTED_JOBS":
      return {
        ...state,
        selectedJobs: action.payload,
      };
    case "SET_INITIAL_JOBS":
      return {
        ...state,
        initialJobs: action.payload,
      };
    case "TOGGLE_TEXT_AREA":
      return {
        ...state,
        isTextAreaVisible: !state.isTextAreaVisible,
      };
    case "SET_LOADING":
      return {
        ...state,
        isLoading: action.payload,
      };
    case "SET_AREA_BUILDING_DATA":
      return {
        ...state,
        areaBuildingData: action.payload,
      };
    case "RESET":
      return {
        ...initialState,
        areaBuildingData: state.areaBuildingData,
      };
    case "RESET_STANDARD_DEFINITION":
      return {
        ...state,
        standardDefinition: {
          ...state.standardDefinition,
          name: "",
          status: "",
          started_at: "",
          saved: "",
          jobBelowStandard: [],
          jobMeetStandard: [],
          jobExceedStandard: [],
          verificationStandardJob: [],
          run_id: null,
        },
      };
    case "SET_VALIDATION_ERRORS":
      return {
        ...state,
        validationErrors: action.payload,
      };
    default:
      return state;
  }
};

const VerificationStandardViewProvider = ({ children }) => (
  <VerificationStandardViewContext.Provider
    value={useReducer(reducer, initialState)}
  >
    {children}
  </VerificationStandardViewContext.Provider>
);

const useVerificationStandardView = () => {
  const context = useContext(VerificationStandardViewContext);
  if (context === undefined) {
    throw new Error(
      "useVerificationStandardView must be used within a UserViewProvider"
    );
  }
  return context;
};

export { VerificationStandardViewProvider, useVerificationStandardView };
