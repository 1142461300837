import { makeStyles, withStyles } from "@material-ui/core/styles";
import InputBase from "@material-ui/core/InputBase";

const useStyles = makeStyles((theme) => ({
  addCommentField: {
    width: "100%",
    // marginTop: 24,
    minHeight: 40,
    padding: "4px",
    // border: "1px solid #ECECEC",
    // borderRadius: "4px",
    fontSize: 14,
    color: "#747474",
    paddingLeft: 12,
    "& textarea": {
      fontSize: 14,
      color: "#747474",
    },
  },
  commentInputText: {
    color: "#747474",
    fontSize: 14,
    border: "none",
    background: "transparent",
    outline: "none",
    width: "100%",
    padding: 0,
    paddingTop: 8,
    paddingBottom: 8,
  },
  containerAddButton: {
    display: "flex",
    alignItems: "flex-start",
    borderRadius: 4,
    background: "#FFFFFF",
    padding: theme.spacing(1),
  },
  categoryContainer: {
    display: "flex",
    flexDirection: "column",
    borderRadius: "4px",
    padding: "12px 0px 6px 6px",
    border: "1px solid #d6dde1",
  },
  errorIcon: {
    color: "#f07a8c",
  },
  noBorder: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        border: "none",
        borderRadius: "4px",
      },
    },
  },
  title: {
    fontWeight: "bold",
    fontSize: 24,
    marginBottom: 20,
  },
  subTitle: {
    fontWeight: "bold",
    fontSize: 14,
    marginTop: 20,
  },
  body: {
    marginBottom: 15,
  },
  inputContainer: {
    // marginBottom: 20,
  },
  inputContainer2: {
    marginBottom: 16,
  },
  inputInternalContainer: {
    marginBottom: 14,
  },
  inputSameRow: { display: "inline-block", marginBottom: 14, width: "100%" },
  inputSameRowCustom: {
    display: "inline-block",
    marginBottom: 14,
    width: "100%",
    marginTop: 44,
  },
  inputLabel: {
    height: 10,
    marginBottom: 10,
    fontSize: 14,
    color: "#747474",
    fontWeight: "bold",
  },
  inputLabelBilling: {
    fontSize: 10,
    color: "#747474",
  },
  inputLabelError: {
    fontSize: 14,
    color: "#9A1010",
    fontWeight: 400,
  },
  inputInternalLabel: {
    fontSize: 14,
    color: "#747474",
    fontWeight: "bold",
  },
  inputField: {
    borderRadius: "4px",
    border: "solid 1px #d6dde1 !important",
    fontSize: "14px",
    paddingLeft: 10,
    paddingRight: 10,
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    "&:hover": {
      border: "solid 1px #359be0",
    },
    "&:focus": {
      backgroundColor: "transparent",
    },
    "& > input": {
      padding: "12px 0 12px",
    },
  },
  inputFieldCategories: {
    borderRadius: "4px",
    border: "solid 1px white !important",
    fontSize: "14px",
    paddingLeft: 10,
    paddingRight: 10,
    transition: "border-color 0.5s ease-in-out",
    animation: "$hideInputBorder 0.5s forwards",
    "&:hover": {
      border: "solid 1px white",
    },
    "&:focus": {
      backgroundColor: "transparent",
    },
    "& > input": {
      padding: "12px 0 12px",
    },
  },
  "@keyframes hideInputBorder": {
    "0%": {
      borderColor: "#d6dde1",
    },
    "100%": {
      borderColor: "transparent",
    },
  },
  inputFieldBilling: {
    borderRadius: "4px",
    border: "solid 1px #ECECEC !important",
    fontSize: 12,
    paddingLeft: 10,
    paddingRight: 10,
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    "&:hover": {
      border: "solid 1px #359be0",
    },
    "&:focus": {
      backgroundColor: "transparent",
    },
    "& > input": {
      padding: "4px 0 4px",
    },
  },
  inputFieldBillingNoLabel: {
    borderRadius: "4px",
    marginTop: 18,
    border: "solid 1px #ECECEC !important",
    fontSize: 12,
    paddingLeft: 10,
    paddingRight: 10,
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    "&:hover": {
      border: "solid 1px #359be0",
    },
    "&:focus": {
      backgroundColor: "transparent",
    },
    "& > input": {
      padding: "4px 0 4px",
    },
  },
  disabled: {
    cursor: "not-allowed",
    background: "transparent",
    // "&:hover": {
    //   border: "1px solid #ced4da"
    // }
  },
  inputDateField: {
    borderRadius: "4px",
    border: "solid 1px #d6dde1 !important",
    paddingLeft: 10,
    paddingRight: 0,
    marginTop: 8,
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    "&:hover": {
      border: "solid 1px #359be0",
    },
  },
  inputFieldFocus: {
    border: "solid 1px #359be0",
  },
  inputFieldDisabled: {
    cursor: "not-allowed",
    "&:hover": {
      borderColor: "#ced4da",
    },
  },
  inputFieldRight: {
    textAlign: "right",
  },
  inputDateWrapper: {
    marginBottom: 25,
  },
  inputDate: {
    marginBottom: "0px !important",
    marginTop: "0px !important",
    padding: "0px !important",
    "& .MuiFormHelperText-root": {
      bottom: -5,
      right: 0,
      position: "absolute",
    },
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
  inputLabelAdo: {
    fontSize: 14,
    color: "#747474",
    fontWeight: "bold",
    textAlign: "left",
  },
  inputLabelAdoNoLabel: {
    fontSize: 14,
    color: "#747474",
    fontWeight: "bold",
    textAlign: "left",
    paddingTop: 14,
  },
  inputDateFieldAdo: {
    borderRadius: "4px",
    border: "solid 1px #d6dde1 !important",
    paddingLeft: 10,
    paddingRight: 0,
    marginTop: 8,
    paddingTop: 3,
    paddingBottom: 4,
    overflow: "hidden",
    fontSize: 14,
    color: "#747474",
    "&:hover": {
      borderColor: "#ced4da",
    },
  },
  inputDateFieldAdoNoMargin: {
    borderRadius: "4px",
    border: "solid 1px #d6dde1 !important",
    paddingLeft: 10,
    paddingRight: 0,
    paddingTop: 3,
    paddingBottom: 4,
    overflow: "hidden",
    fontSize: 14,
    color: "#747474",
    "&:hover": {
      borderColor: "#ced4da",
    },
  },
  selectField: {
    "& .MuiSvgIcon-root": {
      rotate: "270deg",
    },
  },
  inputAutoField: {
    "& > .MuiTextField-root": {
      padding: 0,
      margin: 0,
      paddingTop: 5,
      paddingBottom: 5,
      paddingLeft: 5,
      background: "#ffffff",
    },
    "&  .MuiInput-underline:before": {
      border: "0 !important",
    },
    "&  .MuiInput-underline:after": {
      border: "0 !important",
    },
    "& .MuiAutocomplete-popupIndicator": {
      position: "absolute",
      top: 0,
      right: 0,
    },
    "& .MuiAutocomplete-clearIndicator": {
      position: "absolute",
      top: 3,
      right: 20,
    },
    "& .MuiChip-root": {
      height: "25px !important",
    },
    "& .MuiChip-label": {
      fontSize: "11px !important",
    },
    "& .MuiFormControl-root": {
      padding: "3px !important",
      paddingRight: "5px !important",
      paddingLeft: "10px !important",
    },
    "& .MuiInput-input": {
      fontSize: 14,
      paddingLeft: 5,
    },
  },
  chipAuto: {
    fontSize: "10px !important",
    margin: "3px !important",
    marginRight: "10px !important",
    height: "24px !important",
    backgroundColor: "#f3f3f3",
    "& .MuiChip-avatar": {
      marginLeft: "0 !important",
    },
  },
  endTextContainer: {
    marginTop: 14,
  },
  endTextContainer2: {
    marginTop: 10,
  },
  endTextContainer3: {
    marginTop: 0,
  },
  informationContainer: {
    width: "100%",
    marginBottom: 30,
  },
  valuesContainer: {
    border: "solid 1px #d6dde1 !important",
    backgroundColor: "#f8f8f8",
    padding: "8px 10px 2px 5px",
    borderRadius: 4,
  },
  valuesContainer2: {
    border: "solid 1px #d6dde1 !important",
    padding: "8px 10px 2px 5px",
    borderRadius: 4,
  },
  valueContainer: {
    paddingTop: 2,
  },
  labelContainer: {
    display: "inline-flex",
  },
  textInformation: {
    fontSize: 10,
    paddingTop: 4,
  },
  textInformationContainer: {
    display: "inline-flex",
  },
  labelContainerTotal: {
    display: "inline-flex",
    marginBottom: 8,
  },
  inputSubLabel: {
    fontSize: 10,
    color: "#747474",
    fontWeight: "bold",
    paddingTop: 4,
    paddingLeft: 4,
  },
  inputInternalSubLabel: {
    fontSize: 10,
    color: "#747474",
    fontWeight: "bold",
    paddingTop: 4,
    paddingLeft: 4,
  },
  arrowIcon: {
    position: "absolute",
    left: 16,
    width: 16,
    color: "#E6E5E5",
  },
  titleToScaleContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  titleToScale: {
    fontSize: 14,
  },
}));

export const InputStyled = withStyles((theme) => ({
  input: {
    borderRadius: 4,
    width: "100%",
    position: "relative",
    fontSize: "14px",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #ced4da",
    padding: "12px 26px 11px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    "&:hover": {
      borderRadius: "4px",
      border: "solid 1px #359be0",
    },
    "&:focus": {
      borderRadius: "4px",
      backgroundColor: "transparent",
      border: "solid 1px #359be0",
    },
  },
  disabled: {
    cursor: "not-allowed !important",
    background: "transparent",
    "&:hover": {
      borderColor: "#ced4da",
    },
  },
}))(InputBase);

export default useStyles;
