import React, { lazy, Suspense } from "react";
import Box from "@material-ui/core/Box";
import PageHeader from "components/common/PageHeader/pageHeader";
import LoadingStateHorizontal from "components/common/LoadingStateHorizontal/LoadingStateHorizontal";
import { VerificationStandardProvider } from "contexts/verificationStandardContext";
import { VerificationStandardActionProvider } from "contexts/verificationStandardActionContext";
import withTableFilterContext from "components/hoc/withTableFilterContext";
import useStyles from "./styles";

const VerificationStandardTable = lazy(() => import("./standardTable"));

const VerificationStandardList = (props) => {
  const classes = useStyles();

  return (
    <>
      {
        <PageHeader
          pageTitle="Verification Standards"
          link="/verification-standards"
        />
      }
      <Box className={classes.pageBodyWrapper}>
        <VerificationStandardProvider>
          <VerificationStandardActionProvider>
            <Suspense
              fallback={
                <LoadingStateHorizontal
                  isVisible
                  style={classes.centerLoading}
                />
              }
            >
              <VerificationStandardTable />
            </Suspense>
          </VerificationStandardActionProvider>
        </VerificationStandardProvider>
      </Box>
    </>
  );
};

export default withTableFilterContext(VerificationStandardList);
