export function ThumbsUpSV({ thumbsUpColor = "#D9D9D9" }) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_721_7082)">
        <path
          d="M4.9952 12.3333C4.71215 12.3333 4.44864 12.1756 4.34506 11.9122C4.12233 11.3457 4 10.7288 4 10.0833C4 8.9172 4.39916 7.84437 5.06829 6.99395C5.1689 6.86609 5.31637 6.78797 5.4681 6.72923C5.7833 6.60719 6.06169 6.38637 6.27607 6.11303C6.79151 5.45581 7.44113 4.90885 8.18369 4.51339C8.66512 4.25701 9.08286 3.87611 9.28543 3.36968C9.42717 3.01532 9.5 2.63716 9.5 2.2555V1.83325C9.5 1.55711 9.72386 1.33325 10 1.33325C10.8284 1.33325 11.5 2.00482 11.5 2.83325C11.5 3.601 11.327 4.32834 11.0178 4.97839C10.8407 5.35061 11.0891 5.83325 11.5013 5.83325H13.5852C14.2696 5.83325 14.8822 6.29591 14.9547 6.97649C14.9846 7.25797 15 7.54382 15 7.83325C15 9.73167 14.3387 11.4756 13.234 12.8474C12.9756 13.1683 12.5761 13.3333 12.1642 13.3333H9.48683C9.16439 13.3333 8.84405 13.2813 8.53815 13.1793L6.46185 12.4872C6.15595 12.3852 5.83561 12.3333 5.51317 12.3333H4.9952Z"
          fill={thumbsUpColor}
        />
        <path
          d="M1.55433 7.15133C1.19654 8.05921 1 9.04828 1 10.0833C1 10.8966 1.12139 11.6817 1.34704 12.4212C1.51994 12.9879 2.06945 13.3333 2.66192 13.3333H3.26712C3.56413 13.3333 3.74758 13.0011 3.61615 12.7347C3.22165 11.9352 3 11.0351 3 10.0833C3 8.94447 3.31726 7.87978 3.8682 6.97275C4.03154 6.70386 3.84936 6.33325 3.53474 6.33325H2.8329C2.27822 6.33325 1.7577 6.63527 1.55433 7.15133Z"
          fill={thumbsUpColor}
        />
      </g>
      <defs>
        <clipPath id="clip0_721_7082">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
