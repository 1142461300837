import React, { useState, useMemo } from "react";
import { useHistory } from "react-router-dom";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import StandardsDetails from "./standardDetails";
import StandardsDefinition from "./standardDefinition";
import StandardComparisonDialog from "./dialog/standardComparisonDialog";
import MessageDialog from "./dialog/messageDialog";
import LoadingDialog from "components/ui/dialog/loadingDialog";
import StandardActivityList from "./standardActivityList";
import { useVerificationStandardView } from "contexts/verificationStandardViewContext";
import {
  saveStandard,
  saveDuplicateStandard,
  editStandard,
} from "services/verificationStandardService";
import classNames from "classnames";
import useStyles from "./styles";

const modes = {
  new: "new",
  edit: "edit",
  duplicate: "duplicate",
  view: "view",
};

const standardActionLabel = {
  new: "Save Standard",
  edit: "Update  Standard",
  duplicate: "Duplicate Standard",
};

const standardType = {
  below: 0,
  meet: 1,
  exceed: 2,
};

const modeMessages = {
  new: "Standards have been saved correctly.",
  edit: "Standards have been updated correctly.",
  duplicate: "Standard has been duplicated successfully.",
};

const steps = [
  {
    stepNumber: 1,
    title: "Standard Details",
    subtitle: "Tell us more about the standard you are creating.",
  },
  {
    stepNumber: 2,
    title: "Standards Definition",
    subtitle:
      "Let’s define the content of the standards based on the needs of the customer.",
  },
];

const requiredFields = [
  "standardName",
  "customerId",
  "industryId",
  "tradeId",
  "serviceCategoryId",
  "jobs",
];

const requiredDefinitionFields = [
  "jobBelowStandard",
  "jobMeetStandard",
  "jobExceedStandard",
];

const StandardSteps = (props) => {
  const {
    customerData,
    jobsData,
    industryData,
    tradeData,
    areaBuildingData,
    activityList,
    mode,
  } = props;
  const classes = useStyles();
  const history = useHistory();
  const [state, dispatch] = useVerificationStandardView();
  const { standardDetails, standardDefinition, selectedJobs, initialJobs } =
    state;
  const [isLoading, setIsLoading] = useState(false);
  const [isTouched, setIsTouched] = useState(false);
  const [showComparisonModal, setShowComparisonModal] = useState(false);
  const [isSuccessDialogOpen, setIsSuccessDialogOpen] = useState(false);
  const [standardAssignmentComparison, setStandardAssignmentComparison] =
    useState(null);

  const isViewMode = mode === modes.view;

  // Validate if standard details are complete
  const areStandardDetailsComplete = useMemo(() => {
    return requiredFields.every((field) => {
      const value = standardDetails[field];
      return Array.isArray(value) ? value.length > 0 : !!value;
    });
  }, [standardDetails]);

  // Validate if standard definition is complete
  const areStandardDefinitionsComplete = useMemo(() => {
    return requiredDefinitionFields.some(
      (field) => standardDefinition[field].length > 0
    );
  }, [standardDefinition]);

  // Check if the form is ready to be saved
  const isFormValidAndComplete =
    areStandardDetailsComplete && areStandardDefinitionsComplete;

  // Helper function to map standards to details
  const mapStandardsToDetails = (standards, type) =>
    standards.map((standard) => ({
      standard_type: type,
      standard_luna_id: standardDefinition.run_id,
      label: standard.label,
      description: standard.description,
    }));

  // Get the list of standard details
  const getStandardDetailsList = () => [
    ...mapStandardsToDetails(
      standardDefinition.jobBelowStandard,
      standardType.below
    ),
    ...mapStandardsToDetails(
      standardDefinition.jobMeetStandard,
      standardType.meet
    ),
    ...mapStandardsToDetails(
      standardDefinition.jobExceedStandard,
      standardType.exceed
    ),
  ];

  // Get data for a duplicated standard
  const getDuplicatedStandardData = () => ({
    duplicated_standard_id: standardDefinition.id,
    standard_name: standardDetails.standardName,
    customer_id: standardDetails.customerId,
    service_category_id: standardDetails.serviceCategoryId,
    industry_id: standardDetails.industryId,
    trade_id: standardDetails.tradeId,
    area_building_id: standardDetails.areaOfBuildingId || null,
    jobs: selectedJobs.map((job) => job.value),
    standard_detail: getStandardDetailsList(),
    replace_jobs: [],
  });

  // Get data for an edited standard
  const getEditedStandardData = () => ({
    standard_detail: getStandardDetailsList(),
    new_jobs: selectedJobs.map((job) => job.value),
    delete_jobs: [],
    replace_jobs: [],
  });

  // Build final data based on the mode
  const buildStandardAssignmentData = (mode, data) => {
    const jobsWithoutStandards = selectedJobs
      .filter((job) => !job.hasStandards)
      .map((job) => job.value);

    let finalData = {};

    if (mode === modes.new) {
      finalData = {
        verification_standard_id: standardDefinition.id,
        jobs: jobsWithoutStandards,
        replace_jobs_standard: data.replace_jobs_standard,
      };
    } else if (mode === modes.duplicate) {
      finalData = {
        ...getDuplicatedStandardData(),
        jobs: jobsWithoutStandards,
        replace_jobs_standard: data.replace_jobs_standard,
      };
    } else if (mode === modes.edit) {
      const deleteJobs = initialJobs
        .filter(
          (initialJob) =>
            !selectedJobs.some((job) => job.value === initialJob.value)
        )
        .map((job) => job.value);

      finalData = {
        ...getEditedStandardData(),
        new_jobs: jobsWithoutStandards,
        delete_jobs: deleteJobs,
        replace_jobs: data.replace_jobs_standard,
      };
    }

    return finalData;
  };

  // Save standard data based on the mode
  const saveStandardData = async (data, mode) => {
    setIsLoading(true);
    try {
      switch (mode) {
        case modes.new:
          await saveStandard(data);
          break;
        case modes.duplicate:
          await saveDuplicateStandard(data);
          break;
        case modes.edit:
          await editStandard(standardDefinition.id, data);
          break;
        default:
          throw new Error("Invalid mode");
      }
      resetFormState();
      setIsSuccessDialogOpen(true);
    } catch (error) {
      console.error("Error saving the standard:", error);
    } finally {
      setIsLoading(false);
    }
  };

  // Check if there are jobs with assigned standards and set comparison data
  const checkAndSetAssignedStandards = (selectedJobs, standardDefinition) => {
    const jobsWithAssignedStandards = selectedJobs.filter(
      (job) => job.hasStandards
    );

    if (jobsWithAssignedStandards.length > 0) {
      setStandardAssignmentComparison({
        currentStandardAssignments: jobsWithAssignedStandards,
        newStandardAssignments: standardDefinition,
      });
      return true;
    }

    return false;
  };

  // Handle saving with validation
  const handleSaveStandardWithValidation = async (dataBuilder, mode) => {
    const isValid = isFormValidAndComplete;

    if (isValid) {
      const hasAssignedStandards = checkAndSetAssignedStandards(
        selectedJobs,
        standardDefinition
      );

      if (hasAssignedStandards) {
        setShowComparisonModal(true);
      } else {
        const data = dataBuilder();
        await saveStandardData(data, mode);
      }
    } else {
      console.log("The form is not valid.");
    }
  };

  // Handle saving a new standard
  const handleSaveNewStandard = async () => {
    const dataBuilder = () => ({
      verification_standard_id: standardDefinition.id,
      jobs: selectedJobs.map((job) => job.value),
    });

    await handleSaveStandardWithValidation(dataBuilder, modes.new);
  };

  // Handle saving a duplicated standard
  const handleSaveDuplicatedStandard = async () => {
    const dataBuilder = () => getDuplicatedStandardData();
    await handleSaveStandardWithValidation(dataBuilder, modes.duplicate);
  };

  // Handle saving an edited standard
  const handleSaveEditedStandard = async () => {
    const dataBuilder = () => getEditedStandardData();
    await handleSaveStandardWithValidation(dataBuilder, modes.edit);
  };

  // Handle the standard save action based on the mode
  const handleSaveStandard = async () => {
    switch (mode) {
      case modes.new:
        await handleSaveNewStandard();
        break;
      case modes.duplicate:
        await handleSaveDuplicatedStandard();
        break;
      case modes.edit:
        await handleSaveEditedStandard();
        break;
      default:
        console.error("Invalid mode:", mode);
    }
  };

  // Handle the comparison decision and save the updated data
  const handleStandardComparisonDecision = async (data) => {
    setShowComparisonModal(false);
    setStandardAssignmentComparison(null);

    try {
      // Build the data to be sent after the comparison decision
      const standardAssignmentData = buildStandardAssignmentData(mode, data);

      await saveStandardData(standardAssignmentData, mode);
      resetFormState();
      setIsSuccessDialogOpen(true);
    } catch (error) {
      console.error("Error saving standards:", error);
    }
  };

  // Close the success dialog
  const closeSuccessDialog = () => {
    setIsSuccessDialogOpen(false);
    history.push("/verification-standards");
  };

  // Reset the form state
  const resetFormState = () => {
    dispatch({ type: "RESET" });
    setIsTouched(false);
  };

  // Navigate to the edit standard page
  const navigateToEditStandard = () => {
    history.push(`/verification-standard/${standardDefinition.id}/edit`);
  };

  return isLoading ? (
    <LoadingDialog open={isLoading} />
  ) : (
    <Box className={classes.scrollContainer}>
      {steps.map((step) => {
        const isStepOne = step.stepNumber === 1;
        return (
          <Box
            key={step.stepNumber}
            className={classNames({ [classes.stepContainer]: !isViewMode })}
          >
            <Box
              className={classNames({ [classes.stepConnector]: !isViewMode })}
            />
            {!isViewMode && (
              <Box className={classes.stepNumberCircle}>
                <Typography variant="h6">{step.stepNumber}</Typography>
              </Box>
            )}
            <Box className={classes.stepContent}>
              {isStepOne && (
                <Box className={classes.stepHeaderContainer}>
                  <Box>
                    <Typography className={classes.titleStep}>
                      {step.title}
                    </Typography>
                    <Typography className={classes.subTitleStep}>
                      {step.subtitle}
                    </Typography>
                  </Box>
                  <Box className={classes.stepActionsContainer}>
                    {!isViewMode && (
                      <Button
                        type="button"
                        variant="contained"
                        color="secondary"
                        disabled={!isFormValidAndComplete}
                        className={classes.buttonSecondary}
                        onClick={handleSaveStandard}
                      >
                        {standardActionLabel[mode] || "Save Standard"}
                      </Button>
                    )}
                    {isViewMode && (
                      <Button
                        type="button"
                        variant="contained"
                        color="secondary"
                        className={classes.buttonSecondary}
                        onClick={navigateToEditStandard}
                      >
                        Edit Standard
                      </Button>
                    )}
                  </Box>
                </Box>
              )}
              {isStepOne ? (
                <StandardsDetails
                  customerData={customerData}
                  jobsData={jobsData}
                  industryData={industryData}
                  tradeData={tradeData}
                  areaBuildingData={areaBuildingData}
                  isTouched={isTouched}
                  mode={mode}
                />
              ) : (
                <StandardsDefinition mode={mode} />
              )}
            </Box>
          </Box>
        );
      })}
      {/* ActivityList in view mode */}
      {isViewMode && (
        <Box className={classes.activityListContainer}>
          <Box>
            <StandardActivityList activities={activityList} />
          </Box>
        </Box>
      )}
      {/* Comparison dialog when a job with standards is selected */}
      {standardAssignmentComparison && (
        <StandardComparisonDialog
          isOpen={showComparisonModal}
          onClose={() => setShowComparisonModal(false)}
          comparisonStandardData={standardAssignmentComparison}
          onDecision={handleStandardComparisonDecision}
        />
      )}
      {/* Success dialog */}
      <MessageDialog
        open={isSuccessDialogOpen}
        title="Success!"
        message={modeMessages[mode] || "Standards have been saved."}
        handleClose={closeSuccessDialog}
      />
    </Box>
  );
};

export default StandardSteps;
