import React, { useState, useCallback, memo, useEffect } from "react";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import Icon from "@material-ui/core/Icon";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import EditIcon from "@material-ui/icons/Edit";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import DeleteIcon from "@material-ui/icons/Delete";
import HandThumbDown from "assets/icons/handThumbDown";
import HandThumbUp from "assets/icons/handThumbUp";
import HandsThumbUp from "assets/icons/handsThumbUp";
import { useVerificationStandardView } from "contexts/verificationStandardViewContext";
import {
  editStandardInfo,
  deleteStandardInfo,
} from "services/verificationStandardService";
import useStyles from "./styles";

const menuOptions = [
  {
    id: 1,
    label: "Edit",
    value: "EDIT_STANDARD",
    icon: <EditIcon fontSize="inherit" />,
    action: "handleEdit",
  },
  {
    id: 2,
    label: "Delete",
    value: "DELETE",
    icon: <DeleteIcon fontSize="inherit" />,
    action: "handleDelete",
  },
];

const standardsConfig = [
  {
    key: "jobMeetStandard",
    title: "Meets Standards*",
    icon: <HandThumbUp color="#9CBB65" width={20} height={20} />,
  },
  {
    key: "jobExceedStandard",
    title: "Exceeds Standards*",
    icon: <HandsThumbUp color="#328914" width={24} height={24} />,
  },
  {
    key: "jobBelowStandard",
    title: "Below Standards*",
    icon: <HandThumbDown color="#747474" width={20} height={20} />,
  },
];

const standardTypeMapping = {
  jobBelowStandard: 0,
  jobMeetStandard: 1,
  jobExceedStandard: 2,
};

const modes = {
  view: "view",
  duplicate: "duplicate",
  edit: "edit",
};

const StandardsAccordion = memo((props) => {
  const { mode } = props;
  const classes = useStyles();
  const [state, dispatch] = useVerificationStandardView();
  const { standardDefinition } = state;
  const [menuAnchorElement, setMenuAnchorElement] = useState(null);
  const [selectedMenuItem, setSelectedMenuItem] = useState(null);
  const [expandedAccordions, setExpandedAccordions] = useState({});
  const [itemBeingEdited, setItemBeingEdited] = useState(null);
  const [editedItemText, setEditedItemText] = useState("");
  const [editedItemLabel, setEditedItemLabel] = useState("");

  const isViewMode = mode === modes.view;
  const isEditOrDuplicatedMode = [modes.edit, modes.duplicate].includes(mode);

  useEffect(() => {
    const newExpandedAccordions = {};
    standardsConfig.forEach((standardConfig) => {
      if (standardDefinition[standardConfig.key]?.length > 0) {
        newExpandedAccordions[standardConfig.key] = true;
      }
    });
    setExpandedAccordions(newExpandedAccordions);
  }, [standardDefinition]);

  const handleMenuOpenClick = useCallback((event, item, standardType) => {
    setMenuAnchorElement(event.currentTarget);
    setSelectedMenuItem({ item, standardType });
  }, []);

  const handleMenuCloseClick = useCallback(() => {
    setMenuAnchorElement(null);
    setSelectedMenuItem(null);
  }, []);

  const handleEditMenuItem = useCallback(() => {
    setItemBeingEdited(selectedMenuItem);
    setEditedItemText(selectedMenuItem.item.description);
    setEditedItemLabel(selectedMenuItem.item.label);
    handleMenuCloseClick();
  }, [selectedMenuItem, handleMenuCloseClick]);

  const updateLocalContext = (standardType, updatedItems, dispatch) => {
    const updatedStandard = {
      ...standardDefinition,
      [standardType]: updatedItems,
    };

    dispatch({
      type: "SET_STANDARD_DEFINITION",
      payload: updatedStandard,
    });
  };

  const handleLocalDelete = (
    standardType,
    item,
    dispatch,
    handleMenuCloseClick
  ) => {
    const updatedItems = standardDefinition[standardType].filter(
      (i) => i.id !== item.id
    );
    updateLocalContext(standardType, updatedItems, dispatch);
    handleMenuCloseClick();
  };

  const handleLocalEdit = (
    standardType,
    item,
    editedItemLabel,
    editedItemText,
    dispatch
  ) => {
    const updatedItems = standardDefinition[standardType].map((i) =>
      i.id === item.id
        ? {
            ...i,
            label: editedItemLabel,
            description: editedItemText,
          }
        : i
    );
    updateLocalContext(standardType, updatedItems, dispatch);
  };

  const handleDeleteMenuItem = useCallback(async () => {
    const { standardType, item } = selectedMenuItem;

    if (isEditOrDuplicatedMode) {
      handleLocalDelete(standardType, item, dispatch, handleMenuCloseClick);
      return;
    }

    const data = {
      standard_type: standardTypeMapping[standardType],
      standard_label_id: item.id,
    };

    try {
      const response = await deleteStandardInfo(data);

      if (response.status === 200 && response.data.message === "success") {
        handleLocalDelete(standardType, item, dispatch, handleMenuCloseClick);
      } else {
        console.error(
          "Error: Unable to delete. Response message:",
          response.data.message
        );
      }
    } catch (error) {
      console.error("Failed to delete the standard. Please try again.", error);
    }
    //eslint-disable-next-line
  }, [selectedMenuItem, standardDefinition, handleMenuCloseClick, dispatch]);

  const handleSaveEditedItem = useCallback(async () => {
    const { standardType, item } = itemBeingEdited;

    if (isEditOrDuplicatedMode) {
      handleLocalEdit(
        standardType,
        item,
        editedItemLabel,
        editedItemText,
        dispatch
      );
      setItemBeingEdited(null);
      setEditedItemText("");
      setEditedItemLabel("");
      return;
    }

    const data = {
      standard_type: standardTypeMapping[standardType],
      standard_label_id: item.id,
      new_label: editedItemLabel,
      new_description: editedItemText,
    };

    try {
      const response = await editStandardInfo(data);

      if (response.status === 200 && response.data.message === "success") {
        const { new_standard } = response.data;
        handleLocalEdit(
          standardType,
          item,
          new_standard.label,
          new_standard.description,
          dispatch
        );
        setItemBeingEdited(null);
        setEditedItemText("");
        setEditedItemLabel("");
      } else {
        console.error(
          "Error: Unable to edit. Response message:",
          response.data.message
        );
      }
    } catch (error) {
      console.error("Failed to edit the standard. Please try again.", error);
    }
    //eslint-disable-next-line
  }, [
    itemBeingEdited,
    editedItemLabel,
    editedItemText,
    standardDefinition,
    dispatch,
  ]);

  const handleCancelEdit = useCallback(() => {
    setItemBeingEdited(null);
    setEditedItemText("");
    setEditedItemLabel("");
  }, []);

  const handleMenuItemAction = useCallback(
    (action) => {
      if (action === "handleEdit") {
        handleEditMenuItem();
      } else if (action === "handleDelete") {
        handleDeleteMenuItem();
      }
      handleMenuCloseClick();
    },
    [handleEditMenuItem, handleDeleteMenuItem, handleMenuCloseClick]
  );

  const handleAccordionChange = (standardKey) => (event, isExpanded) => {
    setExpandedAccordions((prev) => ({
      ...prev,
      [standardKey]: isExpanded,
    }));
  };

  return (
    <Box>
      {standardsConfig.map((standardConfig) => (
        <Accordion
          key={standardConfig.key}
          className={classes.accordion}
          expanded={expandedAccordions[standardConfig.key] || false}
          onChange={handleAccordionChange(standardConfig.key)}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Box className={classes.accordionItemContent}>
              {standardConfig.icon}
              <Typography className={classes.accordionTitle}>
                {standardConfig.title}
              </Typography>
            </Box>
          </AccordionSummary>
          <AccordionDetails>
            <Box className={classes.accordionContainerDetails}>
              {standardDefinition[standardConfig.key]?.length > 0 ? (
                standardDefinition[standardConfig.key].map((item, index) => (
                  <Box key={item.id} className={classes.accordionItemContainer}>
                    <Box className={classes.accordionItemContent}>
                      <Box className={classes.bulletPoint} />
                      {itemBeingEdited?.item.id === item.id &&
                      itemBeingEdited?.standardType === standardConfig.key ? (
                        <Box flexGrow={1}>
                          <Typography className={classes.accordionDescription}>
                            <span className={classes.standardLabel}>
                              {item.label}:
                            </span>
                          </Typography>
                          <TextField
                            value={editedItemText}
                            onChange={(e) => setEditedItemText(e.target.value)}
                            fullWidth
                            multiline
                            minRows={2}
                            variant="outlined"
                            InputProps={{
                              style: {
                                fontSize: 14,
                              },
                            }}
                            className={classes.focusedInput}
                          />
                        </Box>
                      ) : (
                        <>
                          <Typography
                            className={
                              isViewMode
                                ? classes.accordionDescriptionView
                                : classes.accordionDescription
                            }
                          >
                            <span className={classes.standardLabel}>
                              {item.label}:
                            </span>{" "}
                            {item.description}
                          </Typography>

                          {!isViewMode && (
                            <Icon
                              aria-label="more"
                              aria-controls="long-menu"
                              aria-haspopup="true"
                              onClick={(e) =>
                                handleMenuOpenClick(e, item, standardConfig.key)
                              }
                            >
                              <MoreHorizIcon />
                            </Icon>
                          )}
                        </>
                      )}
                    </Box>
                    {itemBeingEdited?.item.id === item.id &&
                      itemBeingEdited?.standardType === standardConfig.key && (
                        <Box
                          className={classes.accordionContainerButtonActions}
                        >
                          <Button
                            type="button"
                            size="small"
                            variant="outlined"
                            color="secondary"
                            onClick={handleCancelEdit}
                            className={classes.buttonWhite}
                          >
                            Cancelar
                          </Button>
                          <Button
                            type="button"
                            size="small"
                            variant="contained"
                            color="secondary"
                            onClick={handleSaveEditedItem}
                            className={classes.buttonSecondary}
                          >
                            Guardar
                          </Button>
                        </Box>
                      )}
                    {index <
                      standardDefinition[standardConfig.key].length - 1 && (
                      <Divider className={classes.accordionDivider} />
                    )}
                  </Box>
                ))
              ) : (
                <Box display="flex" alignItems="center">
                  <Box
                    className={classes.bulletPoint}
                    style={{
                      marginTop: 0,
                    }}
                  />
                  <Typography className={classes.standardListDescription}>
                    These will be filled by Luna depending on the content you
                    provide.
                  </Typography>
                </Box>
              )}
            </Box>
          </AccordionDetails>
        </Accordion>
      ))}
      <Menu
        anchorEl={menuAnchorElement}
        open={Boolean(menuAnchorElement)}
        onClose={handleMenuCloseClick}
        disablePortal
        onExited={() => {
          if (menuAnchorElement) {
            menuAnchorElement.focus();
          }
        }}
        PaperProps={{
          role: "menu",
          "aria-hidden": false,
        }}
      >
        {menuOptions.map((option) => (
          <MenuItem
            key={option.id}
            onClick={() => handleMenuItemAction(option.action)}
            tabIndex={0}
          >
            <Box className={classes.menuIcon}>{option.icon}</Box>
            <Typography variant="caption" className={classes.menuText}>
              {option.label}
            </Typography>
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
});

export default StandardsAccordion;
