import React, { useState, useEffect, useMemo, useCallback } from "react";
import { FixedSizeList as List } from "react-window";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Checkbox from "@material-ui/core/Checkbox";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Modal from "@material-ui/core/Modal";
import CloseIcon from "@material-ui/icons/Close";
import SearchIcon from "@material-ui/icons/Search";
import ClearIcon from "@material-ui/icons/Clear";
import InfoIcon from "@material-ui/icons/Info";
import InputAdornment from "@material-ui/core/InputAdornment";
import LoadingIndicator from "components/common/LoadingData/loadingData";
import JobStandardPreviewDialog from "./jobStandardsPreviewDialog";
import * as classnames from "classnames";
import useStyles from "./styles";

const itemSize = 50;
const filterOptions = {
  jobs: {
    display: "jobs",
    key: "jobs",
  },
};

const JobSelectionDialog = (props) => {
  const {
    isOpen,
    onCloseModal,
    filterType,
    optionsList,
    currentSelections,
    onFilterChange,
    isLoading,
  } = props;
  const classes = useStyles();
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedItems, setSelectedItems] = useState([]);
  const [isStandardsModalOpen, setIsStandardsModalOpen] = useState(false);
  const [selectedJob, setSelectedJob] = useState(null);

  // Memoize filtered options
  const filteredOptions = useMemo(() => {
    return optionsList.filter((item) =>
      item.label.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }, [optionsList, searchTerm]);

  // Update selectedItems when the modal opens or currentSelections changes
  useEffect(() => {
    if (isOpen) {
      const selectedIds =
        currentSelections.jobs?.map((item) => item.value.toString()) || [];

      setSelectedItems(selectedIds);
    }
  }, [isOpen, currentSelections]);

  // Memoize checkAll
  const checkAll = useMemo(() => {
    return selectedItems.length === filteredOptions.length;
  }, [selectedItems, filteredOptions]);

  const handleCheckboxChange = useCallback((event) => {
    const value = event.target.value;
    setSelectedItems((prevSelected) =>
      prevSelected.includes(value)
        ? prevSelected.filter((selectedValue) => selectedValue !== value)
        : [...prevSelected, value]
    );
  }, []);

  const handleCheckAllChange = useCallback(() => {
    if (checkAll) {
      setSelectedItems([]);
    } else {
      const allIds = filteredOptions.map((item) => item.value.toString());
      setSelectedItems(allIds);
    }
  }, [checkAll, filteredOptions]);

  const handleSave = useCallback(() => {
    const selected = selectedItems.map((value) =>
      optionsList.find((item) => item.value.toString() === value)
    );
    onFilterChange(selected);
    onCloseModal();
  }, [selectedItems, optionsList, onFilterChange, onCloseModal]);

  const handleClear = useCallback(() => {
    setSearchTerm("");
  }, []);

  const handleOpenStandardsModal = useCallback((job) => {
    setSelectedJob(job);
    setIsStandardsModalOpen(true);
  }, []);

  const handleCloseStandardsModal = useCallback(() => {
    setSelectedJob(null);
    setIsStandardsModalOpen(false);
  }, []);

  // Render a single row in the list
  const renderRow = useCallback(
    ({ index, style }) => {
      const item = filteredOptions[index];
      if (!item) return null;

      const hasStandards = item.hasStandards;
      const isSelected = selectedItems.includes(item.value.toString());

      return (
        <Box
          key={item.value}
          display="flex"
          justifyContent="space-between"
          width="100%"
          style={style}
          className={classnames(classes.checkboxItem, {
            [classes.selectedCheckbox]: isSelected,
          })}
        >
          <FormControlLabel
            control={
              <Checkbox
                checked={isSelected}
                value={item.value.toString()}
                onChange={handleCheckboxChange}
              />
            }
            label={item.label}
            style={{ flex: 1 }}
          />
          {hasStandards && (
            <Box className={classes.jobIconBox}>
              <Box display="flex" alignItems="center">
                <InfoIcon className={classes.jobIcon} />
              </Box>
              <Typography className={classes.jobText}>
                This job has an existing set of standards,{" "}
                <span
                  className={classes.jobLinkText}
                  onClick={() => handleOpenStandardsModal(item)}
                >
                  click here{" "}
                </span>
                to review them.
              </Typography>
            </Box>
          )}
        </Box>
      );
    },
    [
      filteredOptions,
      selectedItems,
      handleCheckboxChange,
      classes,
      handleOpenStandardsModal,
    ]
  );

  return (
    <>
      <Modal open={isOpen} onClose={onCloseModal} className={classes.modal}>
        <Box className={classes.modalContent}>
          <Box className={classes.modalHeader}>
            <Box className={classes.btnContainer}>
              <IconButton
                className={classes.modalCloseButton}
                onClick={onCloseModal}
              >
                <CloseIcon />
              </IconButton>
            </Box>
            <Typography variant="caption" className={classes.selectedText}>
              {selectedItems.length}/{filteredOptions.length} selected jobs for
              this customer
            </Typography>
          </Box>
          <Box className={classes.modalBody}>
            {isLoading ? (
              <Box className={classes.loadingContainer}>
                <LoadingIndicator backgroundColor="#FFFFFF" />
              </Box>
            ) : (
              <>
                <Box className={classes.bottomRow}>
                  <Typography className={classes.itemCount}>
                    {filteredOptions.length} jobs available for this customer
                  </Typography>
                  <Box className={classes.searchActionsWrapper}>
                    <TextField
                      variant="outlined"
                      placeholder={`Search jobs`}
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            {searchTerm ? (
                              <IconButton onClick={handleClear}>
                                <ClearIcon />
                              </IconButton>
                            ) : (
                              <SearchIcon />
                            )}
                          </InputAdornment>
                        ),
                        className: classes.inputPropsStyle,
                      }}
                      className={classnames(
                        classes.searchInput,
                        classes.noFocus
                      )}
                    />
                    <Button
                      type="button"
                      size="small"
                      variant="contained"
                      onClick={handleCheckAllChange}
                      style={{ marginRight: 10 }}
                      className={classes.buttonWhite}
                    >
                      {checkAll ? "Deselect all jobs" : "Select all jobs"}
                    </Button>
                    <Button
                      type="button"
                      size="small"
                      variant="contained"
                      color="secondary"
                      onClick={handleSave}
                      className={classes.buttonSecondary}
                    >
                      Apply Selection
                    </Button>
                  </Box>
                </Box>
                {optionsList.length === 0 ? (
                  <Box className={classes.notFoundText}>
                    <Typography variant="body1" color="textSecondary">
                      No jobs available for the selected customer
                    </Typography>
                  </Box>
                ) : (
                  <Box className={classes.checkboxContainer}>
                    <Box className={classes.checkboxGroupContainer}>
                      <Box style={{ flex: 1 }}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={checkAll}
                              onChange={handleCheckAllChange}
                            />
                          }
                          label={
                            <span className={classes.checkboxGroupLabel}>
                              Jobs
                            </span>
                          }
                        />
                      </Box>
                    </Box>
                    <List
                      height={600}
                      itemCount={filteredOptions.length}
                      itemSize={itemSize}
                      width="100%"
                    >
                      {renderRow}
                    </List>
                  </Box>
                )}
              </>
            )}
          </Box>
        </Box>
      </Modal>
      <JobStandardPreviewDialog
        isOpen={isStandardsModalOpen}
        onCloseModal={handleCloseStandardsModal}
        selectedJob={selectedJob}
      />
    </>
  );
};

export default JobSelectionDialog;
